import { Column } from "react-table";
import { SelectColumnFilter } from "../../../components/Table";
import {
  ButtonsPill,
  DateFromatPill,
  ImageCell,
  VisibilityPill,
} from "../../../components/TableCells";
import ApiEndpoints from "../../../core/apiEndpoints";
import store from "../../../redux/store";
import { KRoutes } from "../../../routers/router";
import {
  changeSalesBannerVisibility,
  deleteSalesBanner,
} from "../../../services/sales/banner";
import { ISalesBanner } from "../../../types/sales";

const salesTableHeaders: Column<ISalesBanner>[] = [
  {
    id: "image",
    Header: "Image",
    accessor: "_id",
    Cell: ({ value }) => ImageCell(ApiEndpoints.salesBannerImage + value),
  },
  {
    Header: "Category",
    accessor: (c) => c.category?.name ?? "No Category",
    Filter: SelectColumnFilter, // new
    filter: "includes",
  },
  {
    Header: "Visibility",
    accessor: "visibility",
    Cell: VisibilityPill,
    Filter: SelectColumnFilter, // new
    filter: "includes",
  },
  {
    Header: "CreatedAt",
    accessor: "createdAt",
    Cell: DateFromatPill,
  },
  {
    Header: "UpdatedAt",
    accessor: "updatedAt",
    Cell: DateFromatPill,
  },
  {
    Header: "Action",
    accessor: "_id",
    Cell: ({ value }) => {
      return ButtonsPill({
        statusChangeButton: false,
        visibilityChangeButton: true,
        editButton: true,
         deleteButton: ["MasterAdmin", "Developer"].includes(store.getState().admins.profile?.role || "")
          ? true
          : false,
        onClickVisibilityChange: () => changeSalesBannerVisibility(value),
        onClickDelete: () => deleteSalesBanner(value),
        editPath: KRoutes.editSalesBanner + "/" + value,
      });
    },
  },
];

export default salesTableHeaders;
