import { useNavigate } from "react-router-dom";

export interface IPillProps {
  column: any;
  row: any;
  cell: any;
  value: any;
}

interface IButtonPillsProps {
  statusChangeButton: boolean;
  visibilityChangeButton: boolean;
  editButton: boolean;
  deleteButton?: boolean;
  editPath?: string;
  editButtonTitle?: string;
  onClickStatusChange?: React.MouseEventHandler<HTMLButtonElement>;
  onClickVisibilityChange?: React.MouseEventHandler<HTMLButtonElement>;
  onClickDelete?: React.MouseEventHandler<HTMLButtonElement>;
}

export const StatusPill = ({ value }: IPillProps) => {
  const status = value ? value.toLowerCase() : "unknown";

  return (
    <span
      className={`
            "px-1 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm"
            ${status === "active" && "bg-green-100 text-green-800"} 
            ${status === "inactive" && "bg-yellow-100 text-yellow-800"}
            ${status === "blocked" && "bg-red-100 text-red-800"}
          )
        `}
    >
      {status}
    </span>
  );
};

export const VisibilityPill = ({ value }: IPillProps) => {
  const visibility = value ? value.toLowerCase() : "unknown";

  return (
    <span
      className={`
            "px-1 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm"
            ${visibility === "show" && "bg-green-100 text-green-800"} 
            ${visibility === "hide" && "bg-red-100 text-red-800"}
          )
        `}
    >
      {visibility}
    </span>
  );
};

export const ButtonsPill = ({
  editButton,
  deleteButton,
  visibilityChangeButton,
  statusChangeButton,
  editPath,
  editButtonTitle,
  onClickDelete,
  onClickStatusChange,
  onClickVisibilityChange,
}: IButtonPillsProps) => {
  const navigate = useNavigate();
  return (
    <div className="inline-flex rounded-md shadow-sm" role="group">
      <button
        type="button"
        className={`${!statusChangeButton && "hidden"
          } py-2 px-4 text-sm font-medium text-gray-900 bg-white rounded-l-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white`}
        onClick={onClickStatusChange}
      >
        Change Status
      </button>
      <button
        type="button"
        className={`${!visibilityChangeButton && "hidden"
          } py-2 px-4 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white`}
        onClick={onClickVisibilityChange}
      >
        Change Visibility
      </button>
      <button
        type="button"
        className={`${!editButton && "hidden"
          } py-2 px-4 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white`}
        onClick={() => {
          typeof editPath === "string" && navigate(editPath);
        }}
      >
        {editButtonTitle ?? "Edit"}
      </button>
      <button
        type="button"
        className={`${!deleteButton && "hidden"
          } py-2 px-4 text-sm font-medium text-gray-900 bg-white rounded-r-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white`}
        onClick={onClickDelete}
      >
        Delete
      </button>
    </div>
    // <div className="flex items-center">
    //   <button
    //     className={`${
    //       !editButton && "hidden"
    //     }  bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded mr-4`}
    //     onClick={onClickEdit}
    //   >
    //     Edit
    //   </button>
    // </div>
  );
};

export const DateFromatPill = ({ value }: IPillProps) => {
  const date = new Date(value);
  return <span className="text-sm text-gray-500"> {date.toDateString() ?? "No Date"}</span>;
};

export const TextPill = ({
  value,
  className,
}: {
  value: string;
  className?: string;
}) => {
  return <span className={className ?? ""}> {value}</span>;
};

export function AvatarCell({ value, column, row }: IPillProps) {
  return (
    <div className="flex items-center">
      <div className="flex-shrink-0 h-10 w-10">
        <img
          className="h-10 w-10 rounded-full"
          src={row.original[column.imgAccessor]}
          alt=""
        />
      </div>
      <div className="ml-4">
        <div className="text-sm font-medium text-gray-900">{value}</div>
        <div className="text-sm text-gray-500">
          {row.original[column.emailAccessor]}
        </div>
      </div>
    </div>
  );
}

export function ImageCell(imageURL: string) {
  return (
    <div className="flex items-center">
      {/* <div className="flex-shrink-0 h-10 w-10"> */}
      <img className="rounded w-26 h-26" src={imageURL} alt="cell" />
      {/* </div> */}
    </div>
  );
}
