import React, { useEffect, useMemo } from "react";
import { useAppSelector } from "../../../redux/hooks";
import Table from "../../../components/Table";
import Layout from "../../../components/Layout";
import servicePlanTableHeaders from "./servicePlanTableHeaders";
import Loader from "../../../components/Loader";
import { useNavigate } from "react-router-dom";
import { KRoutes } from "../../../routers/router";
import { getServicePlans } from "../../../services/services";
import { autoRefreshDelay } from "../../../core/constants";

const ScreenServicePlans = () => {
  const { plans, isLoading } = useAppSelector((state) => state.servicePlans);
  const columns = useMemo(() => servicePlanTableHeaders, []);
  const data = useMemo(() => plans, [plans]);
  const navigate = useNavigate();

  useEffect(() => {
    const refreshInterval = setInterval(
      () => getServicePlans(true),
      autoRefreshDelay * 1000
    );
    return () => {
      clearInterval(refreshInterval);
    };
  }, []);

  return (
    <Layout title="service-plans">
      {isLoading && plans.length <= 0 ? (
        <Loader />
      ) : (
        <Table
          columns={columns}
          data={data}
          tableTitle={"Service Plans"}
          buttons={{
            addButton: true,
            addButtonTitle: "Add Plan",
            onClickAddButton: () => {
              navigate(KRoutes.addServicePlan);
            },
            exportButton: false,
          }}
        />
      )}
    </Layout>
  );
};

export default ScreenServicePlans;
