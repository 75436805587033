import httpClient from "../../core/axios";
import ApiEndpoints from "../../core/apiEndpoints";
import store, { dispatch } from "../../redux/store";
import { setErrorMessage, setSuccessMessage } from "../../redux/notification";
import {
  changeAgentLoading,
  setAgentsState,
  addAgentState,
  changeAgentStatusState,
  deleteAgentState,
  editAgentState,
  setDeletedAgentState,
  restoreAgentState,
  pDeleteAgentState,
} from "../../redux/agent";

/**
 * To get a  agents from server
 */
export const getAgents = async (refresh?: boolean) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeAgentLoading(true));

    // API Fetching Deleted
    if (store.getState().admins.profile?.role === "MasterAdmin")
      getDeletedAgents(refresh);

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.agent);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(
      setAgentsState({
        agents: resp.data.data,
        refresh,
      })
    );
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAgentLoading(false));
  }
};

/**
 * To get a deleted agents from server
 */
export const getDeletedAgents = async (refresh?: boolean) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeAgentLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.deletedAgent);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(
      setDeletedAgentState({
        agents: resp.data.data,
        refresh,
      })
    );
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAgentLoading(false));
  }
};

/**
 * To add a new  agent
 */
export const addAgent = async (agentData: any) => {
  try {
    // Set Loading
    dispatch(changeAgentLoading(true));

    // API Fetching
    const resp = await httpClient().post(ApiEndpoints.agent, agentData);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(addAgentState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAgentLoading(false));
  }
};

/**
 * To add a new  agent
 */
export const editAgent = async (id: string, agentData: any) => {
  try {
    // Set Loading
    dispatch(changeAgentLoading(true));

    // API Fetching
    const resp = await httpClient().patch(
      ApiEndpoints.agent + "/" + id,
      agentData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(editAgentState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAgentLoading(false));
  }
};

/**
 * To change the status
 */
export const changeAgentStatus = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeAgentLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.changeAgentStatus + id);
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeAgentStatusState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAgentLoading(false));
  }
};

/**
 * To delete the agent
 */
export const deleteAgent = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeAgentLoading(true));

    // API Fetching
    const resp = await httpClient().delete(ApiEndpoints.agent + "/" + id);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(deleteAgentState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAgentLoading(false));
  }
};

/**
 * To restore the agent
 */
export const restoreAgent = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeAgentLoading(true));

    // API Fetching
    const resp = await httpClient().delete(ApiEndpoints.restoreAgent + id);
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(restoreAgentState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAgentLoading(false));
  }
};

/**
 * To delete the agent permanently
 */
export const pDeleteAgent = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeAgentLoading(true));

    // API Fetching
    const resp = await httpClient().delete(ApiEndpoints.deleteAgent + id);
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(pDeleteAgentState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAgentLoading(false));
  }
};
