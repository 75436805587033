import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISalesItem } from "../../types/sales";

interface ISalesItemState {
  items: ISalesItem[];
  deletedItems: ISalesItem[];
  isLoading: boolean;
}

const salesItemInitState: ISalesItemState = {
  items: [],
  deletedItems: [],
  isLoading: false,
};

const salesItemSlice = createSlice({
  name: "sales-item",
  initialState: salesItemInitState,
  reducers: {
    changeSalesItemLoading: (
      state: ISalesItemState,
      action: PayloadAction<boolean>
    ) => {
      if (state.isLoading !== action.payload) {
        state.isLoading = action.payload;
      }
    },
    setSalesItemState: (
      state: ISalesItemState,
      action: PayloadAction<{ items: ISalesItem[]; refresh?: boolean }>
    ) => {
      if (state.items.length <= 0 || action.payload.refresh) {
        state.items = action.payload.items || state.items;
      }
      state.isLoading = false;
    },
    setSalesDeletedItemState: (
      state: ISalesItemState,
      action: PayloadAction<{ items: ISalesItem[]; refresh?: boolean }>
    ) => {
      if (state.deletedItems.length <= 0 || action.payload.refresh) {
        state.deletedItems = action.payload.items || state.deletedItems;
      }
      state.isLoading = false;
    },
    addSalesItemState: (
      state: ISalesItemState,
      action: PayloadAction<ISalesItem>
    ) => {
      state.items.push(action.payload);
      state.isLoading = false;
    },
    editSalesItemState: (
      state: ISalesItemState,
      action: PayloadAction<ISalesItem>
    ) => {
      state.items = state.items.map((item) => {
        if (action.payload._id === item._id) {
          item = action.payload;
        }
        return item;
      });
      state.isLoading = false;
    },
    changeSalesItemStatusState: (
      state: ISalesItemState,
      action: PayloadAction<ISalesItem>
    ) => {
      state.items = state.items.map((item) => {
        if (
          action.payload._id === item._id &&
          action.payload.status !== item.status
        ) {
          item.status = action.payload.status;
        }
        return item;
      });
      state.isLoading = false;
    },
    changeSalesItemVisibilityState: (
      state: ISalesItemState,
      action: PayloadAction<ISalesItem>
    ) => {
      state.items = state.items.map((item) => {
        if (
          action.payload._id === item._id &&
          action.payload.visibility !== item.visibility
        ) {
          item.visibility = action.payload.visibility;
        }
        return item;
      });
      state.isLoading = false;
    },
    deleteSalesItemState: (
      state: ISalesItemState,
      action: PayloadAction<ISalesItem["_id"]>
    ) => {
      state.items = state.items.filter((item) => item._id !== action.payload);
      state.isLoading = false;
    },
    pDeleteSalesItemState: (
      state: ISalesItemState,
      action: PayloadAction<ISalesItem["_id"]>
    ) => {
      state.deletedItems = state.deletedItems.filter(
        (item) => item._id !== action.payload
      );
      state.isLoading = false;
    },
    restoreSalesItemState: (
      state: ISalesItemState,
      action: PayloadAction<ISalesItem>
    ) => {
      state.items.push(action.payload);
      state.isLoading = false;
    },
    clearSalesItemState: (state: ISalesItemState) => {
      state = salesItemInitState;
    },
  },
});

export const {
  changeSalesItemLoading,
  setSalesItemState,
  pDeleteSalesItemState,
  addSalesItemState,
  editSalesItemState,
  changeSalesItemStatusState,
  changeSalesItemVisibilityState,
  deleteSalesItemState,
  setSalesDeletedItemState,
  restoreSalesItemState,
  clearSalesItemState,
} = salesItemSlice.actions;

export default salesItemSlice.reducer;
