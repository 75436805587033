import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IHoliday } from "../types/default";

interface IHolidayState {
  holidays: IHoliday[];
  deletedHolidays: IHoliday[];
  isLoading: boolean;
}

const holidayInitState: IHolidayState = {
  holidays: [],
  deletedHolidays: [],
  isLoading: false,
};

const holidaySlice = createSlice({
  name: "holiday",
  initialState: holidayInitState,
  reducers: {
    changeHolidayLoading: (
      state: IHolidayState,
      action: PayloadAction<boolean>
    ) => {
      if (state.isLoading !== action.payload) {
        state.isLoading = action.payload;
      }
    },
    setHolidaysState: (
      state: IHolidayState,
      action: PayloadAction<{ holidays: IHoliday[]; refresh?: boolean }>
    ) => {
      if (state.holidays.length <= 0 || action.payload.refresh) {
        state.holidays = action.payload.holidays || state.holidays;
      }
      state.isLoading = false;
    },
    setDeletedHolidaysState: (
      state: IHolidayState,
      action: PayloadAction<{ holidays: IHoliday[]; refresh?: boolean }>
    ) => {
      if (state.deletedHolidays.length <= 0 || action.payload.refresh) {
        state.deletedHolidays =
          action.payload.holidays || state.deletedHolidays;
      }
      state.isLoading = false;
    },
    addHolidayState: (
      state: IHolidayState,
      action: PayloadAction<IHoliday>
    ) => {
      state.holidays.push(action.payload);
      state.isLoading = false;
    },
    editHolidayState: (
      state: IHolidayState,
      action: PayloadAction<IHoliday>
    ) => {
      state.holidays = state.holidays.map((holiday) => {
        if (action.payload._id === holiday._id) {
          holiday = action.payload;
        }
        return holiday;
      });
      state.isLoading = false;
    },
    deleteHolidayState: (
      state: IHolidayState,
      action: PayloadAction<IHoliday["_id"]>
    ) => {
      state.holidays = state.holidays.filter(
        (holiday) => holiday._id !== action.payload
      );
      state.isLoading = false;
    },
    pDeleteHolidayState: (
      state: IHolidayState,
      action: PayloadAction<IHoliday["_id"]>
    ) => {
      state.deletedHolidays = state.deletedHolidays.filter(
        (holiday) => holiday._id !== action.payload
      );
      state.isLoading = false;
    },
    restoreHolidayState: (
      state: IHolidayState,
      action: PayloadAction<IHoliday>
    ) => {
      state.holidays.push(action.payload);
      state.isLoading = false;
    },
    clearHolidayState: (state: IHolidayState) => {
      state = holidayInitState;
    },
  },
});

export const {
  changeHolidayLoading,
  setHolidaysState,
  setDeletedHolidaysState,
  addHolidayState,
  editHolidayState,
  deleteHolidayState,
  pDeleteHolidayState,
  restoreHolidayState,
  clearHolidayState,
} = holidaySlice.actions;

export default holidaySlice.reducer;
