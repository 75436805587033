import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IServiceModel } from "../../types/services";

interface IServiceModelState {
  models: IServiceModel[];
  deletedModels: IServiceModel[];
  isLoading: boolean;
}

const serviceModelInitState: IServiceModelState = {
  models: [],
  deletedModels: [],
  isLoading: false,
};

const serviceModelSlice = createSlice({
  name: "service-model",
  initialState: serviceModelInitState,
  reducers: {
    changeServiceModelLoading: (
      state: IServiceModelState,
      action: PayloadAction<boolean>
    ) => {
      if (state.isLoading !== action.payload) {
        state.isLoading = action.payload;
      }
    },
    setServiceModelsState: (
      state: IServiceModelState,
      action: PayloadAction<{ models: IServiceModel[]; refresh?: boolean }>
    ) => {
      if (state.models.length <= 0 || action.payload.refresh) {
        state.models = action.payload.models || state.models;
      }
      state.isLoading = false;
    },
    setServiceDeletedModelsState: (
      state: IServiceModelState,
      action: PayloadAction<{ models: IServiceModel[]; refresh?: boolean }>
    ) => {
      if (state.deletedModels.length <= 0 || action.payload.refresh) {
        state.deletedModels = action.payload.models || state.deletedModels;
      }
      state.isLoading = false;
    },
    addServiceModelState: (
      state: IServiceModelState,
      action: PayloadAction<IServiceModel>
    ) => {
      state.models.unshift(action.payload);
      state.isLoading = false;
    },
    editServiceModelState: (
      state: IServiceModelState,
      action: PayloadAction<IServiceModel>
    ) => {
      state.models = state.models.map((model) => {
        if (action.payload._id === model._id) {
          model = action.payload;
        }
        return model;
      });
      state.isLoading = false;
    },
    changeServiceModelStatusState: (
      state: IServiceModelState,
      action: PayloadAction<IServiceModel>
    ) => {
      state.models = state.models.map((model) => {
        if (
          action.payload._id === model._id &&
          action.payload.status !== model.status
        ) {
          model.status = action.payload.status;
        }
        return model;
      });
      state.isLoading = false;
    },
    changeServiceModelVisibilityState: (
      state: IServiceModelState,
      action: PayloadAction<IServiceModel>
    ) => {
      state.models = state.models.map((model) => {
        if (
          action.payload._id === model._id &&
          action.payload.visibility !== model.visibility
        ) {
          model.visibility = action.payload.visibility;
        }
        return model;
      });
      state.isLoading = false;
    },
    deleteServiceModelState: (
      state: IServiceModelState,
      action: PayloadAction<IServiceModel["_id"]>
    ) => {
      state.models = state.models.filter(
        (model) => model._id !== action.payload
      );
      state.isLoading = false;
    },
    pDeleteServiceModelState: (
      state: IServiceModelState,
      action: PayloadAction<IServiceModel["_id"]>
    ) => {
      state.deletedModels = state.deletedModels.filter(
        (model) => model._id !== action.payload
      );
      state.isLoading = false;
    },
    restoreServiceModelState: (
      state: IServiceModelState,
      action: PayloadAction<IServiceModel>
    ) => {
      state.models.unshift(action.payload);
      state.isLoading = false;
    },
    clearServiceModelState: (state: IServiceModelState) => {
      state = serviceModelInitState;
    },
  },
});

export const {
  changeServiceModelLoading,
  setServiceModelsState,
  setServiceDeletedModelsState,
  addServiceModelState,
  editServiceModelState,
  changeServiceModelStatusState,
  changeServiceModelVisibilityState,
  deleteServiceModelState,
  pDeleteServiceModelState,
  restoreServiceModelState,
  clearServiceModelState,
} = serviceModelSlice.actions;

export default serviceModelSlice.reducer;
