import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IService } from "../../types/services";

interface IServiceState {
  services: IService[];
  deletedServices: IService[];
  isLoading: boolean;
}

const serviceInitState: IServiceState = {
  services: [],
  deletedServices: [],
  isLoading: false,
};

const serviceSlice = createSlice({
  name: "service",
  initialState: serviceInitState,
  reducers: {
    changeServiceLoading: (
      state: IServiceState,
      action: PayloadAction<boolean>
    ) => {
      if (state.isLoading !== action.payload) {
        state.isLoading = action.payload;
      }
    },
    setServicesState: (
      state: IServiceState,
      action: PayloadAction<{ services: IService[]; refresh?: boolean }>
    ) => {
      if (state.services.length <= 0 || action.payload.refresh) {
        state.services = action.payload.services || state.services;
      }
      state.isLoading = false;
    },
    setDeletedServicesState: (
      state: IServiceState,
      action: PayloadAction<{ services: IService[]; refresh?: boolean }>
    ) => {
      if (state.deletedServices.length <= 0 || action.payload.refresh) {
        state.deletedServices =
          action.payload.services || state.deletedServices;
      }
      state.isLoading = false;
    },
    addServiceState: (
      state: IServiceState,
      action: PayloadAction<IService>
    ) => {
      state.services.unshift(action.payload);
      state.isLoading = false;
    },
    editServiceState: (
      state: IServiceState,
      action: PayloadAction<IService>
    ) => {
      state.services = state.services.map((service) => {
        if (action.payload._id === service._id) {
          service = action.payload;
        }
        return service;
      });
      state.isLoading = false;
    },
    changeServiceStatusState: (
      state: IServiceState,
      action: PayloadAction<IService>
    ) => {
      state.services = state.services.map((service) => {
        if (
          action.payload._id === service._id &&
          action.payload.status !== service.status
        ) {
          service.status = action.payload.status;
        }
        return service;
      });
      state.isLoading = false;
    },
    deleteServiceState: (
      state: IServiceState,
      action: PayloadAction<IService["_id"]>
    ) => {
      state.services = state.services.filter(
        (service) => service._id !== action.payload
      );
      state.isLoading = false;
    },
    pDeleteServiceState: (
      state: IServiceState,
      action: PayloadAction<IService["_id"]>
    ) => {
      state.deletedServices = state.deletedServices.filter(
        (service) => service._id !== action.payload
      );
      state.isLoading = false;
    },
    restoreServiceState: (
      state: IServiceState,
      action: PayloadAction<IService>
    ) => {
      state.services.unshift(action.payload);
      state.isLoading = false;
    },
    clearServiceState: (state: IServiceState) => {
      state = serviceInitState;
    },
  },
});

export const {
  changeServiceLoading,
  setServicesState,
  setDeletedServicesState,
  addServiceState,
  editServiceState,
  changeServiceStatusState,
  deleteServiceState,
  pDeleteServiceState,
  restoreServiceState,
  clearServiceState,
} = serviceSlice.actions;

export default serviceSlice.reducer;
