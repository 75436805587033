import React, { useEffect, useMemo } from "react";
import { useAppSelector } from "../../../redux/hooks";
import Table from "../../../components/Table";
import Layout from "../../../components/Layout";
import Loader from "../../../components/Loader";
import { useNavigate } from "react-router-dom";
import { KRoutes } from "../../../routers/router";
import salesItemsTableHeaders from "./salesItemTableHeader";
import { getSalesItems } from "../../../services/sales";
import { autoRefreshDelay } from "../../../core/constants";

const ScreenSalesItems = () => {
  const { items, isLoading } = useAppSelector((state) => state.salesItems);
  const columns = useMemo(() => salesItemsTableHeaders, []);
  const data = useMemo(() => items, [items]);
  const navigate = useNavigate();

  useEffect(() => {
    const refreshInterval = setInterval(
      () => getSalesItems(true),
      autoRefreshDelay * 1000
    );
    return () => {
      clearInterval(refreshInterval);
    };
  }, []);

  return (
    <Layout title="sales-items">
      {(isLoading && items.length <= 0) ? (
        <Loader />
      ) : (
        <Table
          columns={columns}
          data={data}
          tableTitle={"Sales Items"}
          buttons={{
            addButton: true,
            addButtonTitle: "Add Item",
            onClickAddButton: () => {
              navigate(KRoutes.addSalesItem);
            },
            exportButton: false,
          }}
        />
      )}
    </Layout>
  );
};

export default ScreenSalesItems;