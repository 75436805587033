import httpClient from "../../core/axios";
import ApiEndpoints from "../../core/apiEndpoints";
import store, { dispatch } from "../../redux/store";
import { setErrorMessage, setSuccessMessage } from "../../redux/notification";
import {
  changeServiceModelLoading,
  setServiceModelsState,
  addServiceModelState,
  changeServiceModelStatusState,
  changeServiceModelVisibilityState,
  deleteServiceModelState,
  editServiceModelState,
  setServiceDeletedModelsState,
  restoreServiceModelState,
  pDeleteServiceModelState,
} from "../../redux/services";

/**
 * To get a service categories from server
 */
export const getServiceModels = async (refresh?: boolean) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeServiceModelLoading(true));

    // API Fetching Deleted
    if (store.getState().admins.profile?.role === "MasterAdmin")
      getDeletedServiceModels(refresh);

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.serviceModel);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setServiceModelsState({ models: resp.data.data, refresh }));
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceModelLoading(false));
  }
};

/**
 * To get a deleted model from server
 */
export const getDeletedServiceModels = async (refresh?: boolean) => {
  try {
    // Set Loading
    if (!refresh) dispatch(changeServiceModelLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.deletedServiceModel);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setServiceDeletedModelsState({ models: resp.data.data, refresh }));
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceModelLoading(false));
  }
};

/**
 * To add a new service model
 */
export const addServiceModel = async (modelForm: FormData) => {
  try {
    // Set Loading
    dispatch(changeServiceModelLoading(true));

    // API Fetching
    const resp = await httpClient("multipart/form-data").post(
      ApiEndpoints.serviceModel,
      modelForm
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(addServiceModelState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceModelLoading(false));
  }
};

/**
 * To add a new service model
 */
export const editServiceModel = async (id: string, modelForm: FormData) => {
  try {
    // Set Loading
    dispatch(changeServiceModelLoading(true));

    // API Fetching
    const resp = await httpClient("multipart/form-data").patch(
      ApiEndpoints.serviceModel + "/" + id,
      modelForm
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(editServiceModelState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceModelLoading(false));
  }
};

/**
 * To change the status
 */
export const changeServiceModelStatus = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeServiceModelLoading(true));

    // API Fetching
    const resp = await httpClient().get(
      ApiEndpoints.changeServiceModelStatus + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeServiceModelStatusState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceModelLoading(false));
  }
};

/**
 * To change the visibility
 */
export const changeServiceModelVisibility = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeServiceModelLoading(true));

    // API Fetching
    const resp = await httpClient().get(
      ApiEndpoints.changeServiceModelVisibility + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeServiceModelVisibilityState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceModelLoading(false));
  }
};

/**
 * To delete the model
 */
export const deleteServiceModel = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeServiceModelLoading(true));

    // API Fetching
    const resp = await httpClient().delete(
      ApiEndpoints.serviceModel + "/" + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(deleteServiceModelState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceModelLoading(false));
  }
};

/**
 * To restore the service model
 */
export const restoreServiceModel = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeServiceModelLoading(true));

    // API Fetching
    const resp = await httpClient().delete(
      ApiEndpoints.restoreServiceModel + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(restoreServiceModelState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceModelLoading(false));
  }
};

/**
 * To delete the service model permanently
 */
export const pDeleteServiceModel = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeServiceModelLoading(true));

    // API Fetching
    const resp = await httpClient().delete(
      ApiEndpoints.deleteServiceModel + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(pDeleteServiceModelState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceModelLoading(false));
  }
};
