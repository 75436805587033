import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IServiceBanner } from "../../types/services";

interface IServiceBannerState {
  banners: IServiceBanner[];
  deletedBanners: IServiceBanner[];
  isLoading: boolean;
}

const serviceBannerInitState: IServiceBannerState = {
  banners: [],
  deletedBanners: [],
  isLoading: false,
};

const serviceBannerSlice = createSlice({
  name: "service-banner",
  initialState: serviceBannerInitState,
  reducers: {
    changeServiceBannerLoading: (
      state: IServiceBannerState,
      action: PayloadAction<boolean>
    ) => {
      if (state.isLoading !== action.payload) {
        state.isLoading = action.payload;
      }
    },
    setServiceBannersState: (
      state: IServiceBannerState,
      action: PayloadAction<{ banners: IServiceBanner[]; refresh?: boolean }>
    ) => {
      if (state.banners.length <= 0 || action.payload.refresh) {
        state.banners = action.payload.banners || state.banners;
      }
      state.isLoading = false;
    },
    setServiceDeletedBannersState: (
      state: IServiceBannerState,
      action: PayloadAction<{ banners: IServiceBanner[]; refresh?: boolean }>
    ) => {
      if (state.deletedBanners.length <= 0 || action.payload.refresh) {
        state.deletedBanners = action.payload.banners || state.deletedBanners;
      }
      state.isLoading = false;
    },
    addServiceBannerState: (
      state: IServiceBannerState,
      action: PayloadAction<IServiceBanner>
    ) => {
      state.banners.unshift(action.payload);
      state.isLoading = false;
    },
    editServiceBannerState: (
      state: IServiceBannerState,
      action: PayloadAction<IServiceBanner>
    ) => {
      state.banners = state.banners.map((banner) => {
        if (action.payload._id === banner._id) {
          banner = action.payload;
        }
        return banner;
      });
      state.isLoading = false;
    },
    changeServiceBannerStatusState: (
      state: IServiceBannerState,
      action: PayloadAction<IServiceBanner>
    ) => {
      state.banners = state.banners.map((banner) => {
        if (
          action.payload._id === banner._id &&
          action.payload.status !== banner.status
        ) {
          banner.status = action.payload.status;
        }
        return banner;
      });
      state.isLoading = false;
    },
    changeServiceBannerVisibilityState: (
      state: IServiceBannerState,
      action: PayloadAction<IServiceBanner>
    ) => {
      state.banners = state.banners.map((banner) => {
        if (
          action.payload._id === banner._id &&
          action.payload.visibility !== banner.visibility
        ) {
          banner.visibility = action.payload.visibility;
        }
        return banner;
      });
      state.isLoading = false;
    },
    deleteServiceBannerState: (
      state: IServiceBannerState,
      action: PayloadAction<IServiceBanner["_id"]>
    ) => {
      state.banners = state.banners.filter(
        (banner) => banner._id !== action.payload
      );
      state.isLoading = false;
    },
    pDeleteServiceBannerState: (
      state: IServiceBannerState,
      action: PayloadAction<IServiceBanner["_id"]>
    ) => {
      state.deletedBanners = state.deletedBanners.filter(
        (banner) => banner._id !== action.payload
      );
      state.isLoading = false;
    },
    restoreServiceBannerState: (
      state: IServiceBannerState,
      action: PayloadAction<IServiceBanner>
    ) => {
      state.banners.unshift(action.payload);
      state.isLoading = false;
    },
    clearServiceBannerState: (state: IServiceBannerState) => {
      state = serviceBannerInitState;
    },
  },
});

export const {
  changeServiceBannerLoading,
  setServiceBannersState,
  setServiceDeletedBannersState,
  addServiceBannerState,
  editServiceBannerState,
  changeServiceBannerStatusState,
  changeServiceBannerVisibilityState,
  deleteServiceBannerState,
  pDeleteServiceBannerState,
  restoreServiceBannerState,
  clearServiceBannerState,
} = serviceBannerSlice.actions;

export default serviceBannerSlice.reducer;
