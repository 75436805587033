import React, {
  createRef,
  LegacyRef,
  useCallback,
  useEffect,
  useState,
} from "react";
import Multiselect from "multiselect-react-dropdown";
import {
  GridView,
  SelectInput,
  FileUpload,
  TextField,
} from "../../../components/TextFieldItems";
import Layout from "../../../components/Layout";
import { useAppSelector } from "../../../redux/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { KRoutes } from "../../../routers/router";
import { ISelectedImage } from "../../../types/default";
import ApiEndpoints from "../../../core/apiEndpoints";
import { addServiceModel, editServiceModel } from "../../../services/services";
import Loader from "../../../components/Loader";

const ScreenAddServiceModel = () => {
  const { types } = useAppSelector((state) => state.serviceTypes);
  const { categories } = useAppSelector((state) => state.serviceCategories);
  const { models, isLoading } = useAppSelector((state) => state.serviceModels);
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [additionalPricePercent, setAdditionalPricePercent] = useState("0");
  const [additionalDuration, setAdditionalDuration] = useState("0");
  const [selectedFile, setSelectedFile] = useState<ISelectedImage>({
    file: null,
    URL: null,
  });
  const [visibility, setVisibility] = useState("Show");
  const [status, setStatus] = useState("Active");
  const navigate = useNavigate();
  const params = useParams();
  const selectedTypes: LegacyRef<Multiselect> = createRef();

  const setEditFields = useCallback(
    (id: string) => {
      const model = models.find((c) => c._id === id);
      if (model) {
        setName(model.name);
        setCategory(model.category._id);
        setAdditionalPricePercent(model.additionalPricePercent);
        setAdditionalDuration(model.additionalDuration);
        setSelectedFile((prevState) => ({
          ...prevState,
          URL: ApiEndpoints.serviceModelImage + model._id,
        }));
        selectedTypes.current?.setState(
          model.types?.map((type) => {
            return {
              id: type._id,
              name: type.name,
            };
          })
        )
        setStatus(model.status);
        setVisibility(model.visibility);
      }
    },
    [models]
  );

  useEffect(() => {
    if (params.mid) {
      setEditFields(params.mid);
    }
  }, [params.mid, setEditFields]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    if (!category || !name || (!params.mid && !selectedFile.file)) {
      toast("Fill name, category, types, image");
      return;
    } else {
      const categoryForm = new FormData();
      categoryForm.append("name", name);
      categoryForm.append("category", category);
      categoryForm.append("additionalPricePercent", additionalPricePercent);
      categoryForm.append("additionalDuration", additionalDuration);
      categoryForm.append("status", status);
      categoryForm.append("visibility", visibility);
      if (selectedTypes.current?.getSelectedItemsCount() > 0) {
        const types = selectedTypes.current
          ?.getSelectedItems()
          .map((type: any) => type.id);
        categoryForm.append("types", JSON.stringify(types));
      }
      if (selectedFile.file) {
        categoryForm.append("image", selectedFile.file);
      }

      const success = params.mid
        ? await editServiceModel(params.mid, categoryForm)
        : await addServiceModel(categoryForm);
      if (success) {
        navigate(KRoutes.serviceModels);
      }
    }
  };
  return (
    <Layout title="service-models">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="p-10 overflow-y-scroll overflow-x-hidden scrollbar-hide">
          <div className="flex justify-between">
            <p className="lg:text-3xl text-2xl font-black dark:text-white ">
              {params.mid ? "Edit" : "Add"} Model
            </p>
            <div>
              <button
                className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800`"
                onClick={() => navigate(-1)}
              >
                <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                  Back
                </span>
              </button>
            </div>
          </div>
          <form className="mt-6 " onSubmit={handleSubmit}>
            <GridView className="gap-6 mb-6 lg:grid-cols-2">
              <>
                <TextField
                  label="Name"
                  id="name"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="text"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="name"
                  value={name}
                  required={true}
                  placeholder="Model Name"
                  onChange={(e) => setName(e.target.value)}
                />
                <SelectInput
                  data={categories.map((category) => {
                    return {
                      id: category._id,
                      title: category.name,
                    };
                  })}
                  title="Category"
                  value={category}
                  disabled={params.mid ? true : false}
                  onChange={(e) => {
                    setCategory(e.target.value);
                  }}
                />
                <div className="flex gap-2 w-full">
                  <SelectInput
                    data={[
                      {
                        id: "Active",
                        title: "Active",
                      },
                      {
                        id: "Inactive",
                        title: "Inactive",
                      },
                    ]}
                    title="Status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  />
                  <SelectInput
                    data={[
                      {
                        id: "Show",
                        title: "Show",
                      },
                      {
                        id: "Hide",
                        title: "Hide",
                      },
                    ]}
                    title="Visibility"
                    value={visibility}
                    onChange={(e) => setVisibility(e.target.value)}
                  />
                </div>
                <div className="flex gap-2 w-full">
                  <TextField
                    label="Additional Price Percentage"
                    className="mb-5 w-1/2"
                    id="price"
                    labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    type="text"
                    inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    name="price"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    title="Must contain number or decimal values Eg:1.00"
                    value={additionalPricePercent}
                    required={true}
                    placeholder="Additional Price Percentage (%)"
                    onChange={(e) => setAdditionalPricePercent(e.target.value)}
                  />
                  <TextField
                    label="Additional Duration"
                    className="mb-5 w-1/2"
                    id="duration"
                    labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    type="text"
                    inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    name="duration"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    title="Must contain number Eg:10 (min)"
                    value={additionalDuration}
                    required={true}
                    placeholder="Additional Duration (min)"
                    onChange={(e) => setAdditionalDuration(e.target.value)}
                  />
                </div>
                <FileUpload
                  title="Image"
                  desc=" PNG, JPG  (MAX. 800x400px)."
                  accept="image/*"
                  maxSize={5}
                  file={selectedFile.file}
                  url={selectedFile.URL}
                  onFileSelect={(file) => {
                    setSelectedFile({
                      file: file,
                      URL: URL.createObjectURL(file),
                    });
                  }}
                  removeSelectedFile={() => {
                    setSelectedFile({
                      file: null,
                      URL: "",
                    });
                  }}
                />
                <div>
                  <label
                    htmlFor="types"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Types
                  </label>
                  <Multiselect
                    options={
                      category.length > 0
                        ? types
                            .filter((type) => type.category._id === category)
                            .map((type) => {
                              return {
                                id: type._id,
                                name: type.name,
                              };
                            })
                        : []
                    }
                    id="types"
                    showArrow={true}
                    placeholder="Search types"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    ref={selectedTypes}
                    displayValue="name"
                    avoidHighlightFirstOption={true}
                    keepSearchTerm={true}
                  />
                </div>
              </>
            </GridView>
            <div className="flex items-center justify-center -mx-3 my-6">
              <div className="w-full lg:w-1/2 px-3">
                <button
                  type="submit"
                  className="w-full active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-3 bg-primary-color text-white  text-lg font-bold rounded-xl"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </Layout>
  );
};

export default ScreenAddServiceModel;
