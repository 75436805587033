import { Column } from "react-table";
import { SelectColumnFilter } from "../../../components/Table";
import {
  ButtonsPill,
  DateFromatPill,
  ImageCell,
  StatusPill,
  TextPill,
  VisibilityPill,
} from "../../../components/TableCells";
import ApiEndpoints from "../../../core/apiEndpoints";
import store from "../../../redux/store";
import { KRoutes } from "../../../routers/router";
import {
  changeSalesItemStatus,
  changeSalesItemVisibility,
  deleteSalesItem,
} from "../../../services/sales/item";
import { ISalesItem } from "../../../types/sales";

const salesItemsTableHeaders: Column<ISalesItem>[] = [
  {
    id: "name",
    Header: "Name",
    accessor: "name",
  },
  {
    id: "image",
    Header: "Image",
    accessor: "_id",
    Cell: ({ value }) => ImageCell(ApiEndpoints.salesItemImage + value),
  },
  {
    id: "desc",
    Header: "Description",
    accessor: "description",
  },
  {
    id: "qty",
    Header: "Quantity",
    accessor: "quantity",
  },
  {
    id: "pprice",
    Header: "Purchase Price",
    accessor: "purchasePrice",
  },
  {
    id: "sprice",
    Header: "Sale Price",
    accessor: "salePrice",
  },
  {
    id: "taxPercent",
    Header: "Tax %",
    accessor: "tax",
    Cell: ({ value }) => TextPill({ value: value + " %", className: "truncate overflow-hidden text-gray-500" }),
  },
  {
    id: "mrp",
    Header: "MRP",
    accessor: "MRP",
  },
  {
    id: "redeem",
    Header: "Redeem Point",
    accessor: "radeempoint",
    Cell: ({ value }) => TextPill({ value: value + " RP", className: "truncate overflow-hidden text-gray-500" }),
  },
  {
    id: "discount",
    Header: "Discount",
    accessor: "discount",
    Cell: ({ value }) => TextPill({ value: value + " %", className: "truncate overflow-hidden text-gray-500" }),
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: StatusPill,
    Filter: SelectColumnFilter, // new
    filter: "includes",
  },
  {
    Header: "Visibility",
    accessor: "visibility",
    Cell: VisibilityPill,
    Filter: SelectColumnFilter, // new
    filter: "includes",
  },
  {
    Header: "CreatedAt",
    accessor: "createdAt",
    Cell: DateFromatPill,
  },
  {
    Header: "UpdatedAt",
    accessor: "updatedAt",
    Cell: DateFromatPill,
  },
  {
    Header: "Action",
    accessor: "_id",
    Cell: ({ value }) => {
      return ButtonsPill({
        statusChangeButton: true,
        visibilityChangeButton: true,
        editButton: false,
         deleteButton: ["MasterAdmin", "Developer"].includes(store.getState().admins.profile?.role || "")
          ? true
          : false,
        editPath: KRoutes.editSalesItem + "/" + value,
        onClickDelete: () => deleteSalesItem(value),
        onClickStatusChange: () => changeSalesItemStatus(value),
        onClickVisibilityChange: () => changeSalesItemVisibility(value),
      });
    },
  },
];

export default salesItemsTableHeaders;
