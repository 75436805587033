import React, { useCallback, useEffect, useState } from "react";
import {
  TextField,
  GridView,
  SelectInput,
  TextAreaField,
} from "../../components/TextFieldItems";
import Layout from "../../components/Layout";
import { useAppSelector } from "../../redux/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { KRoutes } from "../../routers/router";
import {
  addAgent,
  editAgent,
} from "../../services/agents/agents";
import Loader from "../../components/Loader";

const ScreenAddAgent = () => {
  const { locations } = useAppSelector((state) => state.locations);
  const { agents, isLoading } = useAppSelector(
    (state) => state.agents
  );
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const params = useParams();

  const setEditFields = useCallback(
    (id: string) => {
      const agent = agents.find((t) => t._id === id);
      if (agent) {
        setName(agent.name);
        setLocation(agent.location._id);
        setPhone(agent.phone);
        setAddress(agent.address);
        setPassword(agent.password!);
      }
    },
    [agents]
  );

  useEffect(() => {
    if (params.tid) {
      setEditFields(params.tid);
    }
  }, [params.tid, setEditFields]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    if (!name || !phone || !location || !address || !password) {
      toast("Fill name, phone, location, address and password");
      return;
    } else {
      const agentData = {
        name,
        location,
        phone,
        address,
        password,
      };
      const success = params.tid
        ? await editAgent(params.tid, agentData)
        : await addAgent(agentData);
      if (success) {
        navigate(KRoutes.agents);
      }
    }
  };
  return (
    <Layout title="service-types">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="p-10 overflow-y-scroll overflow-x-hidden scrollbar-hide">
          <div className="flex justify-between">
            <p className="lg:text-3xl text-2xl font-black dark:text-white ">
              {params.tid ? "Edit" : "Add"} Agent
            </p>
            <div>
              <button
                className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800`"
                onClick={() => navigate(-1)}
              >
                <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                  Back
                </span>
              </button>
            </div>
          </div>
          <form className="mt-6 " onSubmit={handleSubmit}>
            <GridView className="gap-6 mb-6 lg:grid-cols-2">
              <>
                <TextField
                  label="Name"
                  id="name"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="text"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="name"
                  value={name}
                  required={true}
                  placeholder="Agent Name"
                  onChange={(e) => setName(e.target.value)}
                />
                <SelectInput
                  data={locations.map((location) => {
                    return {
                      id: location._id,
                      title: location.name,
                    };
                  })}
                  title="Location"
                  value={location}
                  disabled={params.tid ? true : false}
                  onChange={(e) => setLocation(e.target.value)}
                />
                <TextField
                  label="Phone"
                  className="mb-5"
                  id="phone"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="tel"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="phone"
                  pattern="[6789][0-9]{9}"
                  title="Please enter valid phone number"
                  value={phone}
                  required={true}
                  placeholder="Agent Phone Number"
                  onChange={(e) => setPhone(e.target.value)}
                />
                <TextField
                  label="Password"
                  className="mb-5"
                  id="password"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="password"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="password"
                  value={password}
                  required={true}
                  placeholder="Agent Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <TextAreaField
                  label="Address"
                  id="address"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="address"
                  value={address}
                  required={true}
                  placeholder="Address"
                  onChange={(e) => setAddress(e.target.value)}
                />
              </>
            </GridView>
            <div className="flex items-center justify-center -mx-3 my-6">
              <div className="w-full lg:w-1/2 px-3">
                <button
                  type="submit"
                  className="w-full active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-3 bg-primary-color text-white  text-lg font-bold rounded-xl"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </Layout>
  );
};

export default ScreenAddAgent;
