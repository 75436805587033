import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IServicePlan } from "../../types/services";

interface IServicePlanState {
  plans: IServicePlan[];
  deletedPlans: IServicePlan[];
  isLoading: boolean;
}

const servicePlanInitState: IServicePlanState = {
  plans: [],
  deletedPlans: [],
  isLoading: false,
};

const servicePlanSlice = createSlice({
  name: "service-plan",
  initialState: servicePlanInitState,
  reducers: {
    changeServicePlanLoading: (
      state: IServicePlanState,
      action: PayloadAction<boolean>
    ) => {
      if (state.isLoading !== action.payload) {
        state.isLoading = action.payload;
      }
    },
    setServicePlansState: (
      state: IServicePlanState,
      action: PayloadAction<{ plans: IServicePlan[]; refresh?: boolean }>
    ) => {
      if (state.plans.length <= 0 || action.payload.refresh) {
        state.plans = action.payload.plans || state.plans;
      }
      state.isLoading = false;
    },
    setServiceDeletedPlansState: (
      state: IServicePlanState,
      action: PayloadAction<{ plans: IServicePlan[]; refresh?: boolean }>
    ) => {
      if (state.deletedPlans.length <= 0 || action.payload.refresh) {
        state.deletedPlans = action.payload.plans || state.deletedPlans;
      }
      state.isLoading = false;
    },
    addServicePlanState: (
      state: IServicePlanState,
      action: PayloadAction<IServicePlan>
    ) => {
      state.plans.push(action.payload);
      state.isLoading = false;
    },
    editServicePlanState: (
      state: IServicePlanState,
      action: PayloadAction<IServicePlan>
    ) => {
      state.plans = state.plans.map((plan) => {
        if (action.payload._id === plan._id) {
          plan = action.payload;
        }
        return plan;
      });
      state.isLoading = false;
    },
    changeServicePlanStatusState: (
      state: IServicePlanState,
      action: PayloadAction<IServicePlan>
    ) => {
      state.plans = state.plans.map((plan) => {
        if (
          action.payload._id === plan._id &&
          action.payload.status !== plan.status
        ) {
          plan.status = action.payload.status;
        }
        return plan;
      });
      state.isLoading = false;
    },
    changeServicePlanVisibilityState: (
      state: IServicePlanState,
      action: PayloadAction<IServicePlan>
    ) => {
      state.plans = state.plans.map((plan) => {
        if (
          action.payload._id === plan._id &&
          action.payload.visibility !== plan.visibility
        ) {
          plan.visibility = action.payload.visibility;
        }
        return plan;
      });
      state.isLoading = false;
    },
    deleteServicePlanState: (
      state: IServicePlanState,
      action: PayloadAction<IServicePlan["_id"]>
    ) => {
      state.plans = state.plans.filter((plan) => plan._id !== action.payload);
      state.isLoading = false;
    },
    pDeleteServicePlanState: (
      state: IServicePlanState,
      action: PayloadAction<IServicePlan["_id"]>
    ) => {
      state.deletedPlans = state.deletedPlans.filter(
        (plan) => plan._id !== action.payload
      );
      state.isLoading = false;
    },
    restoreServicePlanState: (
      state: IServicePlanState,
      action: PayloadAction<IServicePlan>
    ) => {
      state.plans.push(action.payload);
      state.isLoading = false;
    },
    clearServicePlanState: (state: IServicePlanState) => {
      state = servicePlanInitState;
    },
  },
});

export const {
  changeServicePlanLoading,
  setServicePlansState,
  setServiceDeletedPlansState,
  addServicePlanState,
  editServicePlanState,
  changeServicePlanStatusState,
  changeServicePlanVisibilityState,
  deleteServicePlanState,
  pDeleteServicePlanState,
  restoreServicePlanState,
  clearServicePlanState,
} = servicePlanSlice.actions;

export default servicePlanSlice.reducer;
