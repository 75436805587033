import React, { useMemo } from "react";
import { useAppSelector } from "../../../redux/hooks";
import Table from "../../../components/Table";
import Layout from "../../../components/Layout";
import Loader from "../../../components/Loader";
import serviceCustomPlanTableHeaders from "./serviceCustomPlanTableHeaders";

const ScreenServiceCustomPlan = () => {
  const { customPlans, isLoading } = useAppSelector((state) => state.serviceCustomPlans);
  const columns = useMemo(() => serviceCustomPlanTableHeaders, []);
  const data = useMemo(() => customPlans, [customPlans]);

  // useEffect(() => {
  //   const refreshInterval = setInterval(
  //     () => getServiceCustomPlan(true),
  //     autoRefreshDelay * 1000
  //   );
  //   return () => {
  //     clearInterval(refreshInterval);
  //   };
  // }, []);

  return (
    <Layout title="service-custom-plans">
      {(isLoading && customPlans.length <= 0) ? (
        <Loader />
      ) : (
        <Table
          columns={columns}
          data={data}
          tableTitle={"Service Custom Plans"}
          buttons={{
            addButton: false,
            exportButton: false,
          }}
        />
      )}
    </Layout>
  );
};

export default ScreenServiceCustomPlan;