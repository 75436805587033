import {
  getServiceCategories,
  addServiceCategory,
  editServiceCategory,
  changeServiceCategoryStatus,
  changeServiceCategoryVisibility,
  deleteServiceCategory,
} from "./category";
import {
  getServiceTypes,
  addServiceType,
  editServiceType,
  changeServiceTypeStatus,
  deleteServiceType,
} from "./types";
import {
  getServiceBanners,
  addServiceBanner,
  editServiceBanner,
  changeServiceBannerVisibility,
  deleteServiceBanner,
} from "./banner";
import {
  getServiceModels,
  addServiceModel,
  editServiceModel,
  changeServiceModelStatus,
  changeServiceModelVisibility,
  deleteServiceModel,
} from "./model";
import {
  getServicePlans,
  addServicePlan,
  editServicePlan,
  changeServicePlanStatus,
  changeServicePlanVisibility,
  deleteServicePlan,
} from "./plan";
import {
  getServices,
  addService,
  editService,
  changeServiceStatus,
  deleteService,
} from "./service";
import { getServicePlansPriceLists } from "./plansPriceList";

/**
 * To get all data's related in service from server
 */
export const getAllServices = (refresh?:boolean) => {
  getServiceCategories(refresh);
  getServiceBanners(refresh);
  getServiceTypes(refresh);
  getServiceModels(refresh);
  getServices(refresh);
  getServicePlans(refresh);
  getServicePlansPriceLists(refresh)
};

export {
  // Category
  getServiceCategories,
  addServiceCategory,
  editServiceCategory,
  changeServiceCategoryStatus,
  changeServiceCategoryVisibility,
  deleteServiceCategory,
  // Types
  getServiceTypes,
  addServiceType,
  editServiceType,
  changeServiceTypeStatus,
  deleteServiceType,
  // Banners
  getServiceBanners,
  addServiceBanner,
  editServiceBanner,
  changeServiceBannerVisibility,
  deleteServiceBanner,
  // Model
  getServiceModels,
  addServiceModel,
  editServiceModel,
  changeServiceModelStatus,
  changeServiceModelVisibility,
  deleteServiceModel,
  // Plan
  getServicePlans,
  addServicePlan,
  editServicePlan,
  changeServicePlanStatus,
  changeServicePlanVisibility,
  deleteServicePlan,
  // 
  getServices,
  addService,
  editService,
  changeServiceStatus,
  deleteService,
};
