import httpClient from "../../core/axios";
import ApiEndpoints from "../../core/apiEndpoints";
import store, { dispatch } from "../../redux/store";
import { setErrorMessage, setSuccessMessage } from "../../redux/notification";
import {
  changeSalesBannerLoading,
  setSalesBannersState,
  addSalesBannerState,
  changeSalesBannerVisibilityState,
  deleteSalesBannerState,
  editSalesBannerState,
  setSalesDeletedBannersState,
  restoreSalesBannerState,
  pDeleteSalesBannerState,
} from "../../redux/sales";
import { ISalesBanner } from "../../types/sales";

/**
 * To get a sales banners from server
 */
export const getSalesBanners = async (refresh?: boolean) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeSalesBannerLoading(true));

    // API Fetching Deleted
    if (store.getState().admins.profile?.role === "MasterAdmin")
      getDeletedSalesBanners(refresh);

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.salesBanner);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(
      setSalesBannersState({
        banners: resp.data.data.filter(
          (banner: ISalesBanner) => banner.type === "SalesBanner"
        ),
        refresh,
      })
    );
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSalesBannerLoading(false));
  }
};

/**
 * To get a deleted banner from server
 */
export const getDeletedSalesBanners = async (refresh?: boolean) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeSalesBannerLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.deletedSalesBanner);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(
      setSalesDeletedBannersState({
        banners: resp.data.data.filter(
          (banner: ISalesBanner) => banner.type === "SalesBanner"
        ),
        refresh,
      })
    );
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSalesBannerLoading(false));
  }
};

/**
 * To add a new sales banner
 */
export const addSalesBanner = async (bannerForm: FormData) => {
  try {
    // Set Loading
    dispatch(changeSalesBannerLoading(true));

    // API Fetching
    const resp = await httpClient("multipart/form-data").post(
      ApiEndpoints.salesBanner,
      bannerForm
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(addSalesBannerState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSalesBannerLoading(false));
  }
};

/**
 * To add a new sales banner
 */
export const editSalesBanner = async (id: string, bannerForm: FormData) => {
  try {
    // Set Loading
    dispatch(changeSalesBannerLoading(true));

    // API Fetching
    const resp = await httpClient("multipart/form-data").patch(
      ApiEndpoints.salesBanner + "/" + id,
      bannerForm
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(editSalesBannerState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSalesBannerLoading(false));
  }
};

/**
 * To change the visibility
 */
export const changeSalesBannerVisibility = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeSalesBannerLoading(true));

    // API Fetching
    const resp = await httpClient().get(
      ApiEndpoints.changeSalesBannerVisibility + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeSalesBannerVisibilityState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSalesBannerLoading(false));
  }
};

/**
 * To delete the banner
 */
export const deleteSalesBanner = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeSalesBannerLoading(true));

    // API Fetching
    const resp = await httpClient().delete(ApiEndpoints.salesBanner + "/" + id);
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(deleteSalesBannerState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSalesBannerLoading(false));
  }
};

/**
 * To restore the sales banner
 */
export const restoreSalesBanner = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeSalesBannerLoading(true));

    // API Fetching
    const resp = await httpClient().delete(
      ApiEndpoints.restoreSalesBanner + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(restoreSalesBannerState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSalesBannerLoading(false));
  }
};

/**
 * To delete the sales banner permanently
 */
export const pDeleteSalesBanner = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeSalesBannerLoading(true));

    // API Fetching
    const resp = await httpClient().delete(ApiEndpoints.deleteSalesBanner + id);
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(pDeleteSalesBannerState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSalesBannerLoading(false));
  }
};
