import React, { useEffect, useMemo } from "react";
import { useAppSelector } from "../../../redux/hooks";
import Table from "../../../components/Table";
import Layout from "../../../components/Layout";
import Loader from "../../../components/Loader";
import serviceBannerTableHeaders from "./serviceTableHeader";
import { useNavigate } from "react-router-dom";
import { KRoutes } from "../../../routers/router";
import { getServiceBanners } from "../../../services/services";
import { autoRefreshDelay } from "../../../core/constants";

const ScreenServiceBanners = () => {
  const { banners, isLoading } = useAppSelector((state) => state.serviceBanners);
  const columns = useMemo(() => serviceBannerTableHeaders, []);
  const data = useMemo(() => banners, [banners]);
  const navigate = useNavigate();
 
  useEffect(() => {
    const refreshInterval = setInterval(
      () => getServiceBanners(true),
      autoRefreshDelay * 1000
    );
    return () => {
      clearInterval(refreshInterval);
    };
  }, []);

  return (
    <Layout title="service-banners">
      {(isLoading && banners.length <= 0) ? (
        <Loader />
      ) : (
        <Table
          columns={columns}
          data={data}
          tableTitle={"Service Banners"}
          buttons={{
            addButton: true,
            addButtonTitle: "Add Banner",
            onClickAddButton: () => {
              navigate(KRoutes.addServiceBanner)
            },
            exportButton: false,
          }}
        />
      )}
    </Layout>
  );
};

export default ScreenServiceBanners;
