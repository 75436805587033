import { Column } from "react-table";
import { SelectColumnFilter } from "../../../components/Table";
import {
  // ButtonsPill,
  DateFromatPill,
} from "../../../components/TableCells";
// import ApiEndpoints from "../../../core/apiEndpoints";
import { IServiceCustomPlan } from "../../../types/services";

const serviceCustomPlanTableHeaders: Column<IServiceCustomPlan>[] = [
  {
    id: "code",
    Header: "Code",
    accessor: "code",
  },
  {
    id: "user",
    Header: "User",
    accessor: (u) => u.user?.name || "No User",
  },
  {
    id: "category",
    Header: "Category",
    accessor: (c) => c.category?.name || "No Category",
    Filter: SelectColumnFilter, // new
    filter: "includes",
  },
  {
    id: "model",
    Header: "Model",
    accessor: (c) => c.model?.name || "No Model",
    Filter: SelectColumnFilter, // new
    filter: "includes",
  },
  {
    id: "type",
    Header: "Type",
    accessor: (c) => c.type?.name || "No Type",
    Filter: SelectColumnFilter, // new
    filter: "includes",
  },
  {
    id: "plan",
    Header: "Base Plan",
    accessor: (c) => c.plan?.name || "No Plan",
    Filter: SelectColumnFilter, // new
    filter: "includes",
  },
  {
    id: "services",
    Header: "Additional Services",
    accessor: (s) => `${s.services.length} Services`,
  },
  {
    id: "price",
    Header: "Price",
    accessor: "price",
  },
  {
    id: "duration",
    Header: "Duration",
    accessor: "duration",
  },
  {
    Header: "CreatedAt",
    accessor: "createdAt",
    Cell: DateFromatPill,
  },
  {
    Header: "UpdatedAt",
    accessor: "updatedAt",
    Cell: DateFromatPill,
  },
  // {
  //   Header: "Action",
  //   accessor: "_id",
  //   Cell: ({ value }) => {
  //     return ButtonsPill({
  //       statusChangeButton: true,
  //       visibilityChangeButton: true,
  //       editButton: true,
  //     });
  //   },
  // },
];

export default serviceCustomPlanTableHeaders;
