import httpClient from "../../core/axios";
import ApiEndpoints from "../../core/apiEndpoints";
import store, { dispatch } from "../../redux/store";
import { setErrorMessage, setSuccessMessage } from "../../redux/notification";
import {
  changeServiceBannerLoading,
  setServiceBannersState,
  setServiceDeletedBannersState,
  addServiceBannerState,
  changeServiceBannerVisibilityState,
  deleteServiceBannerState,
  restoreServiceBannerState,
  pDeleteServiceBannerState,
  editServiceBannerState,
} from "../../redux/services";
import { IServiceBanner } from "../../types/services";

/**
 * To get a service banners from server
 */
export const getServiceBanners = async (refresh?: boolean) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeServiceBannerLoading(true));

    // API Fetching Deleted
    if (store.getState().admins.profile?.role === "MasterAdmin")
      getDeletedServiceBanners(refresh);

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.serviceBanner);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(
      setServiceBannersState({
        banners: resp.data.data.filter(
          (banner: IServiceBanner) => banner.type === "ServiceBanner"
        ),
        refresh,
      })
    );
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceBannerLoading(false));
  }
};

/**
 * To get a deleted banner from server
 */
export const getDeletedServiceBanners = async (refresh?: boolean) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeServiceBannerLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.deletedServiceBanner);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(
      setServiceDeletedBannersState({
        banners: resp.data.data.filter(
          (banner: IServiceBanner) => banner.type === "ServiceBanner"
        ),
        refresh,
      })
    );
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceBannerLoading(false));
  }
};

/**
 * To add a new service banner
 */
export const addServiceBanner = async (bannerForm: FormData) => {
  try {
    // Set Loading
    dispatch(changeServiceBannerLoading(true));

    // API Fetching
    const resp = await httpClient("multipart/form-data").post(
      ApiEndpoints.serviceBanner,
      bannerForm
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(addServiceBannerState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceBannerLoading(false));
  }
};

/**
 * To add a new service banner
 */
export const editServiceBanner = async (id: string, bannerForm: FormData) => {
  try {
    // Set Loading
    dispatch(changeServiceBannerLoading(true));

    // API Fetching
    const resp = await httpClient("multipart/form-data").patch(
      ApiEndpoints.serviceBanner + "/" + id,
      bannerForm
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(editServiceBannerState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceBannerLoading(false));
  }
};

/**
 * To change the visibility
 */
export const changeServiceBannerVisibility = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeServiceBannerLoading(true));

    // API Fetching
    const resp = await httpClient().get(
      ApiEndpoints.changeServiceBannerVisibility + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeServiceBannerVisibilityState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceBannerLoading(false));
  }
};

/**
 * To delete the banner
 */
export const deleteServiceBanner = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeServiceBannerLoading(true));

    // API Fetching
    const resp = await httpClient().delete(
      ApiEndpoints.serviceBanner + "/" + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(deleteServiceBannerState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceBannerLoading(false));
  }
};

/**
 * To restore the service banner
 */
export const restoreServiceBanner = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeServiceBannerLoading(true));

    // API Fetching
    const resp = await httpClient().delete(
      ApiEndpoints.restoreServiceBanner + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(restoreServiceBannerState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceBannerLoading(false));
  }
};

/**
 * To delete the service banner permanently
 */
export const pDeleteServiceBanner = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeServiceBannerLoading(true));

    // API Fetching
    const resp = await httpClient().delete(
      ApiEndpoints.deleteServiceBanner + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(pDeleteServiceBannerState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceBannerLoading(false));
  }
};
