import React, {
  createRef,
  LegacyRef,
  useCallback,
  useEffect,
  useState,
} from "react";
import { TextField, GridView } from "../../components/TextFieldItems";
import Layout from "../../components/Layout";
import { useAppSelector } from "../../redux/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { KRoutes } from "../../routers/router";
import Loader from "../../components/Loader";
import { addHoliday, editHoliday } from "../../services/holidays/holiday";
import Multiselect from "multiselect-react-dropdown";

const ScreenAddHoliday = () => {
  const { holidays, isLoading } = useAppSelector((state) => state.holidays);
  const { locations } = useAppSelector((state) => state.locations);

  const selectedLocations: LegacyRef<Multiselect> = createRef();
  const [holidayDate, setHolidayDate] = useState("");
  const navigate = useNavigate();

  const params = useParams();

  const setEditFields = useCallback(
    (id: string) => {
      const holiday = holidays.find((h) => h._id === id);
      if (holiday) {
        setHolidayDate(new Date(holiday.date).toISOString().slice(0, 10));
        selectedLocations.current?.setState(
          holiday.locations?.map((location) => {
            return {
              id: location._id,
              name: location.name,
            };
          })
        );
      }
    },
    [holidays, selectedLocations]
  );

  useEffect(() => {
    if (params.hid) {
      setEditFields(params.hid);
    }
  }, [params.hid, setEditFields]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    if (!holidayDate) {
      toast("Set holiday date");
      return;
    } else {
      const selectedLocationIds = selectedLocations.current
        ?.getSelectedItems()
        .map((location: any) => location.id);
      const splittedDate = holidayDate.split("-");
      const holidayData = {
        date: `${splittedDate[2]}-${splittedDate[1]}-${splittedDate[0]}`,
        locations: selectedLocationIds,
      };
      const success = params.hid
        ? await editHoliday(params.hid, holidayData)
        : await addHoliday(holidayData);
      if (success) {
        navigate(KRoutes.holidays);
      }
    }
  };
  return (
    <Layout title="holidays">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="p-10 overflow-y-scroll overflow-x-hidden scrollbar-hide">
          <div className="flex justify-between">
            <p className="lg:text-3xl text-2xl font-black dark:text-white ">
              {params.hid ? "Edit" : "Add"} Holiday
            </p>
            <div>
              <button
                className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800`"
                onClick={() => navigate(-1)}
              >
                <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                  Back
                </span>
              </button>
            </div>
          </div>
          <form className="mt-6 " onSubmit={handleSubmit}>
            <GridView className="gap-6 mb-6 lg:grid-cols-2">
              <>
                <TextField
                  label="Date"
                  id="date"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="date"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="date"
                  value={holidayDate}
                  required={true}
                  placeholder="Holiday Date"
                  onChange={(e) => setHolidayDate(e.target.value)}
                />

                <div>
                  <label
                    htmlFor="types"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Locations
                  </label>
                  <Multiselect
                    options={locations.map((location) => {
                      return {
                        id: location._id,
                        name: location.name,
                      };
                    })}
                    id="locations"
                    showArrow={true}
                    placeholder="Search locations"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    ref={selectedLocations}
                    displayValue="name"
                    avoidHighlightFirstOption={true}
                    keepSearchTerm={true}
                  />
                </div>
              </>
            </GridView>
            <div className="flex items-center justify-center -mx-3 my-6">
              <div className="w-full lg:w-1/2 px-3">
                <button
                  type="submit"
                  className="w-full active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-3 bg-primary-color text-white  text-lg font-bold rounded-xl"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </Layout>
  );
};

export default ScreenAddHoliday;
