import httpClient from "../../core/axios";
import ApiEndpoints from "../../core/apiEndpoints";
import store, { dispatch } from "../../redux/store";
import { setErrorMessage, setSuccessMessage } from "../../redux/notification";
import {
  changeCustomerLoading,
  setCustomersState,
  addCustomerState,
  changeCustomerStatusState,
  deleteCustomerState,
  editCustomerState,
  setDeletedCustomerState,
  restoreCustomerState,
  pDeleteCustomerState,
} from "../../redux/customer";

/**
 * To get a  customers from server
 */
export const getCustomers = async (refresh?: boolean) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeCustomerLoading(true));

    // API Fetching Deleted
    if (store.getState().admins.profile?.role === "MasterAdmin")
      getDeletedCustomers(refresh);

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.customer);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(
      setCustomersState({
        customers: resp.data.data,
        refresh,
      })
    );
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeCustomerLoading(false));
  }
};

/**
 * To get a deleted customers from server
 */
export const getDeletedCustomers = async (refresh?: boolean) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeCustomerLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.deletedCustomer);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(
      setDeletedCustomerState({
        customers: resp.data.data,
        refresh,
      })
    );
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeCustomerLoading(false));
  }
};

/**
 * To add a new  customer
 */
export const addCustomer = async (customerForm: FormData) => {
  try {
    // Set Loading
    dispatch(changeCustomerLoading(true));

    // API Fetching
    const resp = await httpClient("multipart/form-data").post(
      ApiEndpoints.customer,
      customerForm
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(addCustomerState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeCustomerLoading(false));
  }
};

/**
 * To add a new  customer
 */
export const editCustomer = async (id: string, customerForm: FormData) => {
  try {
    // Set Loading
    dispatch(changeCustomerLoading(true));

    // API Fetching
    const resp = await httpClient("multipart/form-data").patch(
      ApiEndpoints.customer + "/" + id,
      customerForm
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(editCustomerState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeCustomerLoading(false));
  }
};

/**
 * To change the status
 */
export const changeCustomerStatus = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeCustomerLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.changeCustomerStatus + id);
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeCustomerStatusState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeCustomerLoading(false));
  }
};

/**
 * To delete the customer
 */
export const deleteCustomer = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeCustomerLoading(true));

    // API Fetching
    const resp = await httpClient().delete(ApiEndpoints.customer + "/" + id);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(deleteCustomerState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeCustomerLoading(false));
  }
};

/**
 * To restore the customer
 */
export const restoreCustomer = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeCustomerLoading(true));

    // API Fetching
    const resp = await httpClient().delete(ApiEndpoints.restoreCustomer + id);
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(restoreCustomerState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeCustomerLoading(false));
  }
};

/**
 * To delete the customer permanently
 */
export const pDeleteCustomer = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeCustomerLoading(true));

    // API Fetching
    const resp = await httpClient().delete(ApiEndpoints.deleteCustomer + id);
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(pDeleteCustomerState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeCustomerLoading(false));
  }
};
