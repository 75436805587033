import { Column } from "react-table";
import { SelectColumnFilter } from "../../components/Table";
import {
  ButtonsPill,
  DateFromatPill,
  // ImageCell,
  StatusPill,
} from "../../components/TableCells";
// import ApiEndpoints from "../../core/apiEndpoints";
import { ICustomer } from "../../types/customer";

const customersTableHeaders: Column<ICustomer>[] = [
  {
    id: "name",
    Header: "Name",
    accessor: "name",
  },
  //   {
  //     id: "image",
  //     Header: "Image",
  //     accessor: "_id",
  //     Cell: ({ value }) => ImageCell(`${ApiEndpoints.categoryImage}/${value}`),
  //   },
  {
    id: "location",
    Header: "Location",
    accessor: (l) => l.location.name ?? "No Location",
    Filter: SelectColumnFilter, // new
    filter: "includes",
  },
  {
    id: "address",
    Header: "Address",
    accessor: "address",
  },
  {
    id: "phone",
    Header: "Phone",
    accessor: "phone",
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: StatusPill,
    Filter: SelectColumnFilter, // new
    filter: "includes",
  },
  {
    Header: "CreatedAt",
    accessor: "createdAt",
    Cell: DateFromatPill,
  },
  {
    Header: "UpdatedAt",
    accessor: "updatedAt",
    Cell: DateFromatPill,
  },
  {
    Header: "Action",
    accessor: "_id",
    Cell: ({ value }) => {
      return ButtonsPill({
        statusChangeButton: true,
        visibilityChangeButton: false,
        editButton: false,
      });
    },
  },
];

export default customersTableHeaders;
