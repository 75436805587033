import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { APP_NAME } from "../core/constants";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setActiveMenuId, setOpenSidebar } from "../redux/settings";
import { KRoutes } from "../routers/router";
import Icons from "../utils/icons";

interface ISidebarTiles {
  title: "NO TITLE" | string;
  subTiles: {
    id: string;
    title: string;
    icon: string;
    path: string;
  }[];
}

interface ISidebarProps {
  title: string;
}

const Sidebar = ({ title }: ISidebarProps) => {
  const { openSidebar, activeMenuId } = useAppSelector(
    (state) => state.settings
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const sidebarTileData = useMemo<ISidebarTiles[]>(
    () => [
      {
        title: "NO TITLE",
        subTiles: [
          {
            id: "dashboard",
            title: "Dashboard",
            icon: Icons.dashboard,
            path: KRoutes.dashboard,
          },
        ],
      },
      {
        title: "Services",
        subTiles: [
          {
            id: "service-categorys",
            title: "Service Categorys",
            icon: Icons.service_categories,
            path: KRoutes.serviceCategories,
          },
          {
            id: "service-types",
            title: "Service Types",
            icon: Icons.service_types,
            path: KRoutes.serviceTypes,
          },
          {
            id: "service-banners",
            title: "Service Banners",
            icon: Icons.service_types,
            path: KRoutes.serviceBanners,
          },
          {
            id: "service-models",
            title: "Service Models",
            icon: Icons.service_models,
            path: KRoutes.serviceModels,
          },
          {
            id: "services",
            title: "Services",
            icon: Icons.services,
            path: KRoutes.services,
          },
          {
            id: "service-plans",
            title: "Service Plans",
            icon: Icons.service_plans,
            path: KRoutes.servicePlans,
          },
          {
            id: "service-custom-plans",
            title: "Service Custom Plans",
            icon: Icons.service_plans,
            path: KRoutes.serviceCustomPlans,
          },
          {
            id: "service-plans-price-list",
            title: "Service Plans Price List",
            icon: Icons.service_plans,
            path: KRoutes.servicePlansPriceList,
          },
          {
            id: "service-orders",
            title: "Service Orders",
            icon: Icons.service_orders,
            path: KRoutes.serviceOrders,
          },
        ],
      },
      {
        title: "Sales",
        subTiles: [
          {
            id: "sales-categorys",
            title: "Sales Categorys",
            icon: Icons.sales_categories,
            path: KRoutes.salesCategories,
          },
          {
            id: "sales-banners",
            title: "Sales Banners",
            icon: Icons.sales_categories,
            path: KRoutes.salesBanners,
          },
          {
            id: "sales-items",
            title: "Sales Items",
            icon: Icons.sales_items,
            path: KRoutes.salesItems,
          },
          {
            id: "sales-orders",
            title: "Sales Orders",
            icon: Icons.sales_orders,
            path: KRoutes.salesOrders,
          },
        ],
      },
      {
        title: "Adminstrations",
        subTiles: [
          {
            id: "admins",
            title: "Admins",
            icon: Icons.admins,
            path: KRoutes.admins,
          },
          {
            id: "agents",
            title: "Agents",
            icon: Icons.users,
            path: KRoutes.agents,
          },
          {
            id: "customers",
            title: "Customers",
            icon: Icons.users,
            path: KRoutes.customers,
          },
        ],
      },
      {
        title: "NO TITLE",
        subTiles: [
          {
            id: "holidays",
            title: "Holidays",
            icon: Icons.settings,
            path: KRoutes.holidays,
          },
          {
            id: "locations",
            title: "Locations",
            icon: Icons.settings,
            path: KRoutes.locations,
          },
          {
            id: "settings",
            title: "Settings",
            icon: Icons.settings,
            path: KRoutes.settings,
          },
        ],
      },
    ],
    []
  );
  useEffect(() => {
    dispatch(setActiveMenuId(title));
  }, [dispatch, title]);

  return (
    <div
      className={`h-screen bg-primary-color overflow-y-scroll overflow-x-hidden scrollbar-hide fixed z-10 top-0 left-0 ${
        openSidebar ? "w-72" : "w-20  hidden lg:block"
      }`}
    >
      <img
        className={`hidden cursor-pointer fixed  top-12 w-7 border-2 border-primary-color rounded-full z-50 ${
          openSidebar ? "left-64" : "rotate-180 left-16"
        }`}
        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQfMRNlXpLne8jaIc3q3ZOkpNz7TrBfK6zvw&usqp=CAU"
        alt={`${openSidebar ? "open" : "close"}`}
        onClick={() => dispatch(setOpenSidebar(!openSidebar))}
      />
      <div
        className={`flex ${
          openSidebar ? "gap-x-4 w-72" : "w-20"
        } items-center justify-start h-20 fixed top-0 left-0 bg-primary-color`}
      >
        <img
          className={`cursor-pointer duration-500  ${
            openSidebar ? "rotate-[360deg] ml-6" : "ml-5"
          }`}
          src="/logo.png"
          alt="Logo"
          width={openSidebar ? 50 : 40}
        />
        <h1
          className={`text-white origin-left font-medium text-xl duration-300 ml-2  ${
            !openSidebar && "scale-0"
          }`}
        >
          {APP_NAME.toUpperCase()}
        </h1>
      </div>
      <div className="p-5 pt-2 mt-20">
        <ul>
          {sidebarTileData.map(({ title, subTiles }, i) => (
            <>
              <li
                className={`text-white origin-left font-medium text-xl duration-300  mt-4 ${
                  (!openSidebar || title === "NO TITLE") && "hidden"
                }`}
                key={i.toString()}
              >
                {title}
              </li>
              {subTiles.map(({ id, title, icon, path }) => (
                <li
                  key={id}
                  className={`${openSidebar ? "my-1.5" : "my-4"} ${
                    id === activeMenuId && "bg-light-white"
                  } first:mt-0 text-gray-300 text-sm flex items-center gap-x-4 cursor-pointer p-2 hover:bg-light-white rounded-md  `}
                  onClick={() => {
                    dispatch(setActiveMenuId(id));
                    navigate(path);
                  }}
                >
                  {" "}
                  <img src={icon} alt="Icon" width={30} />{" "}
                  <span
                    className={`${
                      !openSidebar && "hidden"
                    } origin-left duration-200`}
                  >
                    {title}
                  </span>
                </li>
              ))}
            </>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
