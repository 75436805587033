import { Column } from "react-table";
import { SelectColumnFilter } from "../../components/Table";
import {
  ButtonsPill,
  DateFromatPill,
  TextPill,
} from "../../components/TableCells";
import store from "../../redux/store";
import { KRoutes } from "../../routers/router";
import { deleteHoliday } from "../../services/holidays/holiday";
import { IHoliday } from "../../types/default";

const weekDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const holidaysTableHeaders: Column<IHoliday>[] = [
  {
    id: "day",
    Header: "Day",
    accessor: (d) => {
      const date = new Date(d.date);
      return weekDays[date.getDay()];
    },
    Filter: SelectColumnFilter, // new
    filter: "includes",
  },
  {
    id: "date",
    Header: "Date",
    accessor: (d) => {
      const date = new Date(d.date);
      return date.getDate();
    },
    Filter: SelectColumnFilter, // new
    filter: "includes",
  },
  {
    id: "month",
    Header: "Month",
    accessor: (d) => {
      const date = new Date(d.date);
      return months[date.getMonth()];
    },
    Filter: SelectColumnFilter, // new
    filter: "includes",
  },
  {
    id: "year",
    Header: "Year",
    accessor: (d) => {
      const date = new Date(d.date);
      return date.getFullYear();
    },
    Filter: SelectColumnFilter, // new
    filter: "includes",
  },
  {
    id: "HolidayAt",
    Header: "Year",
    accessor: "date",
    Cell: DateFromatPill,
  },
  {
    id: "locations",
    Header: "Locations",
    accessor: "locations",
    Cell: ({ value }) => {
      const locations = value.map((l) => l.name).join(", ");
      return TextPill({
        value: locations || "No Locations",
        className: "text-sm text-gray-500",
      });
    },
  },
  {
    Header: "CreatedAt",
    accessor: "createdAt",
    Cell: DateFromatPill,
  },
  {
    Header: "UpdatedAt",
    accessor: "updatedAt",
    Cell: DateFromatPill,
  },
  {
    Header: "Action",
    accessor: "_id",
    Cell: ({ value }) => {
      return ButtonsPill({
        statusChangeButton: false,
        visibilityChangeButton: false,
        editButton: true,
         deleteButton: ["MasterAdmin", "Developer"].includes(store.getState().admins.profile?.role || "")
          ? true
          : false,
        editPath: KRoutes.editHoliday + "/" + value,
        onClickDelete: () => deleteHoliday(value),
      });
    },
  },
];

export default holidaysTableHeaders;
