import React from "react";
import { useAppSelector } from "../redux/hooks";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";

interface ILayoutProps {
  children: JSX.Element;
  title: string;
}

const Layout = ({ children, title }: ILayoutProps) => {
  const { openSidebar } = useAppSelector((state) => state.settings);
  return (
    <>
      <Sidebar title={title} />
      <div
        className={`flex min-h-screen ${
          openSidebar ? "ml-72" : "lg:ml-20"
        }`}
      >
        <Navbar />
        <div className="mt-20 w-full">{children}</div>
      </div>
    </>
  );
};

export default Layout;
