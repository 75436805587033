import { Column } from "react-table";
import { SelectColumnFilter } from "../../../components/Table";
import {
  ButtonsPill,
  DateFromatPill,
  ImageCell,
  StatusPill,
  TextPill,
  VisibilityPill,
} from "../../../components/TableCells";
import ApiEndpoints from "../../../core/apiEndpoints";
import store from "../../../redux/store";
import { KRoutes } from "../../../routers/router";
import {
  changeServiceModelStatus,
  changeServiceModelVisibility,
  deleteServiceModel,
} from "../../../services/services";
import { IServiceModel } from "../../../types/services";

const serviceModelTableHeaders: Column<IServiceModel>[] = [
  {
    id: "name",
    Header: "Name",
    accessor: "name",
  },
  {
    id: "image",
    Header: "Image",
    accessor: "_id",
    Cell: ({ value }) => ImageCell(ApiEndpoints.serviceModelImage + value),
  },
  {
    id: "category",
    Header: "Category",
    accessor: (v) => v.category.name,
    Filter: SelectColumnFilter,
    filter: "includes",
  },
  {
    Header: "Types",
    accessor: "types",
    Cell: ({ value }) => {
      const types = value.map((t) => t.name).join(", ");
      return TextPill({
        value: types || "No Types",
        className: "text-sm text-gray-500",
      });
    },
  },
  {
    id: "price",
    Header: "Additional Price (%)",
    accessor: "additionalPricePercent",
    Cell: ({ value }) =>
      TextPill({
        value: value + " %",
        className: "truncate overflow-hidden text-gray-500",
      }),
  },
  {
    id: "duration",
    Header: "Additional Duration (min)",
    accessor: "additionalDuration",
    Cell: ({ value }) =>
      TextPill({
        value: value + " min",
        className: "truncate overflow-hidden text-gray-500",
      }),
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: StatusPill,
    Filter: SelectColumnFilter, // new
    filter: "includes",
  },
  {
    Header: "Visibility",
    accessor: "visibility",
    Cell: VisibilityPill,
    Filter: SelectColumnFilter, // new
    filter: "includes",
  },
  {
    Header: "CreatedAt",
    accessor: "createdAt",
    Cell: DateFromatPill,
  },
  {
    Header: "UpdatedAt",
    accessor: "updatedAt",
    Cell: DateFromatPill,
  },
  {
    Header: "Action",
    accessor: "_id",
    Cell: ({ value }) => {
      return ButtonsPill({
        statusChangeButton: true,
        visibilityChangeButton: true,
        editButton: true,
        deleteButton: ["MasterAdmin", "Developer"].includes(
          store.getState().admins.profile?.role || ""
        )
          ? true
          : false,
        editPath: KRoutes.editServiceModel + "/" + value,
        onClickDelete: () => deleteServiceModel(value),
        onClickStatusChange: () => changeServiceModelStatus(value),
        onClickVisibilityChange: () => changeServiceModelVisibility(value),
      });
    },
  },
];

export default serviceModelTableHeaders;
