import httpClient from "../../core/axios";
import ApiEndpoints from "../../core/apiEndpoints";
import store, { dispatch } from "../../redux/store";
import { setErrorMessage, setSuccessMessage } from "../../redux/notification";
import {
  changeSalesCategoryLoading,
  setSalesCategoriesState,
  addSalesCategoryState,
  changeSalesCategoryStatusState,
  changeSalesCategoryVisibilityState,
  deleteSalesCategoryState,
  editSalesCategoryState,
  setSalesDeletedCategoriesState,
  restoreSalesCategoryState,
  pDeleteSalesCategoryState,
} from "../../redux/sales";
import { ISalesCategory } from "../../types/sales";

/**
 * To get a sales categories from server
 */
export const getSalesCategories = async (refresh?: boolean) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeSalesCategoryLoading(true));

    // API Fetching Deleted
    if (store.getState().admins.profile?.role === "MasterAdmin")
      getSalesDeletedCategories(refresh);

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.salesCategory);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(
      setSalesCategoriesState({
        categories: resp.data.data.filter(
          (category: ISalesCategory) => category.type === "SalesCategory"
        ),
        refresh,
      })
    );
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSalesCategoryLoading(false));
  }
};

/**
 * To get a sales deleted categories from server
 */
export const getSalesDeletedCategories = async (refresh?: boolean) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeSalesCategoryLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.deletedSalesBanner);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(
      setSalesDeletedCategoriesState({
        categories: resp.data.data.filter(
          (category: ISalesCategory) => category.type === "SalesCategory"
        ),
        refresh,
      })
    );
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSalesCategoryLoading(false));
  }
};

/**
 * To add a new sales category
 */
export const addSalesCategory = async (categoryForm: FormData) => {
  try {
    // Set Loading
    dispatch(changeSalesCategoryLoading(true));

    // API Fetching
    const resp = await httpClient("multipart/form-data").post(
      ApiEndpoints.salesCategory,
      categoryForm
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(addSalesCategoryState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSalesCategoryLoading(false));
  }
};

/**
 * To add a new sales category
 */
export const editSalesCategory = async (id: string, categoryForm: FormData) => {
  try {
    // Set Loading
    dispatch(changeSalesCategoryLoading(true));

    // API Fetching
    const resp = await httpClient("multipart/form-data").patch(
      ApiEndpoints.salesCategory + "/" + id,
      categoryForm
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(editSalesCategoryState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSalesCategoryLoading(false));
  }
};

/**
 * To change the status
 */
export const changeSalesCategoryStatus = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeSalesCategoryLoading(true));

    // API Fetching
    const resp = await httpClient().get(
      ApiEndpoints.changeSalesCategoryStatus + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeSalesCategoryStatusState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSalesCategoryLoading(false));
  }
};

/**
 * To change the visibility
 */
export const changeSalesCategoryVisibility = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeSalesCategoryLoading(true));

    // API Fetching
    const resp = await httpClient().get(
      ApiEndpoints.changeSalesCategoryVisibility + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeSalesCategoryVisibilityState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSalesCategoryLoading(false));
  }
};

/**
 * To delete the category
 */
export const deleteSalesCategory = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeSalesCategoryLoading(true));

    // API Fetching
    const resp = await httpClient().delete(
      ApiEndpoints.salesCategory + "/" + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(deleteSalesCategoryState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSalesCategoryLoading(false));
  }
};
/**
 * To restore the category
 */
export const restoreSalesCategory = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeSalesCategoryLoading(true));

    // API Fetching
    const resp = await httpClient().delete(
      ApiEndpoints.restoreSalesCategory + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(restoreSalesCategoryState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSalesCategoryLoading(false));
  }
};

/**
 * To delete the category permanently
 */
export const pDeleteSalesCategory = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeSalesCategoryLoading(true));

    // API Fetching
    const resp = await httpClient().delete(
      ApiEndpoints.deleteSalesCategory + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(pDeleteSalesCategoryState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSalesCategoryLoading(false));
  }
};
