import { Column } from "react-table";
import { SelectColumnFilter } from "../../../components/Table";
import {
  ButtonsPill,
  DateFromatPill,
  ImageCell,
  StatusPill,
  TextPill,
  VisibilityPill,
} from "../../../components/TableCells";
import ApiEndpoints from "../../../core/apiEndpoints";
import store from "../../../redux/store";
import { KRoutes } from "../../../routers/router";
import {
  changeServicePlanStatus,
  changeServicePlanVisibility,
  deleteServicePlan,
} from "../../../services/services";
import { IServicePlan } from "../../../types/services";

const servicePlanTableHeaders: Column<IServicePlan>[] = [
  {
    id: "name",
    Header: "Name",
    accessor: "name",
  },
  {
    id: "category",
    Header: "Category",
    accessor: (c) => c.category?.name || "No Category",
    Filter: SelectColumnFilter, // new
    filter: "includes",
  },
  {
    id: "services",
    Header: "Services",
    // accessor: (s) => `${s.services.length} Services`,
    accessor: "services",
    Cell: ({ value }) => {
      const services = value.map((s) => s.name).join(", ");
      return TextPill({
        value: services || "No Services",
        className: "text-sm text-gray-500",
      });
    },
  },
  {
    id: "image",
    Header: "Image",
    accessor: "_id",
    Cell: ({ value }) => ImageCell(ApiEndpoints.servicePlanImage + value),
  },
  {
    id: "redeemPoint",
    Header: "Redeem Point",
    accessor: "redeemPoints",
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: StatusPill,
    Filter: SelectColumnFilter, // new
    filter: "includes",
  },
  {
    Header: "Visibility",
    accessor: "visibility",
    Cell: VisibilityPill,
    Filter: SelectColumnFilter, // new
    filter: "includes",
  },
  {
    Header: "CreatedAt",
    accessor: "createdAt",
    Cell: DateFromatPill,
  },
  {
    Header: "UpdatedAt",
    accessor: "updatedAt",
    Cell: DateFromatPill,
  },
  {
    Header: "Action",
    accessor: "_id",
    Cell: ({ value }) => {
      return ButtonsPill({
        statusChangeButton: true,
        visibilityChangeButton: true,
        editButton: true,
        deleteButton: ["MasterAdmin", "Developer"].includes(
          store.getState().admins.profile?.role || ""
        )
          ? true
          : false,
        editPath: KRoutes.editServicePlan + "/" + value,
        onClickDelete: () => deleteServicePlan(value),
        onClickStatusChange: () => changeServicePlanStatus(value),
        onClickVisibilityChange: () => changeServicePlanVisibility(value),
      });
    },
  },
];

export default servicePlanTableHeaders;
