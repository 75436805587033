import React, { useCallback, useEffect, useState } from "react";
import {
  GridView,
  SelectInput,
  FileUpload,
} from "../../../components/TextFieldItems";
import Layout from "../../../components/Layout";
import { useAppSelector } from "../../../redux/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { KRoutes } from "../../../routers/router";
import {
  addServiceBanner,
  editServiceBanner,
} from "../../../services/services";
import { ISelectedImage } from "../../../types/default";
import ApiEndpoints from "../../../core/apiEndpoints";
import Loader from "../../../components/Loader";

const ScreenAddServiceBanner = () => {
  const { models } = useAppSelector((state) => state.serviceModels);
  const { categories } = useAppSelector((state) => state.serviceCategories);
  const { banners, isLoading } = useAppSelector(
    (state) => state.serviceBanners
  );
  const [category, setCategory] = useState("");
  const [model, setModel] = useState("");
  const [selectedFile, setSelectedFile] = useState<ISelectedImage>({
    file: null,
    URL: null,
  });
  const [visibility, setVisibility] = useState("Show");
  const [type, setType] = useState("Service Banner");
  const navigate = useNavigate();
  const params = useParams();

  const setEditFields = useCallback((id: string) => {
    const banner = banners.find((b) => b._id === id);
    if (banner) {
      setSelectedFile((prevState) => ({
        ...prevState,
        URL: ApiEndpoints.serviceBannerImage + banner._id,
      }));
      setCategory(banner.category._id);
      setVisibility(banner.visibility);
      setType(
        banner.type === "ServiceBanner" ? "Service Banner" : "Model Banner"
      );
      if (banner.type === "ModelBanner" && banner.model) {
        setModel(banner.model._id);
      }
    }
}, [banners]);

  useEffect(() => {
    if (params.bid) {
      setEditFields(params.bid);
    }
  }, [params.bid, setEditFields]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    if (!category || (!params.bid && !selectedFile.file) || !type) {
      toast("Fill category,type, and image");
      return;
    } else {
      const bannerForm = new FormData();
      bannerForm.append("category", category);
      bannerForm.append(
        "type",
        type === "Service Banner" ? "ServiceBanner" : "ModelBanner"
      );
      bannerForm.append("visibility", visibility);
      if (selectedFile.file) {
        bannerForm.append("image", selectedFile.file);
      }

      const success = params.bid
        ? await editServiceBanner(params.bid, bannerForm)
        : await addServiceBanner(bannerForm);
      if (success) {
        navigate(KRoutes.serviceBanners);
      }
    }
  };
  return (
    <Layout title="service-banners">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="p-10 overflow-y-scroll overflow-x-hidden scrollbar-hide">
          <div className="flex justify-between">
            <p className="lg:text-3xl text-2xl font-black dark:text-white ">
            {params.bid ? "Edit" : "Add"} Banner
            </p>
            <div>
              <button
                className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800`"
                onClick={() => navigate(-1)}
              >
                <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                  Back
                </span>
              </button>
            </div>
          </div>
          <form className="mt-6 " onSubmit={handleSubmit}>
            <GridView className="gap-6 mb-6 lg:grid-cols-2">
              <>
                <SelectInput
                  data={categories.map((category) => {
                    return {
                      id: category._id,
                      title: category.name,
                    };
                  })}
                  title="Category"
                  value={category}
                  disabled={params.bid ? true : false}
                  onChange={(e) => setCategory(e.target.value)}
                />
                <SelectInput
                  data={[
                    {
                      id: "Show",
                      title: "Show",
                    },
                    {
                      id: "Hide",
                      title: "Hide",
                    },
                  ]}
                  title="Visibility"
                  value={visibility}
                  onChange={(e) => setVisibility(e.target.value)}
                />
                <SelectInput
                  data={[
                    {
                      id: "Service Banner",
                      title: "Service Banner",
                    },
                    {
                      id: "Model Banner",
                      title: "Model Banner",
                    },
                  ]}
                  title="Type"
                  value={type}
                  disabled={params.bid ? true : false}
                  onChange={(e) => setType(e.target.value)}
                />
                {type === "Model Banner" && (
                  <SelectInput
                    data={models.map((model) => {
                      return {
                        id: model._id,
                        title: model.name,
                      };
                    })}
                    title="Model"
                    value={model}
                    onChange={(e) => setModel(e.target.value)}
                  />
                )}
                <FileUpload
                  title="Image"
                  desc=" PNG, JPG  (MAX. 800x400px)."
                  accept="image/*"
                  maxSize={1}
                  file={selectedFile.file}
                  url={selectedFile.URL}
                  onFileSelect={(file) => {
                    setSelectedFile({
                      file: file,
                      URL: URL.createObjectURL(file),
                    });
                  }}
                  removeSelectedFile={() => {
                    setSelectedFile({
                      file: null,
                      URL: "",
                    });
                  }}
                />
              </>
            </GridView>
            <div className="flex items-center justify-center -mx-3 my-6">
              <div className="w-full lg:w-1/2 px-3">
                <button
                  type="submit"
                  className="w-full active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-3 bg-primary-color text-white  text-lg font-bold rounded-xl"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </Layout>
  );
};

export default ScreenAddServiceBanner;
