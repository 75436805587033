import React, { useCallback, useEffect, useState } from "react";
import "./App.css";
import Router from "./routers/router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { resetAllMessage } from "./redux/notification";
import { getAdminProfile, getAdmins } from "./services/admins/admin";
import { getCustomers } from "./services/customers/customers";
import { getAgents } from "./services/agents/agents";
import { getAllServices } from "./services/services";
import { getAllSales } from "./services/sales";
import { getHolidays } from "./services/holidays/holiday";
import { getLocations } from "./services/locations/location";
import { onMessageListener, requestForToken } from "./firebase";

const App = () => {
  const { hasError, hasNotification, hasSuccess, hasWarning, message } =
    useAppSelector((state) => state.notification);
  const { isLoggedIn } = useAppSelector((state) => state.admins);

  const dispatch = useAppDispatch();
  const [isFetched, setFetched] = useState(false);

  onMessageListener();

  useEffect(() => {
    if (
      (hasError || hasNotification || hasSuccess || hasWarning) &&
      message.length > 0
    ) {
      toast(message, {
        className: "m-2",
      });
      dispatch(resetAllMessage());
    }
  }, [hasError, hasNotification, hasSuccess, hasWarning, message, dispatch]);

  // Fetch all data from backend on startup if login
  const fetchAllDatas = useCallback(() => {
    getAllServices();
    getAllSales();
    getCustomers();
    getAgents();
    getAdminProfile();
    getAdmins();
    getAgents();
    getCustomers();
    getHolidays();
    getLocations();
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      if (!isFetched) {
        requestForToken();
        fetchAllDatas();
        setFetched(true);
      }
    }
  }, [isLoggedIn, fetchAllDatas, isFetched]);

  return (
    <div>
      <ToastContainer />
      <Router />
    </div>
  );
};

export default App;
