import { Column } from "react-table";
import { SelectColumnFilter } from "../../components/Table";
import {
  ButtonsPill,
  DateFromatPill,
  //   ImageCell,
  StatusPill,
} from "../../components/TableCells";
import store from "../../redux/store";
import { KRoutes } from "../../routers/router";
import { changeAdminStatus, deleteAdmin } from "../../services/admins/admin";
// import ApiEndpoints from "../../core/apiEndpoints";
import { IAdmin } from "../../types/admin";

const masterAdminTableHeaders: Column<IAdmin>[] = [
  {
    id: "branchCode",
    Header: "Branch Code",
    accessor: "code",
  },
  {
    id: "blocation",
    Header: "Location",
    accessor: (b) => b.branchLocation.name ?? "No Location",
  },
  {
    id: "bdistrict",
    Header: "District",
    accessor: "branchDistrict",
  },
  {
    id: "mname",
    Header: "Manager Name",
    accessor: "branchManagerName",
  },
  {
    id: "maddress",
    Header: "Manager Address",
    accessor: "branchAddress",
  },
  {
    id: "role",
    Header: "Role",
    accessor: "role",
  },
  {
    id: "owner",
    Header: "Owner",
    accessor: "branchOwnerName",
  },
  {
    id: "gender",
    Header: "Gender",
    accessor: "gender",
  },
  {
    id: "dob",
    Header: "DOB",
    accessor: "DOB",
    Cell: DateFromatPill,
  },
  {
    id: "address",
    Header: "Address",
    accessor: "address",
  },
  {
    id: "mainPhone",
    Header: "Contact No (Main)",
    accessor: "mainPhone",
  },
  {
    id: "secoPhone",
    Header: "Contact No (Secondary)",
    accessor: "secondaryPhone",
  },
  {
    id: "Email",
    Header: "Email",
    accessor: "emailId",
  },
  {
    id: "aadhaarId",
    Header: "Aadhaar Id",
    accessor: "aadhaarId",
  },
  {
    id: "cname",
    Header: "Contact Name",
    accessor: "contactName",
  },
  {
    id: "noOfMachines",
    Header: "No. of Machines",
    accessor: "noOfMachines",
  },
  {
    id: "openTime",
    Header: "Open Time",
    accessor: "branchOpenTime",
  },
  {
    id: "closeTime",
    Header: "Close Time",
    accessor: "branchCloseTime",
  },
  {
    id: "userName",
    Header: "User Name",
    accessor: "username",
  },
  //   {
  //     id: "image",
  //     Header: "Image",
  //     accessor: "_id",
  //     Cell: ({ value }) => ImageCell(`${ApiEndpoints.adminImage}/${value}`),
  //   },
  {
    Header: "Status",
    accessor: "status",
    Cell: StatusPill,
    Filter: SelectColumnFilter, // new
    filter: "includes",
  },
  {
    Header: "CreatedAt",
    accessor: "createdAt",
    Cell: DateFromatPill,
  },
  {
    Header: "UpdatedAt",
    accessor: "updatedAt",
    Cell: DateFromatPill,
  },
  {
    Header: "Action",
    accessor: "_id",
    Cell: ({ value }) => {
      return ButtonsPill({
        statusChangeButton: true,
        visibilityChangeButton: false,
        editButton: true,
        deleteButton: ["MasterAdmin", "Developer"].includes(store.getState().admins.profile?.role || "")
        ? true
        : false,
        editPath: KRoutes.editAdmin + "/" + value,
        onClickStatusChange: () => changeAdminStatus(value),
        onClickDelete: () => deleteAdmin(value),
      });
    },
  },
];

const adminTableHeaders: Column<IAdmin>[] = [
  {
    id: "branchCode",
    Header: "Branch Code",
    accessor: "code",
  },
  {
    id: "blocation",
    Header: "Location",
    accessor: (b) => b.branchLocation.name ?? "No Location",
  },
  {
    id: "address",
    Header: "Address",
    accessor: "address",
  },
  {
    id: "mainPhone",
    Header: "Contact No",
    accessor: "mainPhone",
  },
  {
    id: "Email",
    Header: "Email",
    accessor: "emailId",
  },
  {
    id: "mname",
    Header: "Manager Name",
    accessor: "branchManagerName",
  },
  {
    id: "noOfMachines",
    Header: "No. of Machines",
    accessor: "noOfMachines",
  },
  {
    id: "openTime",
    Header: "Open Time",
    accessor: "branchOpenTime",
  },
  {
    id: "closeTime",
    Header: "Close Time",
    accessor: "branchCloseTime",
  },
];

export { adminTableHeaders, masterAdminTableHeaders };
