import { Column } from "react-table";
import { SelectColumnFilter } from "../../../components/Table";
import {
  ButtonsPill,
  DateFromatPill,
  ImageCell,
  VisibilityPill,
} from "../../../components/TableCells";
import ApiEndpoints from "../../../core/apiEndpoints";
import store from "../../../redux/store";
import { KRoutes } from "../../../routers/router";
import {
  changeServiceBannerVisibility,
  deleteServiceBanner,
} from "../../../services/services";
import { IServiceBanner } from "../../../types/services";

const serviceBannerTableHeaders: Column<IServiceBanner>[] = [
  {
    id: "image",
    Header: "Image",
    accessor: "_id",
    Cell: ({ value }) => ImageCell(ApiEndpoints.serviceBannerImage + value),
  },
  {
    Header: "Category",
    accessor: (c) => c.category?.name ?? "No Category",
    Filter: SelectColumnFilter, // new
    filter: "includes",
  },
  {
    Header: "Type",
    accessor: (t) =>
      t.type === "ServiceBanner" ? "Service Banner" : "Model Banner",
    Filter: SelectColumnFilter, // new
    filter: "includes",
  },
  {
    Header: "Model",
    accessor: (t) => t.model?.name ?? "No Model",
    Filter: SelectColumnFilter, // new
    filter: "includes",
  },
  {
    Header: "Visibility",
    accessor: "visibility",
    Cell: VisibilityPill,
    Filter: SelectColumnFilter, // new
    filter: "includes",
  },
  {
    Header: "CreatedAt",
    accessor: "createdAt",
    Cell: DateFromatPill,
  },
  {
    Header: "UpdatedAt",
    accessor: "updatedAt",
    Cell: DateFromatPill,
  },
  {
    Header: "Action",
    accessor: "_id",
    Cell: ({ value }) => {
      return ButtonsPill({
        statusChangeButton: false,
        visibilityChangeButton: true,
        editButton: true,
        deleteButton: ["MasterAdmin", "Developer"].includes(store.getState().admins.profile?.role || "")
        ? true
        : false,
        onClickVisibilityChange: () => changeServiceBannerVisibility(value),
        onClickDelete: () => deleteServiceBanner(value),
        editPath: KRoutes.editServiceBanner + "/" + value,
      });
    },
  },
];

export default serviceBannerTableHeaders;
