import React, { useEffect, useMemo } from "react";
import { useAppSelector } from "../../../redux/hooks";
import Table from "../../../components/Table";

import Layout from "../../../components/Layout";
import Loader from "../../../components/Loader";
import serviceModelTableHeaders from "./serviceModelTableHeaders";
import { KRoutes } from "../../../routers/router";
import { useNavigate } from "react-router-dom";
import { getServiceModels } from "../../../services/services";
import { autoRefreshDelay } from "../../../core/constants";

const ScreenServiceModels = () => {
  const { models, isLoading } = useAppSelector((state) => state.serviceModels);
  const columns = useMemo(() => serviceModelTableHeaders, []);
  const data = useMemo(() => models, [models]);
  const navigate = useNavigate();
  
  useEffect(() => {
    const refreshInterval = setInterval(
      () => getServiceModels(true),
      autoRefreshDelay * 1000
    );
    return () => {
      clearInterval(refreshInterval);
    };
  }, []);

  return (
    <Layout title="service-models">
      {isLoading && models.length <= 0 ? (
        <Loader />
      ) : (
        <Table
          columns={columns}
          data={data}
          tableTitle={"Service Models"}
          buttons={{
            addButton: true,
            addButtonTitle: "Add Model",
            onClickAddButton: () => {
              navigate(KRoutes.addServiceModel);
            },
            exportButton: false,
          }}
        />
      )}
    </Layout>
  );
};

export default ScreenServiceModels;
