import React, { useEffect, useMemo } from "react";
import { useAppSelector } from "../../../redux/hooks";
import Table from "../../../components/Table";
import Layout from "../../../components/Layout";
import Loader from "../../../components/Loader";
import servicePlansPriceListTableHeaders from "./servicePlansPriceListTableHeader";
import { getServicePlansPriceLists } from "../../../services/services/plansPriceList";
import { autoRefreshDelay } from "../../../core/constants";
import { toast } from "react-toastify";

const ScreenServicePlansPriceList = () => {
  const { allPlansPriceList, isLoading } = useAppSelector(
    (state) => state.servicePlansPriceList
  );
  const columns = useMemo(() => servicePlansPriceListTableHeaders, []);
  const data = useMemo(() => allPlansPriceList, [allPlansPriceList]);

  useEffect(() => {
    setTimeout(() => {
      getServicePlansPriceLists();
    }, 0);
    const refreshInterval = setInterval(
      () => getServicePlansPriceLists(true),
      autoRefreshDelay * 1000
    );

    return () => {
      clearInterval(refreshInterval);
    };
  }, []);

  return (
    <Layout title="service-plans-price-list">
      {isLoading && allPlansPriceList.length <= 0 ? (
        <Loader />
      ) : (
        <Table
          columns={columns}
          data={data}
          tableTitle={"Service Plans Price List"}
          buttons={{
            addButton: false,
            exportButton: true,
            exportButtonTitle: "Export",
            onClickExportButton() {
              toast("Feature not available on this version!");
            },
          }}
        />
      )}
    </Layout>
  );
};

export default ScreenServicePlansPriceList;
