import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ScreenAddAdmin from "../screens/admins/addAdmin";
import ScreenAdmins from "../screens/admins/ScreenAdmins";
import ScreenDashboard from "../screens/dashboard/ScreenDashboard";
import Screen404 from "../screens/error/Screen404";
import ScreenAddHoliday from "../screens/holiday/AddHoliday";
import ScreenHolidays from "../screens/holiday/ScreenHoliday";
import ScreenAddLocation from "../screens/location/AddLocation";
import ScreenLocations from "../screens/location/ScreenLocations";
import ScreenLogin from "../screens/login/ScreenLogin";
import ScreenAddSalesBanner from "../screens/sales/salesBanner/ScreenAddSalesBanner";
import ScreenSalesBanners from "../screens/sales/salesBanner/ScreenSalesBanners";
import ScreenAddSalesCategory from "../screens/sales/salesCategory/ScreenAddSalesCategory";
import ScreenSalesCategeories from "../screens/sales/salesCategory/ScreenSalesCategeories";
import ScreenAddSalesItem from "../screens/sales/salesItem/ScreenAddSalesItem";
import ScreenSalesItems from "../screens/sales/salesItem/ScreenSalesItems";
import ScreenSalesOrders from "../screens/sales/salesOrder/ScreenSalesOrders";
import ScreenAddServiceBanner from "../screens/services/serviceBanner/ScreenAddServiceBanner";
import ScreenServiceBanners from "../screens/services/serviceBanner/ScreenServiceBanners";
import ScreenAddServiceCategory from "../screens/services/serviceCategory/ScreenAddServiceCategory";
import ScreenServiceCategeories from "../screens/services/serviceCategory/ScreenServiceCategeories";
import ScreenServiceCustomPlan from "../screens/services/serviceCustomPlan/ScreenCustomPlan";
import ScreenAddServiceModel from "../screens/services/serviceModel/ScreenAddServiceModel";
import ScreenServiceModels from "../screens/services/serviceModel/ScreenServiceModels";
import ScreenServiceOrders from "../screens/services/serviceOrder/ScreenServiceOrders";
import ScreenAddServicePlan from "../screens/services/servicePlan/ScreenAddServicePlan";
import ScreenServicePlans from "../screens/services/servicePlan/ScreenServicePlans";
import ScreenServicePlansPriceList from "../screens/services/servicePlanPriceList/ScreenServicePlansPriceList";
import ScreenAddServicePlansPriceList from "../screens/services/servicePlanPriceList/ScreenAddServicePlansPriceList";
import ScreenAddService from "../screens/services/services/AddScreenServices";
import ScreenServices from "../screens/services/services/ScreenServices";
import ScreenAddServiceType from "../screens/services/serviceType/ScreenAddServiceType";
import ScreenServiceTypes from "../screens/services/serviceType/ScreenServiceTypes";
import ScreenSettings from "../screens/settings/ScreenSettings";
import ScreenCustomers from "../screens/customer/ScreenCustomers";
import ScreenAgents from "../screens/agent/ScreenAgent";
import ScreenAddAgent from "../screens/agent/ScreenAddAgent";
import PrivateRoute from "./PrivateRoute";
import ScreenProfile from "../screens/admins/ScreenProfile";

export const enum KRoutes {
  "login" = "/login",
  "dashboard" = "/dashboard",
  "serviceCategories" = "/service-categories",
  "addServiceCategory" = "/add-service-category",
  "editServiceCategory" = "/edit-service-category",
  "serviceTypes" = "/service-types",
  "addServiceType" = "/add-service-type",
  "editServiceType" = "/edit-service-type",
  "serviceBanners" = "/service-banners",
  "addServiceBanner" = "/add-service-banner",
  "editServiceBanner" = "/edit-service-banner",
  "serviceModels" = "/service-models",
  "addServiceModel" = "/add-service-model",
  "editServiceModel" = "/edit-service-model",
  "servicePlans" = "/service-plans",
  "addServicePlan" = "/add-service-plan",
  "editServicePlan" = "/edit-service-plan",
  "serviceCustomPlans" = "/service-custom-plans",
  "servicePlansPriceList" = "/service-plans-price-list",
  "addServicePlansPriceList" = "/add-service-plans-price-list",
  "editServicePlansPriceList" = "/edit-service-plans-price-list",
  "services" = "/services",
  "addService" = "/add-service",
  "editService" = "/edit-service",
  "serviceOrders" = "/service-orders",
  "salesCategories" = "/sales-categories",
  "addSalesCategory" = "/add-sales-category",
  "editSalesCategory" = "/edit-sales-category",
  "salesBanners" = "/sales-banners",
  "addSalesBanner" = "/add-sales-banner",
  "editSalesBanner" = "/edit-sales-banner",
  "salesItems" = "/sales-items",
  "addSalesItem" = "/add-sales-item",
  "editSalesItem" = "/edit-sales-item",
  "salesOrders" = "/sales-orders",
  "settings" = "/settings",
  "reports" = "/reports",
  "payments" = "/payments",
  "profile" = "/profile",
  "customers" = "/customers",
  "agents" = "/agents",
  "addAgent" = "/add-agent",
  "editAgent" = "/edit-agent",
  "admins" = "/admins",
  "addAdmin" = "/add-admin",
  "editAdmin" = "/edit-admin",
  "holidays" = "/holidays",
  "addHoliday" = "/add-holiday",
  "editHoliday" = "/edit-holiday",
  "locations" = "/locations",
  "addLocation" = "/add-location",
  "editLocation" = "/edit-location",
}

const Router = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to={KRoutes.dashboard} />} />
          <Route path={KRoutes.login} element={<ScreenLogin />} />
          <Route
            path={KRoutes.dashboard}
            element={<PrivateRoute component={<ScreenDashboard />} />}
          />
          <Route
            path={KRoutes.serviceCategories}
            element={<PrivateRoute component={<ScreenServiceCategeories />} />}
          />
          <Route
            path={KRoutes.addServiceCategory}
            element={<PrivateRoute component={<ScreenAddServiceCategory />} />}
          />
          <Route
            path={KRoutes.editServiceCategory + "/:cid"}
            element={<PrivateRoute component={<ScreenAddServiceCategory />} />}
          />
          <Route
            path={KRoutes.serviceTypes}
            element={<PrivateRoute component={<ScreenServiceTypes />} />}
          />
          <Route
            path={KRoutes.addServiceType}
            element={<PrivateRoute component={<ScreenAddServiceType />} />}
          />
          <Route
            path={KRoutes.editServiceType + "/:tid"}
            element={<PrivateRoute component={<ScreenAddServiceType />} />}
          />
          <Route
            path={KRoutes.serviceBanners}
            element={<PrivateRoute component={<ScreenServiceBanners />} />}
          />
          <Route
            path={KRoutes.addServiceBanner}
            element={<PrivateRoute component={<ScreenAddServiceBanner />} />}
          />
          <Route
            path={KRoutes.editServiceBanner + "/:bid"}
            element={<PrivateRoute component={<ScreenAddServiceBanner />} />}
          />
          <Route
            path={KRoutes.serviceModels}
            element={<PrivateRoute component={<ScreenServiceModels />} />}
          />
          <Route
            path={KRoutes.addServiceModel}
            element={<PrivateRoute component={<ScreenAddServiceModel />} />}
          />
          <Route
            path={KRoutes.editServiceModel + "/:mid"}
            element={<PrivateRoute component={<ScreenAddServiceModel />} />}
          />
          <Route
            path={KRoutes.servicePlans}
            element={<PrivateRoute component={<ScreenServicePlans />} />}
          />
          <Route
            path={KRoutes.addServicePlan}
            element={<PrivateRoute component={<ScreenAddServicePlan />} />}
          />
          <Route
            path={KRoutes.editServicePlan + "/:pid"}
            element={<PrivateRoute component={<ScreenAddServicePlan />} />}
          />
          <Route
            path={KRoutes.serviceCustomPlans}
            element={<PrivateRoute component={<ScreenServiceCustomPlan />} />}
          />
          <Route
            path={KRoutes.servicePlansPriceList}
            element={
              <PrivateRoute component={<ScreenServicePlansPriceList />} />
            }
          />
          <Route
            path={KRoutes.addServicePlansPriceList}
            element={
              <PrivateRoute component={<ScreenAddServicePlansPriceList />} />
            }
          />
          <Route
            path={KRoutes.editServicePlansPriceList + "/:pid"}
            element={
              <PrivateRoute component={<ScreenAddServicePlansPriceList />} />
            }
          />
          <Route
            path={KRoutes.services}
            element={<PrivateRoute component={<ScreenServices />} />}
          />
          <Route
            path={KRoutes.addService}
            element={<PrivateRoute component={<ScreenAddService />} />}
          />
          <Route
            path={KRoutes.editService + "/:sid"}
            element={<PrivateRoute component={<ScreenAddService />} />}
          />
          <Route
            path={KRoutes.serviceOrders}
            element={<PrivateRoute component={<ScreenServiceOrders />} />}
          />
          <Route
            path={KRoutes.salesCategories}
            element={<PrivateRoute component={<ScreenSalesCategeories />} />}
          />
          <Route
            path={KRoutes.addSalesCategory}
            element={<PrivateRoute component={<ScreenAddSalesCategory />} />}
          />
          <Route
            path={KRoutes.editSalesCategory + "/:cid"}
            element={<PrivateRoute component={<ScreenAddSalesCategory />} />}
          />
          <Route
            path={KRoutes.salesBanners}
            element={<PrivateRoute component={<ScreenSalesBanners />} />}
          />
          <Route
            path={KRoutes.addSalesBanner}
            element={<PrivateRoute component={<ScreenAddSalesBanner />} />}
          />
          <Route
            path={KRoutes.editSalesBanner + "/:bid"}
            element={<PrivateRoute component={<ScreenAddSalesBanner />} />}
          />
          <Route
            path={KRoutes.salesItems}
            element={<PrivateRoute component={<ScreenSalesItems />} />}
          />
          <Route
            path={KRoutes.addSalesItem}
            element={<PrivateRoute component={<ScreenAddSalesItem />} />}
          />
          <Route
            path={KRoutes.editSalesItem + "/:sid"}
            element={<PrivateRoute component={<ScreenAddSalesItem />} />}
          />
          <Route
            path={KRoutes.salesOrders}
            element={<PrivateRoute component={<ScreenSalesOrders />} />}
          />
          <Route
            path={KRoutes.admins}
            element={<PrivateRoute component={<ScreenAdmins />} />}
          />
          <Route
            path={KRoutes.profile}
            element={<PrivateRoute component={<ScreenProfile />} />}
          />
          <Route
            path={KRoutes.addAdmin}
            element={<PrivateRoute component={<ScreenAddAdmin />} />}
          />
          <Route
            path={KRoutes.editAdmin + "/:aid"}
            element={<PrivateRoute component={<ScreenAddAdmin />} />}
          />
          <Route
            path={KRoutes.customers}
            element={<PrivateRoute component={<ScreenCustomers />} />}
          />
          <Route
            path={KRoutes.agents}
            element={<PrivateRoute component={<ScreenAgents />} />}
          />
          <Route
            path={KRoutes.addAgent}
            element={<PrivateRoute component={<ScreenAddAgent />} />}
          />
          <Route
            path={KRoutes.editAgent + "/:tid"}
            element={<PrivateRoute component={<ScreenAddAgent />} />}
          />
          <Route
            path={KRoutes.holidays}
            element={<PrivateRoute component={<ScreenHolidays />} />}
          />
          <Route
            path={KRoutes.addHoliday}
            element={<PrivateRoute component={<ScreenAddHoliday />} />}
          />
          <Route
            path={KRoutes.editHoliday + "/:hid"}
            element={<PrivateRoute component={<ScreenAddHoliday />} />}
          />
          <Route
            path={KRoutes.locations}
            element={<PrivateRoute component={<ScreenLocations />} />}
          />
          <Route
            path={KRoutes.addLocation}
            element={<PrivateRoute component={<ScreenAddLocation />} />}
          />
          <Route
            path={KRoutes.editLocation + "/:lid"}
            element={<PrivateRoute component={<ScreenAddLocation />} />}
          />
          <Route
            path={KRoutes.settings}
            element={<PrivateRoute component={<ScreenSettings />} />}
          />
          <Route path="*" element={<Screen404 />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Router;
