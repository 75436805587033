import React, { useCallback, useEffect, useState } from "react";
import {
  GridView,
  SelectInput,
  FileUpload,
  TextField,
  TextAreaField,
} from "../../../components/TextFieldItems";
import Layout from "../../../components/Layout";
import { useNavigate, useParams } from "react-router-dom";
import { KRoutes } from "../../../routers/router";
import { useAppSelector } from "../../../redux/hooks";
import { addSalesItem, editSalesItem } from "../../../services/sales/item";
import ApiEndpoints from "../../../core/apiEndpoints";
import { ISelectedImage } from "../../../types/default";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";

const ScreenAddSalesItem = () => {
  const { items, isLoading } = useAppSelector((state) => state.salesItems);
  const { categories } = useAppSelector((state) => state.salesCategory);
  const [category, setCategory] = useState("");
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [qty, setQty] = useState("");
  const [sPrice, setSPrice] = useState("");
  const [pPrice, setPPrice] = useState("");
  const [taxPercent, setTaxPercent] = useState("");
  const [MRP, setMRP] = useState("");
  const [redeemPoint, setRedeemPoint] = useState("");
  const [discount, setDiscount] = useState("");
  const [status, setStatus] = useState("Active");
  const [visibility, setVisibility] = useState("Show");
  const [selectedFile, setSelectedFile] = useState<ISelectedImage>({
    file: null,
    URL: null,
  });

  const params = useParams();
  const navigate = useNavigate();

  const setEditFields = useCallback(
    (id: string) => {
      const item = items.find((i) => i._id === id);
      if (item) {
        setName(item.category._id);
        setName(item.name);
        setDesc(item.description);
        setQty(item.quantity);
        setSPrice(item.salePrice);
        setPPrice(item.purchasePrice);
        setTaxPercent(item.tax);
        setMRP(item.MRP);
        setRedeemPoint(item.radeempoint);
        setDiscount(item.discount);
        setSelectedFile((prevState) => ({
          ...prevState,
          URL: ApiEndpoints.salesItemImage + item._id,
        }));
        setStatus(item.status);
        setVisibility(item.visibility);
      }
    },
    [items]
  );

  useEffect(() => {
    if (params.siid) {
      setEditFields(params.siid);
    }
  }, [params.siid, setEditFields]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    if (
      !category ||
      !name ||
      !desc ||
      !qty ||
      !sPrice ||
      !pPrice ||
      !taxPercent ||
      !MRP ||
      !redeemPoint ||
      !discount ||
      (!params.siid && !selectedFile.file)
    ) {
      toast(
        "Fill category, name, desc, qty, sales price, purchase price, tax percentage, MRP, image, redeemPoint and discount"
      );
      return;
    } else {
      const itemForm = new FormData();
      itemForm.append("category", category);
      itemForm.append("name", name);
      itemForm.append("description", desc);
      itemForm.append("quantity", qty);
      itemForm.append("salePrice", sPrice);
      itemForm.append("purchasePrice", pPrice);
      itemForm.append("tax", taxPercent);
      itemForm.append("MRP", MRP);
      itemForm.append("radeempoint", redeemPoint);
      itemForm.append("discount", discount);
      itemForm.append("status", status);
      itemForm.append("visibility", visibility);
      if (selectedFile.file) {
        itemForm.append("image", selectedFile.file);
      }

      const success = params.siid
        ? await editSalesItem(params.siid, itemForm)
        : await addSalesItem(itemForm);
      if (success) {
        navigate(KRoutes.salesItems);
      }
    }
  };
  return (
    <Layout title="sales-items">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="p-10 overflow-y-scroll overflow-x-hidden scrollbar-hide">
          <div className="flex justify-between">
            <p className="lg:text-3xl text-2xl font-black dark:text-white ">
              {params.siid ? "Edit" : "Add"} Item
            </p>
            <div>
              <button
                className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800`"
                onClick={() => navigate(-1)}
              >
                <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                  Back
                </span>
              </button>
            </div>
          </div>
          <form className="mt-6 " onSubmit={handleSubmit}>
            <GridView className="gap-6 mb-6 lg:grid-cols-2">
              <>
                <SelectInput
                  data={categories.map((category) => {
                    return {
                      id: category._id,
                      title: category.name,
                    };
                  })}
                  title="Category"
                  disabled={params.siid ? true : false}
                  onChange={(e) => setCategory(e.target.value)}
                />
                <TextField
                  label="Name"
                  id="name"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="text"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="name"
                  value={name}
                  required={true}
                  placeholder="Item Name"
                  onChange={(e) => setName(e.target.value)}
                />

                <TextField
                  label="Quantity"
                  id="qty"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="number"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="qty"
                  value={qty}
                  required={true}
                  placeholder="Item's Total Quantity"
                  onChange={(e) => setQty(e.target.value)}
                />
                <TextField
                  label="Purchase Price"
                  id="pprice"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="number"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="pprice"
                  value={pPrice}
                  required={true}
                  placeholder="Item Purchase Price"
                  onChange={(e) => setPPrice(e.target.value)}
                />
                <TextField
                  label="Sale Price"
                  id="sprice"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="number"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="sprice"
                  value={sPrice}
                  required={true}
                  placeholder="Item Sale Price"
                  onChange={(e) => setSPrice(e.target.value)}
                />
                <TextField
                  label="Tax Percentage"
                  id="taxPercent"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="number"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="taxPercent"
                  value={taxPercent}
                  required={true}
                  placeholder="Item Tax Percentage"
                  onChange={(e) => setTaxPercent(e.target.value)}
                />
                <TextField
                  label="MRP"
                  id="mrp"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="number"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="mrp"
                  value={MRP}
                  required={true}
                  placeholder="Item MRP"
                  onChange={(e) => setMRP(e.target.value)}
                />
                <TextField
                  label="Redeem Point"
                  id="radeem"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="number"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="radeem"
                  value={redeemPoint}
                  required={true}
                  placeholder="Item Redeem Point"
                  onChange={(e) => setRedeemPoint(e.target.value)}
                />
                <TextField
                  label="Discount"
                  id="discount"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="number"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="discount"
                  value={discount}
                  required={true}
                  placeholder="Item Discount"
                  onChange={(e) => setDiscount(e.target.value)}
                />

                <SelectInput
                  data={[
                    {
                      id: "Active",
                      title: "Active",
                    },
                    {
                      id: "Inactive",
                      title: "Inactive",
                    },
                  ]}
                  title="Status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                />
                <SelectInput
                  data={[
                    {
                      id: "Show",
                      title: "Show",
                    },
                    {
                      id: "Hide",
                      title: "Hide",
                    },
                  ]}
                  title="Visibility"
                  value={visibility}
                  onChange={(e) => setVisibility(e.target.value)}
                />
                <TextAreaField
                  label="Description"
                  id="desc"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="desc"
                  value={desc}
                  required={true}
                  placeholder="Item Description"
                  onChange={(e) => setDesc(e.target.value)}
                />
                <FileUpload
                  title="Image"
                  desc=" PNG, JPG  (MAX. 800x400px)."
                  accept="image/*"
                  maxSize={1}
                  file={selectedFile.file}
                  url={selectedFile.URL}
                  onFileSelect={(file) => {
                    setSelectedFile({
                      file: file,
                      URL: URL.createObjectURL(file),
                    });
                  }}
                  removeSelectedFile={() => {
                    setSelectedFile({
                      file: null,
                      URL: "",
                    });
                  }}
                />
              </>
            </GridView>
            <div className="flex items-center justify-center -mx-3 my-6">
              <div className="w-full lg:w-1/2 px-3">
                <button
                  type="submit"
                  className="w-full active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-3 bg-primary-color text-white  text-lg font-bold rounded-xl"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </Layout>
  );
};

export default ScreenAddSalesItem;
