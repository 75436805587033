import {
  getSalesCategories,
  getSalesDeletedCategories,
  addSalesCategory,
  editSalesCategory,
  changeSalesCategoryStatus,
  changeSalesCategoryVisibility,
  pDeleteSalesCategory,
  restoreSalesCategory,
  deleteSalesCategory,
} from "./category";

import {
  getSalesBanners,
  getDeletedSalesBanners,
  addSalesBanner,
  editSalesBanner,
  changeSalesBannerVisibility,
  pDeleteSalesBanner,
  restoreSalesBanner,
  deleteSalesBanner,
} from "./banner";

import {
  getSalesItems,
  getSalesDeletedItem,
  addSalesItem,
  editSalesItem,
  changeSalesItemVisibility,
  restoreSalesItem,
  pDeleteSalesItem,
  deleteSalesItem,
} from "./item";

/**
 * To get all data's related in sales from server
 */
export const getAllSales = (refresh?: boolean) => {
  getSalesCategories(refresh);
  getSalesBanners(refresh);
  getSalesBanners(refresh);
  getSalesItems(refresh);
};

export {
  // Category
  getSalesCategories,
  getSalesDeletedCategories,
  addSalesCategory,
  editSalesCategory,
  changeSalesCategoryStatus,
  changeSalesCategoryVisibility,
  pDeleteSalesCategory,
  restoreSalesCategory,
  deleteSalesCategory,
  // Banners
  getSalesBanners,
  getDeletedSalesBanners,
  addSalesBanner,
  editSalesBanner,
  changeSalesBannerVisibility,
  restoreSalesBanner,
  pDeleteSalesBanner,
  deleteSalesBanner,
  // Sales
  getSalesItems,
  getSalesDeletedItem,
  addSalesItem,
  editSalesItem,
  changeSalesItemVisibility,
  restoreSalesItem,
  pDeleteSalesItem,
  deleteSalesItem,
};
