import { configureStore } from "@reduxjs/toolkit";
import notificationReducer from "./notification";
import settingsReducer from "./settings";
import adminReducer from "./admin";
import locationReducer from "./location";
import holidayReducer from "./holiday";
import customerReducer from "./customer";
import agentReducer from "./agent";
import {
  serviceCategoryReducer,
  serviceTypeReducer,
  serviceBannerReducer,
  serviceModelReducer,
  servicePlanReducer,
  serviceReducer,
  serviceCustomPlanReducer,
  servicePlansPriceListReducer,
} from "./services";
import {
  salesBannerReducer,
  salesCategoryReducer,
  salesItemReducer,
} from "./sales";

const store = configureStore({
  reducer: {
    serviceCategories: serviceCategoryReducer,
    serviceTypes: serviceTypeReducer,
    serviceBanners: serviceBannerReducer,
    serviceModels: serviceModelReducer,
    servicePlans: servicePlanReducer,
    services: serviceReducer,
    serviceCustomPlans: serviceCustomPlanReducer,
    servicePlansPriceList: servicePlansPriceListReducer,
    // serviceOrders
    salesBanner: salesBannerReducer,
    salesCategory: salesCategoryReducer,
    salesItems: salesItemReducer,
    // salesOrders
    admins: adminReducer,
    customers: customerReducer,
    agents: agentReducer,
    locations: locationReducer,
    holidays: holidayReducer,
    settings: settingsReducer,
    notification: notificationReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const dispatch = store.dispatch;
export default store;
