import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  MessagePayload,
  onMessage,
} from "firebase/messaging";
import { setNotificationMessage } from "./redux/notification";
import { dispatch } from "./redux/store";

const firebaseConfig = {
  apiKey: "AIzaSyAl4mIr-vUlhPhWkPCiQU-ILcdyqT58xXU",
  authDomain: "kwiknkleen-project.firebaseapp.com",
  projectId: "kwiknkleen-project",
  storageBucket: "kwiknkleen-project.appspot.com",
  messagingSenderId: "261667055490",
  appId: "1:261667055490:web:f1146116eb472b1fd2119b",
  measurementId: "G-TLQJW9BSYM",
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey:
      "BCMpKdfixNjuzaAxmoLIscEtgDOfNaJxq5U1XUYFsjOR8BxNDE7tSeSzwbNBCEmVp7lDh_msddyFlpNnMFz_mmY",
  })
    .then((currentToken) => {
      if (currentToken) {
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        // messaging.requestPermission().then(()=> messaging.getToken()).then((token)=> console.log(token)).catch((error)=> console.log(error))

        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("Notification permission denied!", err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve: (value: MessagePayload) => void) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);
      dispatch(setNotificationMessage(payload.notification?.body ?? ""));
      resolve(payload);
    });
  });
