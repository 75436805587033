import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IServicePlansPriceList } from "../../types/services";

interface IServicePlansPriceListState {
  plansPriceList: IServicePlansPriceList[];
  nonAvailablePlansPriceList: IServicePlansPriceList[];
  allPlansPriceList: IServicePlansPriceList[];
  deletedPlansPriceLists: IServicePlansPriceList[];
  isLoading: boolean;
}

const servicePlanPriceListInitState: IServicePlansPriceListState = {
  plansPriceList: [],
  nonAvailablePlansPriceList: [],
  allPlansPriceList: [],
  deletedPlansPriceLists: [],
  isLoading: false,
};

const servicesPlanPriceListSlice = createSlice({
  name: "service-plans-price-list",
  initialState: servicePlanPriceListInitState,
  reducers: {
    changeServicePlansPriceListLoading: (
      state: IServicePlansPriceListState,
      action: PayloadAction<boolean>
    ) => {
      if (state.isLoading !== action.payload) {
        state.isLoading = action.payload;
      }
    },
    setServicePlansPriceListsState: (
      state: IServicePlansPriceListState,
      action: PayloadAction<{ planPriceLists: IServicePlansPriceList[]; refresh?: boolean }>
    ) => {
      if (state.plansPriceList.length <= 0 || action.payload.refresh) {
        state.plansPriceList = action.payload.planPriceLists || state.plansPriceList;
      }
      state.isLoading = false;
    },
    setServiceAllPlansPriceListsState: (
      state: IServicePlansPriceListState,
      action: PayloadAction<{ allPlansPriceList: IServicePlansPriceList[]; refresh?: boolean }>
    ) => {
      if (state.allPlansPriceList.length <= 0 || action.payload.refresh) {
        state.nonAvailablePlansPriceList = action.payload.allPlansPriceList.filter((planPriceList) => !planPriceList.available) || state.nonAvailablePlansPriceList;
        state.allPlansPriceList = action.payload.allPlansPriceList || state.allPlansPriceList;
      }
      state.isLoading = false;
    },
    setServiceDeletedPlanPriceListsState: (
      state: IServicePlansPriceListState,
      action: PayloadAction<{ planPriceLists: IServicePlansPriceList[]; refresh?: boolean }>
    ) => {
      if (state.deletedPlansPriceLists.length <= 0 || action.payload.refresh) {
        state.deletedPlansPriceLists = action.payload.planPriceLists || state.deletedPlansPriceLists;
      }
      state.isLoading = false;
    },
    addServicePlansPriceListState: (
      state: IServicePlansPriceListState,
      action: PayloadAction<IServicePlansPriceList>
    ) => {
      state.plansPriceList.push(action.payload);
      state.isLoading = false;
    },
    editServicePlansPriceListState: (
      state: IServicePlansPriceListState,
      action: PayloadAction<IServicePlansPriceList>
    ) => {
      state.plansPriceList = state.plansPriceList.map((planPriceList) => {
        if (action.payload._id === planPriceList._id) {
          planPriceList = action.payload;
        }
        return planPriceList;
      });
      state.isLoading = false;
    },
    editServiceAllPlansPriceListState: (
      state: IServicePlansPriceListState,
      action: PayloadAction<{plansPriceList:IServicePlansPriceList; plansPriceListId:string }>
    ) => {
      state.allPlansPriceList = state.allPlansPriceList.map((planPriceList) => {
        if (action.payload.plansPriceListId === planPriceList._id) {
          planPriceList = action.payload.plansPriceList;
        }
        return planPriceList;
      });
      state.isLoading = false;
    },
    deleteServicePlansPriceListState: (
      state: IServicePlansPriceListState,
      action: PayloadAction<IServicePlansPriceList["_id"]>
    ) => {
      state.plansPriceList = state.plansPriceList.filter(
        (planPriceList) => planPriceList._id !== action.payload
      );
      state.isLoading = false;
    },
    pDeleteServicePlansPriceListState: (
      state: IServicePlansPriceListState,
      action: PayloadAction<IServicePlansPriceList["_id"]>
    ) => {
      state.deletedPlansPriceLists = state.deletedPlansPriceLists.filter(
        (planPriceList) => planPriceList._id !== action.payload
      );
      state.isLoading = false;
    },
    restoreServicePlansPriceListState: (
      state: IServicePlansPriceListState,
      action: PayloadAction<IServicePlansPriceList>
    ) => {
      state.plansPriceList.push(action.payload);
      state.isLoading = false;
    },
    clearServicePlansPriceListState: (state: IServicePlansPriceListState) => {
      state = servicePlanPriceListInitState;
    },
  },
});

export const {
  changeServicePlansPriceListLoading,
  setServicePlansPriceListsState,
  setServiceAllPlansPriceListsState,
  setServiceDeletedPlanPriceListsState,
  addServicePlansPriceListState,
  editServicePlansPriceListState,
  editServiceAllPlansPriceListState,
  deleteServicePlansPriceListState,
  pDeleteServicePlansPriceListState,
  restoreServicePlansPriceListState,
  clearServicePlansPriceListState,
} = servicesPlanPriceListSlice.actions;

export default servicesPlanPriceListSlice.reducer;
