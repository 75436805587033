import { Column } from "react-table";
import { SelectColumnFilter } from "../../../components/Table";
import {
  ButtonsPill,
  DateFromatPill,
  StatusPill,
} from "../../../components/TableCells";
import store from "../../../redux/store";
import { KRoutes } from "../../../routers/router";
import { changeServiceStatus, deleteService } from "../../../services/services";
import { IService } from "../../../types/services";

const servicesTableHeaders: Column<IService>[] = [
  {
    id: "name",
    Header: "Name",
    accessor: "name",
  },
  {
    id: "category",
    Header: "Category",
    accessor: (v) => v.category.name ?? "No Category",
    Filter: SelectColumnFilter,
    filter: "includes",
  },
  {
    id: "price",
    Header: "Price",
    accessor: "price",
  },
  {
    id: "duration",
    Header: "Duration",
    accessor: "duration",
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: StatusPill,
    Filter: SelectColumnFilter, // new
    filter: "includes",
  },
  {
    Header: "CreatedAt",
    accessor: "createdAt",
    Cell: DateFromatPill,
  },
  {
    Header: "UpdatedAt",
    accessor: "updatedAt",
    Cell: DateFromatPill,
  },
  {
    Header: "Action",
    accessor: "_id",
    Cell: ({ value }) => {
      return ButtonsPill({
        statusChangeButton: true,
        visibilityChangeButton: false,
        editButton: true,
        deleteButton: ["MasterAdmin", "Developer"].includes(store.getState().admins.profile?.role || "")
        ? true
        : false,        editPath: KRoutes.editService + "/" + value,
        onClickDelete: () => deleteService(value),
        onClickStatusChange: () => changeServiceStatus(value),
      });
    },
  },
];

export default servicesTableHeaders;
