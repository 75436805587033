import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAgent } from "../types/agent";

interface IAgentState {
  agents: IAgent[];
  deletedAgents: IAgent[];
  isLoading: boolean;
}

const agentInitState: IAgentState = {
  agents: [],
  deletedAgents: [],
  isLoading: false,
};

const agentSlice = createSlice({
  name: "agents",
  initialState: agentInitState,
  reducers: {
    changeAgentLoading: (
      state: IAgentState,
      action: PayloadAction<boolean>
    ) => {
      if (state.isLoading !== action.payload) {
        state.isLoading = action.payload;
      }
    },
    setAgentsState: (
      state: IAgentState,
      action: PayloadAction<{agents:IAgent[]; refresh?: boolean }>
    ) => {
      if (state.agents.length <= 0 || action.payload.refresh) {
        state.agents = action.payload.agents || state.agents;
      }
      state.isLoading = false;
    },
    setDeletedAgentState: (
      state: IAgentState,
      action: PayloadAction<{agents:IAgent[]; refresh?: boolean }>
    ) => {
      if (state.deletedAgents.length <= 0 || action.payload.refresh) {
        state.deletedAgents = action.payload.agents || state.deletedAgents;
      }
      state.isLoading = false;
    },
    addAgentState: (
      state: IAgentState,
      action: PayloadAction<IAgent>
    ) => {
      state.agents.push(action.payload);
      state.isLoading = false;
    },
    editAgentState: (
      state: IAgentState,
      action: PayloadAction<IAgent>
    ) => {
      state.agents = state.agents.map((agent) => {
        if (action.payload._id === agent._id) {
          agent = action.payload;
        }
        return agent;
      });
      state.isLoading = false;
    },
    changeAgentStatusState: (
      state: IAgentState,
      action: PayloadAction<IAgent>
    ) => {
      state.agents = state.agents.map((agent) => {
        if (
          action.payload._id === agent._id &&
          action.payload.status !== agent.status
        ) {
          agent.status = action.payload.status;
        }
        return agent;
      });
      state.isLoading = false;
    },
    deleteAgentState: (
      state: IAgentState,
      action: PayloadAction<IAgent["_id"]>
    ) => {
      state.agents = state.agents.filter(
        (agent) => agent._id !== action.payload
      );
      state.isLoading = false;
    },
    pDeleteAgentState: (
      state: IAgentState,
      action: PayloadAction<IAgent["_id"]>
    ) => {
      state.deletedAgents = state.deletedAgents.filter(
        (agent) => agent._id !== action.payload
      );
      state.isLoading = false;
    },
    restoreAgentState: (
      state: IAgentState,
      action: PayloadAction<IAgent>
    ) => {
      state.agents.push(action.payload);
      state.isLoading = false;
    },
    clearAgentState: (state: IAgentState) => {
      state = agentInitState;
    },
  },
});

export const {
  changeAgentLoading,
  setAgentsState,
  setDeletedAgentState,
  addAgentState,
  editAgentState,
  changeAgentStatusState,
  deleteAgentState,
  pDeleteAgentState,
  restoreAgentState,
  clearAgentState,
} = agentSlice.actions;

export default agentSlice.reducer;
