import httpClient from "../../core/axios";
import ApiEndpoints from "../../core/apiEndpoints";
import store, { dispatch } from "../../redux/store";
import { setErrorMessage, setSuccessMessage } from "../../redux/notification";
import {
  changeHolidayLoading,
  setHolidaysState,
  setDeletedHolidaysState,
  addHolidayState,
  deleteHolidayState,
  editHolidayState,
  restoreHolidayState,
  pDeleteHolidayState,
} from "../../redux/holiday";

/**
 * To get a  holidays from server
 */
export const getHolidays = async (refresh?: boolean) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeHolidayLoading(true));

    // API Fetching Deleted
    if (store.getState().admins.profile?.role === "MasterAdmin")
      getDeletedHolidays(refresh);

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.holiday);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setHolidaysState({ holidays: resp.data.data, refresh }));
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeHolidayLoading(false));
  }
};

/**
 * To get a deleted holidays from server
 */
export const getDeletedHolidays = async (refresh?: boolean) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeHolidayLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.deletedHoliday);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setDeletedHolidaysState({ holidays: resp.data.data, refresh }));
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeHolidayLoading(false));
  }
};

/**
 * To add a new  holiday
 */
export const addHoliday = async (holidayData: any) => {
  try {
    // Set Loading
    dispatch(changeHolidayLoading(true));

    // API Fetching
    const resp = await httpClient().post(ApiEndpoints.holiday, holidayData);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(addHolidayState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeHolidayLoading(false));
  }
};

/**
 * To add a new  holiday
 */
export const editHoliday = async (id: string, holidayData: any) => {
  try {
    // Set Loading
    dispatch(changeHolidayLoading(true));

    // API Fetching
    const resp = await httpClient().patch(
      ApiEndpoints.holiday + "/" + id,
      holidayData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(editHolidayState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeHolidayLoading(false));
  }
};

/**
 * To delete the holiday
 */
export const deleteHoliday = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeHolidayLoading(true));

    // API Fetching
    const resp = await httpClient().delete(ApiEndpoints.holiday + "/" + id);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(deleteHolidayState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeHolidayLoading(false));
  }
};

/**
 * To restore the holiday
 */
export const restoreHoliday = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeHolidayLoading(true));

    // API Fetching
    const resp = await httpClient().delete(ApiEndpoints.restoreHoliday + id);
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(restoreHolidayState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeHolidayLoading(false));
  }
};

/**
 * To delete the holiday permanently
 */
export const pDeleteHoliday = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeHolidayLoading(true));

    // API Fetching
    const resp = await httpClient().delete(ApiEndpoints.deleteHoliday + id);
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(pDeleteHolidayState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeHolidayLoading(false));
  }
};
