import httpClient from "../../core/axios";
import ApiEndpoints from "../../core/apiEndpoints";
import store, { dispatch } from "../../redux/store";
import { setErrorMessage, setSuccessMessage } from "../../redux/notification";
import {
  changeServiceTypeLoading,
  setServiceTypesState,
  addServiceTypeState,
  changeServiceTypeStatusState,
  deleteServiceTypeState,
  editServiceTypeState,
  restoreServiceTypeState,
  pDeleteServiceTypeState,
  setServiceDeletedTypesState,
} from "../../redux/services/types";

/**
 * To get a service types from server
 */
export const getServiceTypes = async (refresh?: boolean) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeServiceTypeLoading(true));

    // API Fetching Deleted
    if (store.getState().admins.profile?.role === "MasterAdmin")
      getDeletedServiceTypes(refresh);

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.serviceType);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setServiceTypesState({ types: resp.data.data, refresh }));
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceTypeLoading(false));
  }
};

/**
 * To get a deleted type from server
 */
export const getDeletedServiceTypes = async (refresh?: boolean) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeServiceTypeLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.deletedServiceType);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setServiceDeletedTypesState({ types: resp.data.data, refresh }));
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceTypeLoading(false));
  }
};

/**
 * To add a new service type
 */
export const addServiceType = async (typeData: any) => {
  try {
    // Set Loading
    dispatch(changeServiceTypeLoading(true));

    // API Fetching
    const resp = await httpClient().post(
      ApiEndpoints.serviceType,
      typeData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(addServiceTypeState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceTypeLoading(false));
  }
};

/**
 * To add a new service type
 */
export const editServiceType = async (id: string, typeData: any) => {
  try {
    // Set Loading
    dispatch(changeServiceTypeLoading(true));

    // API Fetching
    const resp = await httpClient().patch(
      ApiEndpoints.serviceType + "/" + id,
      typeData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(editServiceTypeState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceTypeLoading(false));
  }
};

/**
 * To change the status
 */
export const changeServiceTypeStatus = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeServiceTypeLoading(true));

    // API Fetching
    const resp = await httpClient().get(
      ApiEndpoints.changeServiceTypeStatus + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeServiceTypeStatusState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceTypeLoading(false));
  }
};

/**
 * To delete the type
 */
export const deleteServiceType = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeServiceTypeLoading(true));

    // API Fetching
    const resp = await httpClient().delete(ApiEndpoints.serviceType + "/" + id);
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(deleteServiceTypeState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceTypeLoading(false));
  }
};

/**
 * To restore the service type
 */
export const restoreServiceType = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeServiceTypeLoading(true));

    // API Fetching
    const resp = await httpClient().delete(
      ApiEndpoints.restoreServiceType + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(restoreServiceTypeState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceTypeLoading(false));
  }
};

/**
 * To delete the service type permanently
 */
export const pDeleteServiceType = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeServiceTypeLoading(true));

    // API Fetching
    const resp = await httpClient().delete(ApiEndpoints.deleteServiceType + id);
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(pDeleteServiceTypeState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceTypeLoading(false));
  }
};
