import httpClient from "../../core/axios";
import ApiEndpoints from "../../core/apiEndpoints";
import store, { dispatch } from "../../redux/store";
import { setErrorMessage, setSuccessMessage } from "../../redux/notification";
import {
  changeServicePlansPriceListLoading,
  setServiceAllPlansPriceListsState,
  setServicePlansPriceListsState,
  setServiceDeletedPlanPriceListsState,
  addServicePlansPriceListState,
  deleteServicePlansPriceListState,
  editServicePlansPriceListState,
  restoreServicePlansPriceListState,
  pDeleteServicePlansPriceListState,
  editServiceAllPlansPriceListState,
} from "../../redux/services";

/**
 * To get a service planPriceLists from server
 */
export const getServicePlansPriceLists = async (refresh?: boolean) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeServicePlansPriceListLoading(true));

    //
    getServiceAllPlansPriceLists(refresh);

    // API Fetching Deleted
    if (store.getState().admins.profile?.role === "MasterAdmin")
      getDeletedServicePlansPriceLists(refresh);

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.servicePlansPriceList);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(
      setServicePlansPriceListsState({
        planPriceLists: resp.data.data,
        refresh,
      })
    );
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServicePlansPriceListLoading(false));
  }
};
/**
 * To get a service planPriceLists from server
 */
export const getServiceAllPlansPriceLists = async (refresh?: boolean) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeServicePlansPriceListLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.serviceAllPlansPriceLists);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(
      setServiceAllPlansPriceListsState({
        allPlansPriceList: resp.data.data,
        refresh,
      })
    );
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServicePlansPriceListLoading(false));
  }
};

/**
 * To get a deleted planPriceList from server
 */
export const getDeletedServicePlansPriceLists = async (refresh?: boolean) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeServicePlansPriceListLoading(true));

    // API Fetching
    const resp = await httpClient().get(
      ApiEndpoints.deletedServicePlansPriceList
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(
      setServiceDeletedPlanPriceListsState({
        planPriceLists: resp.data.data,
        refresh,
      })
    );
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServicePlansPriceListLoading(false));
  }
};

/**
 * To add a new service planPriceList
 */
export const addServicePlansPriceList = async (planPriceListData: any) => {
  try {
    // Set Loading
    dispatch(changeServicePlansPriceListLoading(true));

    // API Fetching
    const resp = await httpClient().post(
      ApiEndpoints.servicePlansPriceList,
      planPriceListData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(addServicePlansPriceListState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServicePlansPriceListLoading(false));
  }
};

/**
 * To add a new service planPriceList
 */
export const editServicePlansPriceList = async (
  id: string,
  planPriceListData: any
) => {
  try {
    // Set Loading
    dispatch(changeServicePlansPriceListLoading(true));

    // API Fetching
    const resp = await httpClient().patch(
      ApiEndpoints.servicePlansPriceList + "/" + id,
      planPriceListData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(editServicePlansPriceListState(resp.data.data));
    dispatch(
      editServiceAllPlansPriceListState({
        plansPriceList: resp.data.data,
        plansPriceListId: id,
      })
    );
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServicePlansPriceListLoading(false));
  }
};

/**
 * To delete the planPriceList
 */
export const deleteServicePlansPriceList = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeServicePlansPriceListLoading(true));

    // API Fetching
    const resp = await httpClient().delete(
      ApiEndpoints.servicePlansPriceList + "/" + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(deleteServicePlansPriceListState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServicePlansPriceListLoading(false));
  }
};

/**
 * To restore the service planPriceList
 */
export const restoreServicePlansPriceList = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeServicePlansPriceListLoading(true));

    // API Fetching
    const resp = await httpClient().delete(
      ApiEndpoints.restoreServicePlansPriceList + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(restoreServicePlansPriceListState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServicePlansPriceListLoading(false));
  }
};

/**
 * To delete the service planPriceList permanently
 */
export const pDeleteServicePlansPriceList = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeServicePlansPriceListLoading(true));

    // API Fetching
    const resp = await httpClient().delete(
      ApiEndpoints.deleteServicePlansPriceList + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(pDeleteServicePlansPriceListState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServicePlansPriceListLoading(false));
  }
};
