import React, { useCallback, useEffect, useState } from "react";
import {
  TextField,
  GridView,
  SelectInput,
} from "../../components/TextFieldItems";
import Layout from "../../components/Layout";
import { useNavigate, useParams } from "react-router-dom";
import { KRoutes } from "../../routers/router";
import { addAdmin, editAdmin } from "../../services/admins/admin";
import { useAppSelector } from "../../redux/hooks";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";

const ScreenAddAdmin = () => {
  const { admins, isLoading } = useAppSelector((state) => state.admins);
  const { locations } = useAppSelector((state) => state.locations);

  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [district, setDistrict] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [gender, setGender] = useState("");
  const [DOB, setDOB] = useState("");
  const [address, setAddress] = useState("");
  const [contactMain, setContactMain] = useState("");
  const [contactSecon, setContactSecon] = useState("");
  const [email, setEmail] = useState("");
  const [aadharId, setAadharId] = useState("");
  const [managerName, setManagerName] = useState("");
  const [managerAddress, setManagerAddress] = useState("");
  const [contactName, setContactName] = useState("");
  const [noOfMachines, setNoOfMachines] = useState("");
  const [openTime, setOpenTime] = useState("");
  const [closeTime, setCloseTime] = useState("");
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState("Active");

  const navigate = useNavigate();
  const params = useParams();

  const setEditFields = useCallback(
    (id: string) => {
      const admin = admins.find((b) => b._id === id);
      if (admin) {
        setName(admin.branchName);
        setLocation(admin.branchLocation._id);
        setDistrict(admin.branchDistrict);
        setOwnerName(admin.branchOwnerName);
        setGender(admin.gender);
        setDOB(new Date(admin.DOB).toISOString().slice(0, 10));
        setAddress(admin.address);
        setContactMain(admin.contactName);
        setContactSecon(admin.contactName);
        setEmail(admin.emailId);
        setAadharId(admin.aadhaarId);
        setManagerName(admin.branchManagerName);
        setManagerAddress(admin.branchAddress);
        setContactName(admin.contactName);
        setNoOfMachines(admin.noOfMachines.toString());
        setOpenTime(admin.branchOpenTime);
        setCloseTime(admin.branchCloseTime);
        setUserName(admin.username);
        setPassword(admin.password);
        setStatus(admin.status);
      }
    },
    [admins]
  );

  useEffect(() => {
    if (params.aid) {
      setEditFields(params.aid);
    }
  }, [params.aid, setEditFields]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    if (
      !name ||
      !location ||
      !district ||
      !ownerName ||
      !gender ||
      !DOB ||
      !address ||
      !contactMain ||
      !contactSecon ||
      !email ||
      !aadharId ||
      !managerName ||
      !managerAddress ||
      !contactName ||
      !noOfMachines ||
      !openTime ||
      !closeTime ||
      !username ||
      !password
    ) {
      toast("Fill all fields");
      return;
    } else {
      const splittedDate = DOB.split("-");
      const adminData = {
        branchName: name,
        branchLocation: location,
        branchDistrict: district,
        branchOwnerName: ownerName,
        branchManagerName: managerName,
        branchAddress: managerAddress,
        gender: gender,
        DOB: `${splittedDate[2]}-${splittedDate[1]}-${splittedDate[0]}`,
        address: address,
        mainPhone: contactMain,
        secondaryPhone: contactSecon,
        emailId: email,
        aadhaarId: aadharId,
        contactName: contactName,
        noOfMachines: noOfMachines,
        branchOpenTime: openTime,
        branchCloseTime: closeTime,
        username: username,
        password: password,
        status: status,
      };
      const success = params.aid
        ? await editAdmin(params.aid, adminData)
        : await addAdmin(adminData);
      if (success) {
        navigate(KRoutes.admins);
      }
    }
  };
  return (
    <Layout title="admins">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="p-10 overflow-y-scroll overflow-x-hidden scrollbar-hide">
          <div className="flex justify-between">
            <p className="lg:text-3xl text-2xl font-black dark:text-white ">
              {params.aid ? "Edit" : "Add"} Admin
            </p>
            <div>
              <button
                className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800`"
                onClick={() => navigate(-1)}
              >
                <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                  Back
                </span>
              </button>
            </div>
          </div>
          <form className="mt-6 " onSubmit={handleSubmit}>
            <GridView className="gap-6 mb-6 lg:grid-cols-2">
              <>
                <TextField
                  label="Branch Name"
                  id="name"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="text"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="name"
                  value={name}
                  required={true}
                  placeholder="Branch Name"
                  onChange={(e) => setName(e.target.value)}
                />
                <SelectInput
                  data={locations.map((location) => {
                    return {
                      id: location._id,
                      title: location.name,
                    };
                  })}
                  title="Location"
                  value={location}
                  disabled={params.aid ? true : false}
                  onChange={(e) => setLocation(e.target.value)}
                />
                <TextField
                  label="District"
                  id="district"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="text"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="district"
                  value={district}
                  required={true}
                  placeholder="Branch District"
                  onChange={(e) => setDistrict(e.target.value)}
                />
                <TextField
                  label="Owner Name"
                  id="ownerName"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="text"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="ownerName"
                  value={ownerName}
                  required={true}
                  placeholder="Branch Owner Name"
                  onChange={(e) => setOwnerName(e.target.value)}
                />
                <SelectInput
                  data={[
                    {
                      id: "Male",
                      title: "MALE",
                    },
                    {
                      id: "Female",
                      title: "FEMALE",
                    },
                    {
                      id: "OTHERS",
                      title: "OTHERS",
                    },
                  ]}
                  title="Owner Gender"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                />
                <TextField
                  label="Owner DOB"
                  id="DOB"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="date"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="DOB"
                  value={DOB}
                  required={true}
                  placeholder="Branch Owner DOB"
                  onChange={(e) => setDOB(e.target.value)}
                />
                <TextField
                  label="Owner Address"
                  id="address"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="text"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="address"
                  value={address}
                  required={true}
                  placeholder="Branch Owner Address"
                  onChange={(e) => setAddress(e.target.value)}
                />
                <TextField
                  label="Owner Contact Number (Main)"
                  id="contactNumberMain"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="text"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="contactMain"
                  value={contactMain}
                  required={true}
                  pattern="[6789][0-9]{9}"
                  title="Please enter valid phone number"
                  placeholder="Branch Owner Contact Number Main"
                  onChange={(e) => setContactMain(e.target.value)}
                />
                <TextField
                  label="Owner Contact Number Secondary"
                  id="contactSecondary"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="text"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="contactSecondary"
                  value={contactSecon}
                  required={true}
                  pattern="[6789][0-9]{9}"
                  title="Please enter valid phone number"
                  placeholder="Branch Owner Contact Number Secondary"
                  onChange={(e) => setContactSecon(e.target.value)}
                />
                <TextField
                  label="Email"
                  id="email"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="text"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="email"
                  value={email}
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  title="Provide valid email id"
                  required={true}
                  placeholder="EmailId"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  label="Aadhar ID"
                  id="aadharId"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="text"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="aadharId"
                  value={aadharId}
                  pattern="[0-9]{12}"
                  required={true}
                  placeholder="Owner Aadhar ID"
                  onChange={(e) => setAadharId(e.target.value)}
                />
                <TextField
                  label="Manager Name"
                  id="managerName"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="text"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="managerName"
                  value={managerName}
                  required={true}
                  placeholder="Branch Manager Name"
                  onChange={(e) => setManagerName(e.target.value)}
                />
                <TextField
                  label="Manager Address"
                  id="managerAddress"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="text"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="managerAddress"
                  value={managerAddress}
                  required={true}
                  placeholder="Branch Manager Address"
                  onChange={(e) => setManagerAddress(e.target.value)}
                />
                <TextField
                  label="Manager ContactName"
                  id="managerContactName"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="text"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="managerContactName"
                  value={contactName}
                  required={true}
                  placeholder="Branch Manager ContactName"
                  onChange={(e) => setContactName(e.target.value)}
                />
                <TextField
                  label="No. Of Machines"
                  id="noOfMacines"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="text"
                  pattern="^(0|[1-9][0-9]*)$"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="noOfMacines"
                  value={noOfMachines}
                  required={true}
                  placeholder="No. Of Machines"
                  onChange={(e) => setNoOfMachines(e.target.value)}
                />
                <TextField
                  label="Open Time"
                  id="openTime"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="time"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="openTime"
                  value={openTime}
                  required={true}
                  placeholder="Branch Open Time"
                  onChange={(e) => setOpenTime(e.target.value)}
                />
                <TextField
                  label="Close Time"
                  id="closeTime"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="time"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="closeTime"
                  value={closeTime}
                  required={true}
                  placeholder="Branch Close Time"
                  onChange={(e) => setCloseTime(e.target.value)}
                />
                <TextField
                  label="Username"
                  id="userId"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="text"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="userId"
                  value={username}
                  required={true}
                  pattern="[A-Za-z0-9\-_\.]{6,20}"
                  title="
                  A-Z any uppercase char, 
                  a-z any lowercase char, 
                  0-9 any number, 
                  \- hyphen (escaped by '\' depending on what order it is put)
                  . period"
                  placeholder="Branch Username"
                  onChange={(e) => setUserName(e.target.value)}
                />
                <TextField
                  label="Password"
                  id="password"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="text"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="password"
                  value={password}
                  required={true}
                  pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,24}$"
                  title="
                  At least one lower character, 
                  At least one upper character, 
                  At least one numeric value,
                  At least one special symbol, 
                  Password length should be in between 8 to 24"
                  placeholder="Branch Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <SelectInput
                  data={[
                    {
                      id: "Active",
                      title: "Active",
                    },
                    {
                      id: "Inactive",
                      title: "Inactive",
                    },
                  ]}
                  title="Status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                />
              </>
            </GridView>
            <div className="flex items-center justify-center -mx-3 my-6">
              <div className="w-full lg:w-1/2 px-3">
                <button
                  type="submit"
                  className="w-full active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-3 bg-primary-color text-white  text-lg font-bold rounded-xl"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </Layout>
  );
};

export default ScreenAddAdmin;
