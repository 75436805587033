import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICustomer } from "../types/customer";

interface ICustomerState {
  customers: ICustomer[];
  deletedCustomers: ICustomer[];
  isLoading: boolean;
}

const customerInitState: ICustomerState = {
  customers: [],
  deletedCustomers: [],
  isLoading: false,
};

const customerSlice = createSlice({
  name: "customers",
  initialState: customerInitState,
  reducers: {
    changeCustomerLoading: (
      state: ICustomerState,
      action: PayloadAction<boolean>
    ) => {
      if (state.isLoading !== action.payload) {
        state.isLoading = action.payload;
      }
    },
    setCustomersState: (
      state: ICustomerState,
      action: PayloadAction<{ customers: ICustomer[]; refresh?: boolean }>
    ) => {
      if (state.customers.length <= 0 || action.payload.refresh) {
        state.customers = action.payload.customers || state.customers;
      }
      state.isLoading = false;
    },
    setDeletedCustomerState: (
      state: ICustomerState,
      action: PayloadAction<{ customers: ICustomer[]; refresh?: boolean }>
    ) => {
      if (state.deletedCustomers.length <= 0 || action.payload.refresh) {
        state.deletedCustomers =
          action.payload.customers || state.deletedCustomers;
      }
      state.isLoading = false;
    },
    addCustomerState: (state: ICustomerState, action: PayloadAction<ICustomer>) => {
      state.customers.push(action.payload);
      state.isLoading = false;
    },
    editCustomerState: (
      state: ICustomerState,
      action: PayloadAction<ICustomer>
    ) => {
      state.customers = state.customers.map((customer) => {
        if (action.payload._id === customer._id) {
          customer = action.payload;
        }
        return customer;
      });
      state.isLoading = false;
    },
    changeCustomerStatusState: (
      state: ICustomerState,
      action: PayloadAction<ICustomer>
    ) => {
      state.customers = state.customers.map((customer) => {
        if (
          action.payload._id === customer._id &&
          action.payload.status !== customer.status
        ) {
          customer.status = action.payload.status;
        }
        return customer;
      });
      state.isLoading = false;
    },
    deleteCustomerState: (
      state: ICustomerState,
      action: PayloadAction<ICustomer["_id"]>
    ) => {
      state.customers = state.customers.filter(
        (customer) => customer._id !== action.payload
      );
      state.isLoading = false;
    },
    pDeleteCustomerState: (
      state: ICustomerState,
      action: PayloadAction<ICustomer["_id"]>
    ) => {
      state.deletedCustomers = state.deletedCustomers.filter(
        (customer) => customer._id !== action.payload
      );
      state.isLoading = false;
    },
    restoreCustomerState: (
      state: ICustomerState,
      action: PayloadAction<ICustomer>
    ) => {
      state.customers.push(action.payload);
      state.isLoading = false;
    },
    clearCustomerState: (state: ICustomerState) => {
      state = customerInitState;
    },
  },
});

export const {
  changeCustomerLoading,
  setCustomersState,
  setDeletedCustomerState,
  addCustomerState,
  editCustomerState,
  changeCustomerStatusState,
  deleteCustomerState,
  pDeleteCustomerState,
  restoreCustomerState,
  clearCustomerState,
} = customerSlice.actions;

export default customerSlice.reducer;
