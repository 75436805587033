export const APP_NAME = "KwikNkleen";
export const autoRefreshDelay = 20;

const FIREBASE_API_KEY = process.env.REACT_FIREBASE_API_KEY || "FIREBASE_API_KEY";
const FIREBASE_AUTH_DOMAIN =
  process.env.REACT_FIREBASE_AUTH_DOMAIN || "FIREBASE_AUTH_DOMAIN";
const FIREBASE_PROJECT_ID =
  process.env.REACT_FIREBASE_PROJECT_ID || "FIREBASE_PROJECT_ID";
const FIREBASE_STORAGE_BUCKET =
  process.env.REACT_FIREBASE_STORAGE_BUCKET || "FIREBASE_STORAGE_BUCKET";
const FIREBASE_SENDER_ID =
  process.env.REACT_FIREBASE_SENDER_ID || "FIREBASE_SENDER_ID";
const FIREBASE_APP_ID = process.env.REACT_FIREBASE_APP_ID || "FIREBASE_APP_ID";
const FIREBASE_MEASUREMENT_ID =
  process.env.REACT_FIREBASE_MEASUREMENT_ID || "FIREBASE_MEASUREMENT_ID";
const FIREBASE_VAPID_KEY =
  process.env.REACT_FIREBASE_VAPID_KEY || "FIREBASE_VAPID_KEY";

export const FIREBASE = {
  FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_SENDER_ID,
  FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID,
  FIREBASE_VAPID_KEY,
};
