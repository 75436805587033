import { Column } from "react-table";
import { SelectColumnFilter } from "../../../components/Table";
import { ButtonsPill, DateFromatPill } from "../../../components/TableCells";
import store from "../../../redux/store";
import { KRoutes } from "../../../routers/router";
import { deleteServicePlansPriceList } from "../../../services/services/plansPriceList";
import { IServicePlansPriceList } from "../../../types/services";

const servicePlansPriceListTableHeaders: Column<IServicePlansPriceList>[] = [
  {
    id: "code",
    Header: "Code",
    accessor: (c) => c.code,
  },
  {
    id: "plan",
    Header: "Plan",
    accessor: (c) => c.plan?.name || "No Plan",
  },
  {
    id: "category",
    Header: "Category",
    accessor: (c) => c.category?.name || "No Category",
    Filter: SelectColumnFilter, // new
    filter: "includes",
  },
  {
    id: "model",
    Header: "Model",
    accessor: (m) => m.model?.name || "No Model",
    Filter: SelectColumnFilter, // new
    filter: "includes",
  },
  {
    id: "type",
    Header: "Type",
    accessor: (m) => m.type?.name || "No Type",
    Filter: SelectColumnFilter, // new
    filter: "includes",
  },
  {
    id: "calculated-price",
    Header: "Calculated Price",
    accessor: "calculatedPrice",
  },
  {
    id: "calculated-duration",
    Header: "Calculated Duration",
    accessor: "calculatedDuration",
  },
  {
    id: "actual-price",
    Header: "Actual Price",
    accessor: "price",
  },
  {
    id: "actual-duration",
    Header: "Actual Duration",
    accessor: "duration",
  },
  {
    Header: "CreatedAt",
    accessor: "createdAt",
    Cell: DateFromatPill,
  },
  {
    Header: "UpdatedAt",
    accessor: "updatedAt",
    Cell: DateFromatPill,
  },
  {
    Header: "Action",
    accessor: (p) => ({ id: p._id, available: p.available }),
    Cell: ({ value }: { value: { id: string; available: boolean } }) => {
       return ButtonsPill({
        statusChangeButton: false,
        visibilityChangeButton: false,
        editButton: true,
        deleteButton: ["MasterAdmin", "Developer"].includes(store.getState().admins.profile?.role || "")
        ? true
        : false,        editPath: KRoutes.editServicePlansPriceList + "/" + value.id,
        editButtonTitle: value.available ? "Edit" : "Add",
        onClickDelete: () => deleteServicePlansPriceList(value.id),
      });
    },
  },
];

export default servicePlansPriceListTableHeaders;
