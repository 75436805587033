import httpClient from "../../core/axios";
import ApiEndpoints from "../../core/apiEndpoints";
import store, { dispatch } from "../../redux/store";
import { setErrorMessage, setSuccessMessage } from "../../redux/notification";
import {
  changeServiceLoading,
  setServicesState,
  addServiceState,
  editServiceState,
  changeServiceStatusState,
  deleteServiceState,
  setDeletedServicesState,
  restoreServiceState,
  pDeleteServiceState,
} from "../../redux/services";

/**
 * To get a services from server
 */
export const getServices = async (refresh?: boolean) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeServiceLoading(true));

    // API Fetching Deleted
    if (store.getState().admins.profile?.role === "MasterAdmin")
      getDeletedServices(refresh);

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.service);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setServicesState({ services: resp.data.data, refresh }));
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceLoading(false));
  }
};

/**
 * To get a deleted services from server
 */
export const getDeletedServices = async (refresh?: boolean) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeServiceLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.deletedService);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setDeletedServicesState({ services: resp.data.data, refresh }));
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceLoading(false));
  }
};

/**
 * To add a new service
 */
export const addService = async (serviceData: any) => {
  try {
    // Set Loading
    dispatch(changeServiceLoading(true));

    // API Fetching
    const resp = await httpClient().post(
      ApiEndpoints.service,
      serviceData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(addServiceState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceLoading(false));
  }
};

/**
 * To add a new service
 */
export const editService = async (id: string, serviceData: any) => {
  try {
    // Set Loading
    dispatch(changeServiceLoading(true));

    // API Fetching
    const resp = await httpClient().patch(
      ApiEndpoints.service + "/" + id,
      serviceData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(editServiceState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceLoading(false));
  }
};

/**
 * To change the status
 */
export const changeServiceStatus = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeServiceLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.changeServiceStatus + id);
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeServiceStatusState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceLoading(false));
  }
};

/**
 * To delete the
 */
export const deleteService = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeServiceLoading(true));

    // API Fetching
    const resp = await httpClient().delete(ApiEndpoints.service + "/" + id);
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(deleteServiceState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceLoading(false));
  }
};

/**
 * To restore the service
 */
export const restoreService = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeServiceLoading(true));

    // API Fetching
    const resp = await httpClient().delete(ApiEndpoints.restoreService + id);
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(restoreServiceState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceLoading(false));
  }
};

/**
 * To delete the service permanently
 */
export const pDeleteService = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeServiceLoading(true));

    // API Fetching
    const resp = await httpClient().delete(ApiEndpoints.deleteService + id);
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(pDeleteServiceState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceLoading(false));
  }
};
