import React, { useEffect, useMemo } from "react";
import { useAppSelector } from "../../redux/hooks";
import Table from "../../components/Table";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import holidaysTableHeaders from "./holidaysTableHeaders";
import { useNavigate } from "react-router-dom";
import { KRoutes } from "../../routers/router";
import { getHolidays } from "../../services/holidays/holiday";
import { autoRefreshDelay } from "../../core/constants";

const ScreenHolidays = () => {
  const { holidays, isLoading } = useAppSelector((state) => state.holidays);
  const columns = useMemo(() => holidaysTableHeaders, []);
  const data = useMemo(() => holidays, [holidays]);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      getHolidays()
    }, 0);
    const refreshInterval = setInterval(
      () => getHolidays(true),
      autoRefreshDelay * 1000
    );
    return () => {
      clearInterval(refreshInterval);
    };
  }, []);

  return (
    <Layout title="holidays">
      {(isLoading && holidays.length <= 0) ? (
        <Loader />
      ) : (
        <Table
          columns={columns}
          data={data}
          tableTitle={"Holidays"}
          buttons={{
            addButton: true,
            addButtonTitle: "Add Holiday",
            exportButton: false,
            onClickAddButton: () => navigate(KRoutes.addHoliday),

          }}
        />
      )}
    </Layout>
  );
};

export default ScreenHolidays;
