import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISalesBanner } from "../../types/sales";

interface ISalesBannerState {
  banners: ISalesBanner[];
  deletedBanners: ISalesBanner[];
  isLoading: boolean;
}

const salesBannerInitState: ISalesBannerState = {
  banners: [],
  isLoading: false,
  deletedBanners: [],
};

const salesBannerSlice = createSlice({
  name: "sales-banner",
  initialState: salesBannerInitState,
  reducers: {
    changeSalesBannerLoading: (
      state: ISalesBannerState,
      action: PayloadAction<boolean>
    ) => {
      if (state.isLoading !== action.payload) {
        state.isLoading = action.payload || state.isLoading;
      }
    },
    setSalesBannersState: (
      state: ISalesBannerState,
      action: PayloadAction<{ banners: ISalesBanner[]; refresh?: boolean }>
    ) => {
      if (state.banners.length <= 0 || action.payload.refresh) {
        state.banners = action.payload.banners || state.banners;
      }
      state.isLoading = false;
    },
    setSalesDeletedBannersState: (
      state: ISalesBannerState,
      action: PayloadAction<{ banners: ISalesBanner[]; refresh?: boolean }>
    ) => {
      if (state.deletedBanners.length <= 0 || action.payload.refresh) {
        state.deletedBanners = action.payload.banners || state.deletedBanners;
      }
      state.isLoading = false;
    },
    addSalesBannerState: (
      state: ISalesBannerState,
      action: PayloadAction<ISalesBanner>
    ) => {
      state.banners.push(action.payload);
      state.isLoading = false;
    },
    editSalesBannerState: (
      state: ISalesBannerState,
      action: PayloadAction<ISalesBanner>
    ) => {
      state.banners = state.banners.map((banner) => {
        if (action.payload._id === banner._id) {
          banner = action.payload;
        }
        return banner;
      });
      state.isLoading = false;
    },
    changeSalesBannerStatusState: (
      state: ISalesBannerState,
      action: PayloadAction<ISalesBanner>
    ) => {
      state.banners = state.banners.map((banner) => {
        if (
          action.payload._id === banner._id &&
          action.payload.status !== banner.status
        ) {
          banner.status = action.payload.status;
        }
        return banner;
      });
      state.isLoading = false;
    },
    changeSalesBannerVisibilityState: (
      state: ISalesBannerState,
      action: PayloadAction<ISalesBanner>
    ) => {
      state.banners = state.banners.map((banner) => {
        if (
          action.payload._id === banner._id &&
          action.payload.visibility !== banner.visibility
        ) {
          banner.visibility = action.payload.visibility;
        }
        return banner;
      });
      state.isLoading = false;
    },
    deleteSalesBannerState: (
      state: ISalesBannerState,
      action: PayloadAction<ISalesBanner["_id"]>
    ) => {
      state.banners = state.banners.filter(
        (banner) => banner._id !== action.payload
      );
      state.isLoading = false;
    },
    pDeleteSalesBannerState: (
      state: ISalesBannerState,
      action: PayloadAction<ISalesBanner["_id"]>
    ) => {
      state.deletedBanners = state.deletedBanners.filter(
        (banner) => banner._id !== action.payload
      );
      state.isLoading = false;
    },
    restoreSalesBannerState: (
      state: ISalesBannerState,
      action: PayloadAction<ISalesBanner>
    ) => {
      state.banners.push(action.payload);
      state.isLoading = false;
    },
    clearSalesBannerState: (state: ISalesBannerState) => {
      state = salesBannerInitState;
    },
  },
});

export const {
  changeSalesBannerLoading,
  setSalesDeletedBannersState,
  setSalesBannersState,
  addSalesBannerState,
  editSalesBannerState,
  changeSalesBannerStatusState,
  changeSalesBannerVisibilityState,
  deleteSalesBannerState,
  pDeleteSalesBannerState,
  restoreSalesBannerState,
  clearSalesBannerState,
} = salesBannerSlice.actions;

export default salesBannerSlice.reducer;
