import httpClient from "../../core/axios";
import ApiEndpoints from "../../core/apiEndpoints";
import store, { dispatch } from "../../redux/store";
import { setErrorMessage, setSuccessMessage } from "../../redux/notification";
import {
  changeAdminLoading,
  setAdminsState,
  addAdminState,
  changeAdminStatusState,
  deleteAdminState,
  editAdminState,
  setDeletedAdminState,
  setAdminDetails,
  restoreAdminState,
  pDeleteAdminState,
  setAdminLoggedIn,
  adminLogoutState,
} from "../../redux/admin";

/**
 * To get a  admins from server
 */
export const getAdmins = async (refresh?: boolean) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeAdminLoading(true));

    // API Fetching Deleted
    if (store.getState().admins.profile?.role === "MasterAdmin")
      getDeletedAdmins(refresh);

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.admin);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setAdminsState({ admins: resp.data.data, refresh }));
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAdminLoading(false));
  }
};

/**
 * To admin from server
 */
export const getAdminProfile = async () => {
  try {
    // Set Loading
    dispatch(changeAdminLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.adminProfile);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setAdminDetails(resp.data.data));
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAdminLoading(false));
  }
};

/**
 * To get a  deleted admins from server
 */
export const getDeletedAdmins = async (refresh?: boolean) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeAdminLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.deletedAdmin);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setDeletedAdminState({ admins: resp.data.data, refresh }));
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAdminLoading(false));
  }
};

/**
 * To login a admin from server api
 */
export const adminLogin = async (data: {
  username?: string;
  emailId?: string;
  password: string;
}) => {
  try {
    // Set Loading
    dispatch(changeAdminLoading(true));

    // API Fetching
    const resp = await httpClient().post(ApiEndpoints.adminLogin, data);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setAdminLoggedIn(resp.data.data.token));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    dispatch(setErrorMessage(error.message));
    dispatch(changeAdminLoading(false));
  }
};

/**
 * To add a new  admin
 */
export const addAdmin = async (adminData: any) => {
  try {
    // Set Loading
    dispatch(changeAdminLoading(true));

    // API Fetching
    const resp = await httpClient().post(ApiEndpoints.admin, adminData);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(addAdminState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAdminLoading(false));
  }
};

/**
 * To add a new  admin
 */
export const editAdmin = async (id: string, adminData: any) => {
  try {
    // Set Loading
    dispatch(changeAdminLoading(true));

    // API Fetching
    const resp = await httpClient().patch(
      ApiEndpoints.admin + "/" + id,
      adminData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(editAdminState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAdminLoading(false));
  }
};

/**
 * To change the status
 */
export const changeAdminStatus = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeAdminLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.changeAdminStatus + id);
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeAdminStatusState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAdminLoading(false));
  }
};

/**
 * To delete the admin
 */
export const deleteAdmin = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeAdminLoading(true));

    // API Fetching
    const resp = await httpClient().delete(ApiEndpoints.admin + "/" + id);
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(deleteAdminState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAdminLoading(false));
  }
};

/**
 * To restore the admin
 */
export const restoreAdmin = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeAdminLoading(true));

    // API Fetching
    const resp = await httpClient().delete(ApiEndpoints.restoreAdmin + id);
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(restoreAdminState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAdminLoading(false));
  }
};

/**
 * To delete the admin permanently
 */
export const pDeleteAdmin = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeAdminLoading(true));

    // API Fetching
    const resp = await httpClient().delete(ApiEndpoints.deleteAdmin + id);
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(pDeleteAdminState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAdminLoading(false));
  }
};

/**
 * To delete the admin permanently
 */
 export const logoutAdmin = async () => {
  try {
    // Set Loading
    dispatch(changeAdminLoading(true));

    // Update to Store
    dispatch(adminLogoutState());
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAdminLoading(false));
  }
};
