import httpClient from "../../core/axios";
import ApiEndpoints from "../../core/apiEndpoints";
import store, { dispatch } from "../../redux/store";
import { setErrorMessage, setSuccessMessage } from "../../redux/notification";
import {
  changeLocationLoading,
  setLocationsState,
  setDeletedLocationsState,
  addLocationState,
  changeLocationStatusState,
  deleteLocationState,
  editLocationState,
  restoreLocationState,
  pDeleteLocationState,
} from "../../redux/location";

/**
 * To get a  locations from server
 */
export const getLocations = async (refresh?: boolean) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeLocationLoading(true));

    // API Fetching Deleted
    if (store.getState().admins.profile?.role === "MasterAdmin")
      getDeletedLocations(refresh);

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.location);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setLocationsState({ locations: resp.data.data, refresh }));
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeLocationLoading(false));
  }
};

/**
 * To get a deleted locations from server
 */
export const getDeletedLocations = async (refresh?: boolean) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeLocationLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.deletedLocation);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setDeletedLocationsState({ locations: resp.data.data, refresh }));
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeLocationLoading(false));
  }
};

/**
 * To add a new  location
 */
export const addLocation = async (locationData: any) => {
  try {
    // Set Loading
    dispatch(changeLocationLoading(true));

    // API Fetching
    const resp = await httpClient().post(
      ApiEndpoints.location,
      locationData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(addLocationState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeLocationLoading(false));
  }
};

/**
 * To add a new  location
 */
export const editLocation = async (id: string, locationData: any) => {
  try {
    // Set Loading
    dispatch(changeLocationLoading(true));

    // API Fetching
    const resp = await httpClient().patch(
      ApiEndpoints.location + "/" + id,
      locationData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(editLocationState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeLocationLoading(false));
  }
};

/**
 * To change the status
 */
export const changeLocationStatus = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeLocationLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.changeLocationStatus + id);
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeLocationStatusState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeLocationLoading(false));
  }
};

/**
 * To delete the location
 */
export const deleteLocation = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeLocationLoading(true));

    // API Fetching
    const resp = await httpClient().delete(ApiEndpoints.location + "/" + id);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(deleteLocationState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeLocationLoading(false));
  }
};

/**
 * To restore the location
 */
export const restoreLocation = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeLocationLoading(true));

    // API Fetching
    const resp = await httpClient().delete(ApiEndpoints.restoreLocation + id);
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(restoreLocationState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeLocationLoading(false));
  }
};

/**
 * To delete the location permanently
 */
export const pDeleteLocation = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeLocationLoading(true));

    // API Fetching
    const resp = await httpClient().delete(ApiEndpoints.deleteLocation + id);
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(pDeleteLocationState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeLocationLoading(false));
  }
};
