import { Navigate } from "react-router-dom";
import { LocalStorageKeys } from "../core/localStorageKeys";

const PrivateRoute = ({ component, ...rest }: any) => {
  const adminToken = localStorage.getItem(LocalStorageKeys.adminToken);

  return !adminToken ? <Navigate to="/login" /> : component;
};

export default PrivateRoute;
