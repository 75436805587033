import React from "react";
import { toast } from "react-toastify";
import Layout from "../../../components/Layout";
import Icons from "../../../utils/icons";

const ScreenSalesOrders = () => {
  return (
    <Layout title="sales-orders">
      <div className="p-10 overflow-y-scroll overflow-x-hidden scrollbar-hide min-h-full min-w-full">
        <div className="flex justify-between">
          <p className="text-3xl font-black dark:text-white ">Sales Orders</p>
          <div>
            <button
              className={`relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800`}
              onClick={() => toast("Feature not available on this version!")}
            >
              <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                {"Add Order"}
              </span>
            </button>
          </div>
        </div>{" "}
        <div className="mt-36 flex justify-center items-center w-full h-full">
          <div className="flex flex-col items-center justify-center">
            <img src={Icons.noData} height={80} width={80} alt="empty" />
            <h1 className="mt-2 text-base text-primary-color font-medium ">
              No Data Found
            </h1>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ScreenSalesOrders;
