import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IServiceCategory } from "../../types/services";

interface IServiceCategoryState {
  categories: IServiceCategory[];
  deletedCategorys: IServiceCategory[];
  isLoading: boolean;
}

const serviceCategoryInitState: IServiceCategoryState = {
  categories: [],
  deletedCategorys: [],
  isLoading: false,
};

const serviceCategorySlice = createSlice({
  name: "service-category",
  initialState: serviceCategoryInitState,
  reducers: {
    changeServiceCategoryLoading: (
      state: IServiceCategoryState,
      action: PayloadAction<boolean>
    ) => {
      if (state.isLoading !== action.payload) {
        state.isLoading = action.payload;
      }
    },
    setServiceCategoriesState: (
      state: IServiceCategoryState,
      action: PayloadAction<{
        categories: IServiceCategory[];
        refresh?: boolean;
      }>
    ) => {
      if (state.categories.length <= 0 || action.payload.categories) {
        state.categories = action.payload.categories || state.categories;
      }
      state.isLoading = false;
    },
    setServiceDeletedCategoriesState: (
      state: IServiceCategoryState,
      action: PayloadAction<{
        categories: IServiceCategory[];
        refresh?: boolean;
      }>
    ) => {
      if (state.deletedCategorys.length <= 0 || action.payload.categories) {
        state.deletedCategorys = action.payload.categories || state.deletedCategorys;
      }
      state.isLoading = false;
    },
    addServiceCategoryState: (
      state: IServiceCategoryState,
      action: PayloadAction<IServiceCategory>
    ) => {
      state.categories.unshift(action.payload);
      state.isLoading = false;
    },
    editServiceCategoryState: (
      state: IServiceCategoryState,
      action: PayloadAction<IServiceCategory>
    ) => {
      state.categories = state.categories.map((category) => {
        if (action.payload._id === category._id) {
          category = action.payload;
        }
        return category;
      });
      state.isLoading = false;
    },
    changeServiceCategoryStatusState: (
      state: IServiceCategoryState,
      action: PayloadAction<IServiceCategory>
    ) => {
      state.categories = state.categories.map((category) => {
        if (
          action.payload._id === category._id &&
          action.payload.status !== category.status
        ) {
          category.status = action.payload.status;
        }
        return category;
      });
      state.isLoading = false;
    },
    changeServiceCategoryVisibilityState: (
      state: IServiceCategoryState,
      action: PayloadAction<IServiceCategory>
    ) => {
      state.categories = state.categories.map((category) => {
        if (
          action.payload._id === category._id &&
          action.payload.visibility !== category.visibility
        ) {
          category.visibility = action.payload.visibility;
        }
        return category;
      });
      state.isLoading = false;
    },
    deleteServiceCategoryState: (
      state: IServiceCategoryState,
      action: PayloadAction<IServiceCategory["_id"]>
    ) => {
      state.categories = state.categories.filter(
        (category) => category._id !== action.payload
      );
      state.isLoading = false;
    },
    pDeleteServiceCategoryState: (
      state: IServiceCategoryState,
      action: PayloadAction<IServiceCategory["_id"]>
    ) => {
      state.deletedCategorys = state.deletedCategorys.filter(
        (category) => category._id !== action.payload
      );
      state.isLoading = false;
    },
    restoreServiceCategoryState: (
      state: IServiceCategoryState,
      action: PayloadAction<IServiceCategory>
    ) => {
      state.categories.unshift(action.payload);
      state.isLoading = false;
    },
    clearServiceCategoryState: (state: IServiceCategoryState) => {
      state = serviceCategoryInitState;
    },
  },
});

export const {
  changeServiceCategoryLoading,
  setServiceCategoriesState,
  setServiceDeletedCategoriesState,
  addServiceCategoryState,
  editServiceCategoryState,
  changeServiceCategoryStatusState,
  changeServiceCategoryVisibilityState,
  deleteServiceCategoryState,
  pDeleteServiceCategoryState,
  restoreServiceCategoryState,
  clearServiceCategoryState,
} = serviceCategorySlice.actions;

export default serviceCategorySlice.reducer;
