import React from "react";
import Layout from "../../components/Layout";
import Icons from "../../utils/icons";

const ScreenProfile = () => {
  return (
    <Layout title="profile">
      <div className="p-10 overflow-y-scroll overflow-x-hidden scrollbar-hide min-h-full min-w-full">
        <div className="flex justify-between">
          <p className="text-3xl font-black dark:text-white ">Admin Profile</p>
        </div>
        <div className="mt-36 flex justify-center items-center w-full h-full">
          <div className="flex flex-col items-center justify-center">
            <img src={Icons.noData} height={80} width={80} alt="empty" />
            <h1 className="mt-2 text-base text-primary-color font-medium ">
              Edit
            </h1>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ScreenProfile;
