import React, { useCallback, useEffect, useState } from "react";
import {
  TextField,
  GridView,
  SelectInput,
} from "../../../components/TextFieldItems";
import Layout from "../../../components/Layout";
import { useAppSelector } from "../../../redux/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { KRoutes } from "../../../routers/router";
import Loader from "../../../components/Loader";
import { addService, editService } from "../../../services/services/service";

const ScreenAddService = () => {
  const { categories } = useAppSelector((state) => state.serviceCategories);
  const { services, isLoading } = useAppSelector((state) => state.services);
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [duration, setDuration] = useState("0");
  const [price, setPrice] = useState("0");
  const [status, setStatus] = useState("Active");
  const navigate = useNavigate();
  const params = useParams();

  const setEditFields = useCallback(
    (id: string) => {
      const service = services.find((s) => s._id === id);
      if (service) {
        setName(service.name);
        setCategory(service.category._id);
        setDuration(service.duration.toString());
        setPrice(service.price);
        setStatus(service.status);
      }
    },
    [services]
  );

  useEffect(() => {
    if (params.sid) {
      setEditFields(params.sid);
    }
  }, [params.sid, setEditFields]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    if (!name || !category || !duration || !price) {
      toast("Fill name, category, price and duration");
      return;
    } else {
      const serviceData = { name, category, price, duration, status };
      const success = params.sid
        ? await editService(params.sid, serviceData)
        : await addService(serviceData);
      if (success) {
        navigate(KRoutes.services);
      }
    }
  };
  return (
    <Layout title="services">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="p-10 overflow-y-scroll overflow-x-hidden scrollbar-hide">
          <div className="flex justify-between">
            <p className="lg:text-3xl text-2xl font-black dark:text-white ">
              {params.sid ? "Edit" : "Add"} Service
            </p>
            <div>
              <button
                className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800`"
                onClick={() => navigate(-1)}
              >
                <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                  Back
                </span>
              </button>
            </div>
          </div>
          <form className="mt-6 " onSubmit={handleSubmit}>
            <GridView className="gap-6 mb-6 lg:grid-cols-2">
              <>
                <TextField
                  label="Name"
                  id="name"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="text"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="name"
                  value={name}
                  required={true}
                  placeholder="Service Name"
                  onChange={(e) => setName(e.target.value)}
                />
                <SelectInput
                  data={categories.map((category) => {
                    return {
                      id: category._id,
                      title: category.name,
                    };
                  })}
                  title="Category"
                  value={category}
                  disabled={params.sid ? true : false}
                  onChange={(e) => setCategory(e.target.value)}
                />
                <div className="flex gap-6 w-full">
                  <TextField
                    label="Price"
                    className="mb-5 w-1/2"
                    id="price"
                    labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    type="text"
                    inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    name="price"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    title="Must contain number or decimal values Eg:1.00"
                    value={price}
                    required={true}
                    placeholder="Price"
                    onChange={(e) => setPrice(e.target.value)}
                  />
                  <TextField
                    label="Duration"
                    className="w-1/2"
                    id="duration"
                    labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    type="text"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    title="Must contain number Eg:10 (min)"
                    inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    name="duration"
                    value={duration}
                    required={true}
                    placeholder="Service Duration (Minutes)"
                    onChange={(e) => setDuration(e.target.value)}
                  />
                </div>

                <SelectInput
                  data={[
                    {
                      id: "Active",
                      title: "Active",
                    },
                    {
                      id: "Inactive",
                      title: "Inactive",
                    },
                  ]}
                  title="Status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                />
              </>
            </GridView>
            <div className="flex items-center justify-center -mx-3 my-6">
              <div className="w-full lg:w-1/2 px-3">
                <button
                  type="submit"
                  className="w-full active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-3 bg-primary-color text-white  text-lg font-bold rounded-xl"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </Layout>
  );
};

export default ScreenAddService;
