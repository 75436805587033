import httpClient from "../../core/axios";
import ApiEndpoints from "../../core/apiEndpoints";
import store, { dispatch } from "../../redux/store";
import { setErrorMessage, setSuccessMessage } from "../../redux/notification";
import {
  changeServicePlanLoading,
  setServicePlansState,
  addServicePlanState,
  changeServicePlanStatusState,
  changeServicePlanVisibilityState,
  deleteServicePlanState,
  editServicePlanState,
  setServiceDeletedPlansState,
  restoreServicePlanState,
  pDeleteServicePlanState,
} from "../../redux/services";

/**
 * To get a service plans from server
 */
export const getServicePlans = async (refresh?: boolean) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeServicePlanLoading(true));

    // API Fetching Deleted
    if (store.getState().admins.profile?.role === "MasterAdmin")
      getDeletedServicePlans(refresh);

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.servicePlan);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setServicePlansState({ plans: resp.data.data, refresh }));
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServicePlanLoading(false));
  }
};

/**
 * To get a deleted plan from server
 */
export const getDeletedServicePlans = async (refresh?: boolean) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeServicePlanLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.deletedServicePlan);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setServiceDeletedPlansState({ plans: resp.data.data, refresh }));
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServicePlanLoading(false));
  }
};

/**
 * To add a new service plan
 */
export const addServicePlan = async (planForm: FormData) => {
  try {
    // Set Loading
    dispatch(changeServicePlanLoading(true));

    // API Fetching
    const resp = await httpClient("multipart/form-data").post(
      ApiEndpoints.servicePlan,
      planForm
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(addServicePlanState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServicePlanLoading(false));
  }
};

/**
 * To add a new service plan
 */
export const editServicePlan = async (id: string, planForm: FormData) => {
  try {
    // Set Loading
    dispatch(changeServicePlanLoading(true));

    // API Fetching
    const resp = await httpClient("multipart/form-data").patch(
      ApiEndpoints.servicePlan + "/" + id,
      planForm
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(editServicePlanState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServicePlanLoading(false));
  }
};

/**
 * To change the status
 */
export const changeServicePlanStatus = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeServicePlanLoading(true));

    // API Fetching
    const resp = await httpClient().get(
      ApiEndpoints.changeServicePlanStatus + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeServicePlanStatusState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServicePlanLoading(false));
  }
};

/**
 * To change the visibility
 */
export const changeServicePlanVisibility = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeServicePlanLoading(true));

    // API Fetching
    const resp = await httpClient().get(
      ApiEndpoints.changeServicePlanVisibility + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeServicePlanVisibilityState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServicePlanLoading(false));
  }
};

/**
 * To delete the plan
 */
export const deleteServicePlan = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeServicePlanLoading(true));

    // API Fetching
    const resp = await httpClient().delete(ApiEndpoints.servicePlan + "/" + id);
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(deleteServicePlanState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServicePlanLoading(false));
  }
};

/**
 * To restore the service plan
 */
export const restoreServicePlan = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeServicePlanLoading(true));

    // API Fetching
    const resp = await httpClient().delete(
      ApiEndpoints.restoreServicePlan + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(restoreServicePlanState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServicePlanLoading(false));
  }
};

/**
 * To delete the service plan permanently
 */
export const pDeleteServicePlan = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeServicePlanLoading(true));

    // API Fetching
    const resp = await httpClient().delete(ApiEndpoints.deleteServicePlan + id);
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(pDeleteServicePlanState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServicePlanLoading(false));
  }
};
