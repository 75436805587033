import { Column } from "react-table";
import { SelectColumnFilter } from "../../components/Table";
import { ButtonsPill, DateFromatPill, StatusPill } from "../../components/TableCells";
import store from "../../redux/store";
import { KRoutes } from "../../routers/router";
import { changeLocationStatus, deleteLocation } from "../../services/locations/location";
import { ILocation } from "../../types/default";

const locationsTableHeaders: Column<ILocation>[] = [
  {
    id: "code",
    Header: "CODE",
    accessor: "code",
  },
  {
    id: "name",
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: StatusPill,
    Filter: SelectColumnFilter, // new
    filter: "includes",
  },
  {
    Header: "CreatedAt",
    accessor: "createdAt",
    Cell: DateFromatPill,
  },
  {
    Header: "UpdatedAt",
    accessor: "updatedAt",
    Cell: DateFromatPill,
  },
  {
    Header: "Action",
    accessor: "_id",
    Cell: ({ value }) => {
      return ButtonsPill({
        statusChangeButton: true,
        visibilityChangeButton: false,
        editButton: true,
         deleteButton: ["MasterAdmin", "Developer"].includes(store.getState().admins.profile?.role || "")
          ? true
          : false,
        editPath: KRoutes.editLocation + '/' + value,
        onClickStatusChange: () => changeLocationStatus(value),
        onClickDelete: () => deleteLocation(value)
      });
    },
  },
];

export default locationsTableHeaders;
