import React, { useEffect, useMemo } from "react";
import { useAppSelector } from "../../redux/hooks";
import Table from "../../components/Table";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import locationsTableHeaders from "./locationTableHeaders";
import { KRoutes } from "../../routers/router";
import { useNavigate } from "react-router-dom";
import { getLocations } from "../../services/locations/location";
import { autoRefreshDelay } from "../../core/constants";

const ScreenLocations = () => {
  const { locations, isLoading } = useAppSelector((state) => state.locations);
  const columns = useMemo(() => locationsTableHeaders, []);
  const data = useMemo(() => locations, [locations]);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      getLocations();
    }, 0);
    const refreshInterval = setInterval(
      () => getLocations(true),
      autoRefreshDelay * 1000
    );
    return () => {
      clearInterval(refreshInterval);
    };
  }, []);

  return (
    <Layout title="locations">
      {isLoading && locations.length <= 0 ? (
        <Loader />
      ) : (
        <Table
          columns={columns}
          data={data}
          tableTitle={"Locations"}
          buttons={{
            addButton: true,
            addButtonTitle: "Add Location",
            exportButton: false,
            onClickAddButton: () => navigate(KRoutes.addLocation),
          }}
        />
      )}
    </Layout>
  );
};

export default ScreenLocations;
