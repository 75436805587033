import React, {
  createRef,
  LegacyRef,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  TextField,
  GridView,
  SelectInput,
  FileUpload,
} from "../../../components/TextFieldItems";
import Layout from "../../../components/Layout";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { KRoutes } from "../../../routers/router";
import { useAppSelector } from "../../../redux/hooks";
import MultiSelect from "multiselect-react-dropdown";
import { ISelectedImage } from "../../../types/default";
import ApiEndpoints from "../../../core/apiEndpoints";
import { addServicePlan, editServicePlan } from "../../../services/services";
import Loader from "../../../components/Loader";

const ScreenAddServicePlan = () => {
  const { services } = useAppSelector((state) => state.services);
  const { categories } = useAppSelector((state) => state.serviceCategories);
  const { plans, isLoading } = useAppSelector((state) => state.servicePlans);
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [selectedFile, setSelectedFile] = useState<ISelectedImage>({
    file: null,
    URL: null,
  });
  const [redeemPoint, setRedeemPoint] = useState("0");
  const [status, setStatus] = useState("Active");
  const [visibility, setVisibility] = useState("Show");
  const selectedServices: LegacyRef<MultiSelect> = createRef();

  const navigate = useNavigate();
  const params = useParams();

  const setEditFields = useCallback(
    (id: string) => {
      const plan = plans.find((p) => p._id === id);
      if (plan) {
        setName(plan.name);
        setSelectedFile((prevState) => ({
          ...prevState,
          URL: ApiEndpoints.servicePlanImage + plan._id,
        }));
        setCategory(plan.category._id);
        setStatus(plan.status);
        setRedeemPoint(plan.redeemPoints);
        setVisibility(plan.visibility);
      }
    },
    [plans]
  );

  useEffect(() => {
    if (params.pid) {
      setEditFields(params.pid);
    }
  }, [params.pid, setEditFields]);

  const handleSubmit = async () => {
    if (!name || !category || (!params.pid && !selectedFile.file)) {
      toast("Fill name, image, and category");
      return;
    } else {
      const categoryForm = new FormData();
      categoryForm.append("name", name);
      categoryForm.append("category", category);
      categoryForm.append("redeemPoints", redeemPoint);
      categoryForm.append("status", status);
      categoryForm.append("visibility", visibility);
      if (selectedServices.current?.getSelectedItemsCount() > 0) {
        const services = selectedServices.current
          ?.getSelectedItems()
          .map((service: any) => service.id);
        categoryForm.append("services", JSON.stringify(services));
      }
      if (selectedFile.file) {
        categoryForm.append("image", selectedFile.file);
      }
      const success = params.pid
        ? await editServicePlan(params.pid, categoryForm)
        : await addServicePlan(categoryForm);
      if (success) {
        navigate(KRoutes.servicePlans);
      }
    }
  };
  return (
    <Layout title="service-plans">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="p-10 overflow-y-scroll overflow-x-hidden scrollbar-hide">
          <div className="flex justify-between">
            <p className="lg:text-3xl text-2xl font-black dark:text-white ">
              {params.pid ? "Edit" : "Add"} Plan
            </p>
            <div>
              <button
                className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800`"
                onClick={() => navigate(-1)}
              >
                <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                  Back
                </span>
              </button>
            </div>
          </div>
          <form className="mt-6 " onSubmit={handleSubmit}>
            <GridView className="gap-6 mb-6 lg:grid-cols-2">
              <>
                <TextField
                  label="Name"
                  id="name"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="text"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="name"
                  value={name}
                  required={true}
                  placeholder="Service Plan Name"
                  onChange={(e) => setName(e.target.value)}
                />
                <SelectInput
                  data={categories.map((category) => {
                    return {
                      id: category._id,
                      title: category.name,
                    };
                  })}
                  title="Category"
                  value={category}
                  disabled={params.pid ? true : false}
                  onChange={(e) => setCategory(e.target.value)}
                />
                <div>
                  <label
                    htmlFor="types"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Services
                  </label>
                  <MultiSelect
                    options={services
                      .filter((service) => service.category._id === category)
                      .map((service) => {
                        return {
                          id: service._id,
                          name: service.name,
                        };
                      })}
                    id="services"
                    showArrow={true}
                    placeholder="Search services"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    ref={selectedServices}
                    displayValue="name"
                    avoidHighlightFirstOption={true}
                    keepSearchTerm={true}
                  />
                </div>
                <TextField
                  label="Redeem Point"
                  className="mb-5"
                  id="rp"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="text"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="redeemPoint"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  title="Must contain number or decimal values Eg:1.00"
                  value={redeemPoint}
                  required={true}
                  placeholder="Redeem Point"
                  onChange={(e) => setRedeemPoint(e.target.value)}
                />
                <div className="flex gap-2 w-full">
                  <SelectInput
                    data={[
                      {
                        id: "Active",
                        title: "Active",
                      },
                      {
                        id: "Inactive",
                        title: "Inactive",
                      },
                    ]}
                    title="Status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  />{" "}
                  <SelectInput
                    data={[
                      {
                        id: "Show",
                        title: "Show",
                      },
                      {
                        id: "Hide",
                        title: "Hide",
                      },
                    ]}
                    title="Visibility"
                    value={visibility}
                    onChange={(e) => setVisibility(e.target.value)}
                  />
                </div>
                <FileUpload
                  title="Image"
                  desc=" PNG, JPG  (MAX. 800x400px)."
                  accept="image/*"
                  maxSize={5}
                  file={selectedFile.file}
                  url={selectedFile.URL}
                  onFileSelect={(file) => {
                    setSelectedFile({
                      file: file,
                      URL: URL.createObjectURL(file),
                    });
                  }}
                  removeSelectedFile={() => {
                    setSelectedFile({
                      file: null,
                      URL: "",
                    });
                  }}
                />
              </>
            </GridView>
            <div className="flex items-center justify-center -mx-3 my-6">
              <div className="w-full lg:w-1/2 px-3">
                <button
                  type="button"
                  className="w-full active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-3 bg-primary-color text-white  text-lg font-bold rounded-xl"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </Layout>
  );
};

export default ScreenAddServicePlan;
