import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IServiceCustomPlan } from "../../types/services";

interface IServiceCustomPlanState {
  customPlans: IServiceCustomPlan[];
  deletedCustomPlans: IServiceCustomPlan[];
  isLoading: boolean;
}

const serviceCustomPlanInitState: IServiceCustomPlanState = {
  customPlans: [],
  deletedCustomPlans: [],
  isLoading: false,
};

const serviceCustomPlanSlice = createSlice({
  name: "service-plan",
  initialState: serviceCustomPlanInitState,
  reducers: {
    changeServiceCustomPlanLoading: (
      state: IServiceCustomPlanState,
      action: PayloadAction<boolean>
    ) => {
      if (state.isLoading !== action.payload) {
        state.isLoading = action.payload;
      }
    },
    setServiceCustomPlansState: (
      state: IServiceCustomPlanState,
      action: PayloadAction<{customPlans:IServiceCustomPlan[]; refresh?: boolean }>
    ) => {
      if (state.customPlans.length <= 0 || action.payload.refresh) {
        state.customPlans = action.payload.customPlans || state.customPlans;
      }
      state.isLoading = false;
    },
    setServiceDeletedCustomPlansState: (
      state: IServiceCustomPlanState,
      action: PayloadAction<{customPlans:IServiceCustomPlan[]; refresh?: boolean }>
    ) => {
      if (state.deletedCustomPlans.length <= 0 || action.payload.refresh) {
        state.deletedCustomPlans = action.payload.customPlans || state.deletedCustomPlans;
      }
      state.isLoading = false;
    },
    addServiceCustomPlanState: (
      state: IServiceCustomPlanState,
      action: PayloadAction<IServiceCustomPlan>
    ) => {
      state.customPlans.push(action.payload);
      state.isLoading = false;
    },
    editServiceCustomPlanState: (
      state: IServiceCustomPlanState,
      action: PayloadAction<IServiceCustomPlan>
    ) => {
      state.customPlans = state.customPlans.map((plan) => {
        if (action.payload._id === plan._id) {
          plan = action.payload;
        }
        return plan;
      });
      state.isLoading = false;
    },
    deleteServiceCustomPlanState: (
      state: IServiceCustomPlanState,
      action: PayloadAction<IServiceCustomPlan["_id"]>
    ) => {
      state.customPlans = state.customPlans.filter(
        (plan) => plan._id !== action.payload
      );
      state.isLoading = false;
    },
    pDeleteServiceCustomPlanState: (
      state: IServiceCustomPlanState,
      action: PayloadAction<IServiceCustomPlan["_id"]>
    ) => {
      state.deletedCustomPlans = state.deletedCustomPlans.filter(
        (plan) => plan._id !== action.payload
      );
      state.isLoading = false;
    },
    restoreServiceCustomPlanState: (
      state: IServiceCustomPlanState,
      action: PayloadAction<IServiceCustomPlan>
    ) => {
      state.customPlans.push(action.payload);
      state.isLoading = false;
    },
    clearServiceCustomPlanState: (state: IServiceCustomPlanState) => {
      state = serviceCustomPlanInitState;
    },
  },
});

export const {
  changeServiceCustomPlanLoading,
  setServiceCustomPlansState,
  setServiceDeletedCustomPlansState,
  addServiceCustomPlanState,
  editServiceCustomPlanState,
  deleteServiceCustomPlanState,
  pDeleteServiceCustomPlanState,
  restoreServiceCustomPlanState,
  clearServiceCustomPlanState,
} = serviceCustomPlanSlice.actions;

export default serviceCustomPlanSlice.reducer;
