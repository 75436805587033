import { Column } from "react-table";
import { SelectColumnFilter } from "../../../components/Table";
import {
  ButtonsPill,
  DateFromatPill,
  ImageCell,
  StatusPill,
  VisibilityPill,
} from "../../../components/TableCells";
import ApiEndpoints from "../../../core/apiEndpoints";
import store from "../../../redux/store";
import { KRoutes } from "../../../routers/router";
import {
  changeSalesCategoryStatus,
  changeSalesCategoryVisibility,
  deleteSalesCategory,
} from "../../../services/sales/category";
import { ISalesCategory } from "../../../types/sales";

const salesCategoryTableHeaders: Column<ISalesCategory>[] = [
  {
    id: "name",
    Header: "Name",
    accessor: "name",
  },
  {
    id: "image",
    Header: "Image",
    accessor: "_id",
    Cell: ({ value }) => ImageCell(ApiEndpoints.salesCategoryImage + value),
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: StatusPill,
    Filter: SelectColumnFilter, // new
    filter: "includes",
  },
  {
    Header: "Visibility",
    accessor: "visibility",
    Cell: VisibilityPill,
    Filter: SelectColumnFilter, // new
    filter: "includes",
  },
  {
    Header: "CreatedAt",
    accessor: "createdAt",
    Cell: DateFromatPill,
  },
  {
    Header: "UpdatedAt",
    accessor: "updatedAt",
    Cell: DateFromatPill,
  },
  {
    Header: "Action",
    accessor: "_id",
    Cell: ({ value }) => {
      return ButtonsPill({
        statusChangeButton: true,
        visibilityChangeButton: true,
        editButton: true,
         deleteButton: ["MasterAdmin", "Developer"].includes(store.getState().admins.profile?.role || "")
          ? true
          : false,
        editPath: KRoutes.editSalesCategory + "/" + value,
        onClickDelete: () => deleteSalesCategory(value),
        onClickStatusChange: () => changeSalesCategoryStatus(value),
        onClickVisibilityChange: () => changeSalesCategoryVisibility(value),
      });
    },
  },
];

export default salesCategoryTableHeaders;
