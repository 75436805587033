import React, { useState } from "react";
import { toast } from "react-toastify";

interface ITextFieldProps {
  className?: string;
  labelClassName?: string;
  label: string;
  inputClassName?: string;
  ref?: React.LegacyRef<HTMLInputElement>;
  id?: string;
  name?: string;
  title?: string;
  type?: React.HTMLInputTypeAttribute;
  placeholder?: string;
  required?: boolean;
  value?: string | number;
  pattern?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  disable?: boolean;
  readonly?: boolean;
  validation?: boolean;
  validationColor?: string;
  validationTitle?: string;
  validationDesc?: string;
  labelColor?: string;
  inputColor?: string;
  focusColor?: string;
}

export const TextField = ({
  className,
  label,
  labelClassName,
  inputClassName,
  id,
  name,
  type,
  ref,
  pattern,
  placeholder,
  required,
  value,
  title,
  onChange,
  disable,
  readonly,
  validation,
  validationColor,
  validationDesc,
  validationTitle,
  inputColor,
  labelColor,
  focusColor,
}: ITextFieldProps) => {
  return (
    <div className={className}>
      <label
        htmlFor={id}
        className={
          labelClassName +
          ` text-${
            validation ? validationColor : labelColor ?? "gray"
          }-900 dark:text-${
            validation ? validationColor : labelColor ?? "gray"
          }-300`
        }
      >
        {label}
      </label>
      <input
        type={type}
        id={id}
        title={title}
        name={name}
        autoComplete="off"
        ref={ref}
        pattern={pattern}
        className={
          inputClassName +
          ` text-${validation ? validationColor : inputColor ?? "gray"}-900
           border-${
             validation ? validationColor : inputColor ?? "gray"
           }-300 text-${
            validation ? validationColor : inputColor ?? "gray"
          }-900 dark:text-${
            validation ? validationColor : inputColor ?? "white"
          } placeholder-${
            validation ? validationColor : inputColor ?? "gray"
          }-700 dark:placeholder-${
            validation ? validationColor : inputColor ?? "gray"
          }-400 focus:ring-${
            validation ? validationColor : focusColor ?? "blue"
          }-500 focus:border-${
            validation ? validationColor : focusColor ?? "blue"
          }-500 dark:bg-${
            validation ? validationColor : inputColor ?? "gray"
          }-700 dark:border-${
            validation ? validationColor : inputColor ?? "gray"
          }-600 dark:focus:ring-${
            validation ? validationColor : focusColor ?? "blue"
          }-500 dark:focus:border-${
            validation ? validationColor : focusColor ?? "blue"
          }-500`
        }
        placeholder={placeholder}
        required={required}
        value={value}
        onChange={onChange}
        disabled={disable}
        readOnly={readonly}
      />
      <p
        className={`${!validation && "hidden"} mt-2 text-sm text-${
          validation ? validationColor : "gray"
        }-600 dark:text-${validation ? validationColor : "gray"}-500`}
      >
        <span className="font-medium">{validationTitle ?? ""}</span>{" "}
        {validationDesc ?? ""}
      </p>
    </div>
  );
};

interface ITextAreaFieldProps {
  className?: string;
  labelClassName?: string;
  label: string;
  inputClassName?: string;
  id?: string;
  name?: string;
  title?: string;
  placeholder?: string;
  required?: boolean;
  value?: string | number;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  disable?: boolean;
  readonly?: boolean;
  validation?: boolean;
  validationColor?: string;
  validationTitle?: string;
  validationDesc?: string;
  labelColor?: string;
  inputColor?: string;
  focusColor?: string;
}

export const TextAreaField = ({
  className,
  label,
  labelClassName,
  inputClassName,
  id,
  name,
  placeholder,
  required,
  value,
  title,
  onChange,
  disable,
  readonly,
  validation,
  validationColor,
  validationDesc,
  validationTitle,
  inputColor,
  labelColor,
  focusColor,
}: ITextAreaFieldProps) => {
  return (
    <div className={className}>
      <label
        htmlFor={id}
        className={
          labelClassName +
          ` text-${
            validation ? validationColor : labelColor ?? "gray"
          }-900 dark:text-${
            validation ? validationColor : labelColor ?? "gray"
          }-300`
        }
      >
        {label}
      </label>
      <textarea
        id={id}
        title={title}
        name={name}
        autoComplete="off"
        className={
          inputClassName +
          ` text-${validation ? validationColor : inputColor ?? "gray"}-900
           border-${
             validation ? validationColor : inputColor ?? "gray"
           }-300 text-${
            validation ? validationColor : inputColor ?? "gray"
          }-900 dark:text-${
            validation ? validationColor : inputColor ?? "white"
          } placeholder-${
            validation ? validationColor : inputColor ?? "gray"
          }-700 dark:placeholder-${
            validation ? validationColor : inputColor ?? "gray"
          }-400 focus:ring-${
            validation ? validationColor : focusColor ?? "blue"
          }-500 focus:border-${
            validation ? validationColor : focusColor ?? "blue"
          }-500 dark:bg-${
            validation ? validationColor : inputColor ?? "gray"
          }-700 dark:border-${
            validation ? validationColor : inputColor ?? "gray"
          }-600 dark:focus:ring-${
            validation ? validationColor : focusColor ?? "blue"
          }-500 dark:focus:border-${
            validation ? validationColor : focusColor ?? "blue"
          }-500`
        }
        placeholder={placeholder}
        required={required}
        value={value}
        onChange={onChange}
        disabled={disable}
        readOnly={readonly}
      />
      <p
        className={`${!validation && "hidden"} mt-2 text-sm text-${
          validation ? validationColor : "gray"
        }-600 dark:text-${validation ? validationColor : "gray"}-500`}
      >
        <span className="font-medium">{validationTitle ?? ""}</span>{" "}
        {validationDesc ?? ""}
      </p>
    </div>
  );
};

interface IGridViewProps {
  children: JSX.Element;
  className: string;
}

export const GridView = ({ children, className }: IGridViewProps) => {
  return <div className={`grid ${className}`}>{children}</div>;
};

interface IDropDownInputProps {
  data: {
    id: string;
    title: string;
  }[];
  title: string;
  className?: string;
  buttonClassName?: string;
  defaultValue: string;
}

export const DropDownInput = ({
  data,
  title,
  className,
  defaultValue,
  buttonClassName,
}: IDropDownInputProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  return (
    <div className={className + "w-full"}>
      <label
        htmlFor="dropdown"
        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
      >
        {title}
      </label>
      <button
        id="dropdown"
        data-dropdown-toggle="dropdown"
        className={
          buttonClassName +
          " flex-shrink-0 z-10 inline-flex items-center text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
        }
        onClick={() => setDropdownOpen(!dropdownOpen)}
        type="button"
      >
        {selectedValue
          ? data.find((d) => d.id === selectedValue)?.title
          : defaultValue}
        <svg
          className="ml-1 w-4 h-4"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </button>
      <div className="absolute">
        <div
          id="dropdown"
          className={`${
            !dropdownOpen && "hidden"
          } z-10 w-full bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 relative bottom-0`}
        >
          <ul
            className="py-1 text-sm text-gray-700 dark:text-gray-200"
            aria-labelledby="dropdown-button"
          >
            {data.map((obj, i) => (
              <li key={obj.id}>
                <button
                  className="py-2 px-4  hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white flex text-align:left"
                  onClick={() => {
                    setSelectedValue(obj.id);
                    setDropdownOpen(false);
                  }}
                >
                  {obj.title}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export const Dropzone = () => {
  return (
    <div className="flex justify-center items-center w-full">
      <label
        htmlFor="dropzone-file"
        className="flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
      >
        <div className="flex flex-col justify-center items-center pt-5 pb-6">
          <svg
            className="mb-3 w-10 h-10 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
            ></path>
          </svg>
          <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
            <span className="font-semibold">Click to upload</span> or drag and
            drop
          </p>
          <p className="text-xs text-gray-500 dark:text-gray-400">
            SVG, PNG, JPG or GIF (MAX. 800x400px)
          </p>
        </div>
        <input id="dropzone-file" type="file" name="image" className="hidden" />
      </label>
    </div>
  );
};

interface ISelectInputProps {
  data: {
    id: string;
    title: string;
  }[];
  title: string;
  value?: string;
  className?: string;
  multiple?: boolean;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
}

export const SelectInput = ({
  data,
  title,
  onChange,
  value,
  multiple,
  disabled,
  className,
}: ISelectInputProps) => {
  return (
    <div className={`w-full ${className}`}>
      <label
        htmlFor="select-btn"
        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
      >
        {title}
      </label>
      <select
        id="select-btn"
        multiple={multiple}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        onChange={onChange}
        disabled={disabled}
      >
        <option >
          {multiple ? "Select all " : "Choose a "} {title.toLocaleLowerCase()}
        </option>
        {data.map((obj) => (
          <option
            value={obj.id}
            selected={obj.title === value || obj.id === value}
          >
            {obj.title}
          </option>
        ))}
      </select>
    </div>
  );
};

interface IFileUploadProps {
  title: string;
  desc: string;
  required?: boolean;
  accept?: string;
  url?: string | null;
  file?: File | null;
  onFileSelect: (file: File) => void;
  removeSelectedFile: () => void;
  maxSize?: number;
}

export const FileUpload = ({
  title,
  desc,
  required,
  accept,
  onFileSelect,
  removeSelectedFile,
  file,
  url,
  maxSize,
}: IFileUploadProps) => {
  const handleFileInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.files && e.target.files[0]) {
      if (maxSize && e.target.files[0].size > maxSize * 1024 * 1024) {
        toast(`File Size cannot exceed more than ${maxSize}MB`);
        return;
      }
      onFileSelect(e.target.files[0]);
    } else {
      toast("File not found");
    }
  };

  return (
    <div className="h-full w-full relative">
      <label
        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
        htmlFor="file_input"
      >
        {title}
      </label>
      <div className="relative  h-72 lg:h-80 px-3 rounded-lg w-full border-2 border-gray-200 border-dashed bg-white flex items-center justify-center overflow-hidden">
        <div
          className={`${
            !file && !url && "hidden"
          } absolute h-full w-full flex items-center justify-center`}
        >
          <img
            src={url ? url : file ? URL.createObjectURL(file) : ""}
            className="w-full h-full object-cover"
            alt="file_image"
          />
        </div>
        <div className={`${(file || url) && "hidden"}`}>
          <div className="bg-primary-color text-8xl">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
              <path d="M144 480C64.47 480 0 415.5 0 336C0 273.2 40.17 219.8 96.2 200.1C96.07 197.4 96 194.7 96 192C96 103.6 167.6 32 256 32C315.3 32 367 64.25 394.7 112.2C409.9 101.1 428.3 96 448 96C501 96 544 138.1 544 192C544 204.2 541.7 215.8 537.6 226.6C596 238.4 640 290.1 640 352C640 422.7 582.7 480 512 480H144zM223 263C213.7 272.4 213.7 287.6 223 296.1C232.4 306.3 247.6 306.3 256.1 296.1L296 257.9V392C296 405.3 306.7 416 320 416C333.3 416 344 405.3 344 392V257.9L383 296.1C392.4 306.3 407.6 306.3 416.1 296.1C426.3 287.6 426.3 272.4 416.1 263L336.1 183C327.6 173.7 312.4 173.7 303 183L223 263z" />
            </svg>{" "}
          </div>
          <div className="font-medium text-primary-color text-xl">
            No file chosen, yet!
          </div>
          <p
            className="mt-1 text-sm text-gray-500 dark:text-gray-300"
            id="file_input_help"
          >
            {desc}
          </p>
        </div>
        <div
          id="cancel-btn"
          className={`${
            !file && !url && "hidden"
          } absolute right-4 top-4 text-white bg-primary-color cursor-pointer text-xl`}
        >
          <i
            className="fas fa-times hover:text-primary-color"
            onClick={removeSelectedFile}
          >
            X
          </i>
        </div>
        <div
          id="file_input_help"
          className={`${
            !file && "hidden"
          } absolute bottom-0  w-full pl-2 text-white  bg-primary-color text-lg`}
        >
          {file?.name}
        </div>
      </div>
      <label
        htmlFor="file_input"
        className="active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all mt-7 rounded-3xl flex items-center justify-center w-full h-12 text-white text-lg uppercase cursor-pointer bg-primary-color hover:"
      >
        {`Choose ${file || url ? "another" : "a"} file`}
      </label>
      <input
        id="file_input"
        className="hidden"
        type="file"
        // hidden
        onChange={handleFileInput}
        aria-describedby="file_input_help"
        name="image"
        required={required}
        accept={accept}
      />
    </div>
  );
};

export const ImageView = ({ imageURL }: { imageURL: string }) => {
  return (
    <div className="flex items-center justify-center m-y-2">
      <img
        className="rounded w-36 h-36"
        src={imageURL}
        alt="Img"
        loading="lazy"
      />
    </div>
  );
};

interface IToggleProps {
  title: string;
  value: string;
  state: string;
  className?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

export const Toggle = ({
  title,
  value,
  state,
  onChange,
  className,
}: IToggleProps) => {
  return (
    <div className={className}>
      <label
        htmlFor={title.toLocaleLowerCase()}
        className="block text-sm font-medium text-gray-900 dark:text-gray-300"
      >
        <div className="flex justify-center items-center">
          <span className="mr-3 text-sm font-medium text-gray-900 dark:text-gray-300">
            {title}
          </span>
          <div className="inline-flex relative cursor-pointer">
            <input
              type="checkbox"
              value={value}
              checked={value === state}
              id={title.toLocaleLowerCase()}
              className="sr-only peer"
              onChange={onChange}
            />
            <div className="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          </div>
        </div>
      </label>
    </div>
  );
};

export const CheckBoxWithDesc = () => {
  return (
    <div className="flex items-start mb-6 ">
      <div className="flex items-center h-5">
        <input
          id="remember"
          type="checkbox"
          value=""
          className="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
          required={true}
        />
      </div>
      <label
        htmlFor="remember"
        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-400"
      >
        I agree with the{" "}
        <button className="text-blue-600 hover:underline dark:text-blue-500">
          terms and conditions
        </button>
        .
      </label>
    </div>
  );
};
