import httpClient from "../../core/axios";
import ApiEndpoints from "../../core/apiEndpoints";
import store, { dispatch } from "../../redux/store";
import { setErrorMessage, setSuccessMessage } from "../../redux/notification";
import {
  changeServiceCategoryLoading,
  setServiceCategoriesState,
  setServiceDeletedCategoriesState,
  addServiceCategoryState,
  changeServiceCategoryStatusState,
  changeServiceCategoryVisibilityState,
  deleteServiceCategoryState,
  restoreServiceCategoryState,
  pDeleteServiceCategoryState,
  editServiceCategoryState,
} from "../../redux/services";
import { IServiceCategory } from "../../types/services";

/**
 * To get a service categories from server
 */
export const getServiceCategories = async (refresh?: boolean) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeServiceCategoryLoading(true));

    // API Fetching
    if (store.getState().admins.profile?.role === "MasterAdmin")
      getDeletedServiceCategorys(refresh);

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.serviceCategory);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(
      setServiceCategoriesState({
        categories: resp.data.data.filter(
          (category: IServiceCategory) => category.type === "ServiceCategory"
        ),
        refresh,
      })
    );
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceCategoryLoading(false));
  }
};

/**
 * To get a deleted category from server
 */
export const getDeletedServiceCategorys = async (refresh?: boolean) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeServiceCategoryLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.deletedServiceCategory);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(
      setServiceDeletedCategoriesState({
        categories: resp.data.data.filter(
          (category: IServiceCategory) => category.type === "ServiceCategory"
        ),
        refresh,
      })
    );
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceCategoryLoading(false));
  }
};

/**
 * To add a new service category
 */
export const addServiceCategory = async (categoryForm: FormData) => {
  try {
    // Set Loading
    dispatch(changeServiceCategoryLoading(true));

    // API Fetching
    const resp = await httpClient("multipart/form-data").post(
      ApiEndpoints.serviceCategory,
      categoryForm
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(addServiceCategoryState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceCategoryLoading(false));
  }
};

/**
 * To add a new service category
 */
export const editServiceCategory = async (
  id: string,
  categoryForm: FormData
) => {
  try {
    // Set Loading
    dispatch(changeServiceCategoryLoading(true));

    // API Fetching
    const resp = await httpClient("multipart/form-data").patch(
      ApiEndpoints.serviceCategory + "/" + id,
      categoryForm
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(editServiceCategoryState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceCategoryLoading(false));
  }
};

/**
 * To change the status
 */
export const changeServiceCategoryStatus = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeServiceCategoryLoading(true));

    // API Fetching
    const resp = await httpClient().get(
      ApiEndpoints.changeServiceCategoryStatus + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeServiceCategoryStatusState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceCategoryLoading(false));
  }
};

/**
 * To change the visibility
 */
export const changeServiceCategoryVisibility = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeServiceCategoryLoading(true));

    // API Fetching
    const resp = await httpClient().get(
      ApiEndpoints.changeServiceCategoryVisibility + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeServiceCategoryVisibilityState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceCategoryLoading(false));
  }
};

/**
 * To delete the category
 */
export const deleteServiceCategory = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeServiceCategoryLoading(true));

    // API Fetching
    const resp = await httpClient().delete(
      ApiEndpoints.serviceCategory + "/" + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(deleteServiceCategoryState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceCategoryLoading(false));
  }
};

/**
 * To restore the category
 */
export const restoreServiceCategory = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeServiceCategoryLoading(true));

    // API Fetching
    const resp = await httpClient().delete(
      ApiEndpoints.restoreServiceCategory + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(restoreServiceCategoryState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceCategoryLoading(false));
  }
};

/**
 * To delete the category permanently
 */
export const pDeleteServiceCategory = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeServiceCategoryLoading(true));

    // API Fetching
    const resp = await httpClient().delete(
      ApiEndpoints.deleteServiceCategory + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(pDeleteServiceCategoryState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceCategoryLoading(false));
  }
};
