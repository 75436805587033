import { Column } from "react-table";
import { SelectColumnFilter } from "../../../components/Table";
import {
  ButtonsPill,
  DateFromatPill,
  StatusPill,
  TextPill,
} from "../../../components/TableCells";
import store from "../../../redux/store";
import { KRoutes } from "../../../routers/router";
import {
  changeServiceTypeStatus,
  deleteServiceType,
} from "../../../services/services";
import { IServiceType } from "../../../types/services";

const serviceTypeTableHeaders: Column<IServiceType>[] = [
  {
    id: "name",
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Description",
    accessor: "desc",
    Cell: ({ value }) =>
      TextPill({ value, className: "truncate overflow-hidden text-gray-500" }),
  },
  {
    Header: "Category",
    accessor: (c) => c.category.name ?? "No Category",
    Filter: SelectColumnFilter, // new
    filter: "includes",
  },
  {
    id: "price",
    Header: "Additional Price (%)",
    accessor: "additionalPricePercent",
    Cell: ({ value }) => TextPill({ value: value + " %", className: "truncate overflow-hidden text-gray-500" }),
  },
  {
    id: "duration",
    Header: "Additional Duration (min)",
    accessor: "additionalDuration",
    Cell: ({ value }) => TextPill({ value: value + " min", className: "truncate overflow-hidden text-gray-500" }),
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: StatusPill,
    Filter: SelectColumnFilter, // new
    filter: "includes",
  },
  {
    Header: "CreatedAt",
    accessor: "createdAt",
    Cell: DateFromatPill,
  },
  {
    Header: "UpdatedAt",
    accessor: "updatedAt",
    Cell: DateFromatPill,
  },
  {
    Header: "Action",
    accessor: "_id",
    Cell: ({ value }) => {
      return ButtonsPill({
        statusChangeButton: true,
        visibilityChangeButton: false,
        editButton: true,
        deleteButton: ["MasterAdmin", "Developer"].includes(store.getState().admins.profile?.role || "")
        ? true
        : false,        onClickStatusChange: () => changeServiceTypeStatus(value),
        editPath: KRoutes.editServiceType + "/" + value,
        onClickDelete: () => deleteServiceType(value),
      });
    },
  },
];

export default serviceTypeTableHeaders;
