import { Column } from "react-table";
import { SelectColumnFilter } from "../../../components/Table";
import {
  ButtonsPill,
  DateFromatPill,
  ImageCell,
  StatusPill,
  VisibilityPill,
} from "../../../components/TableCells";
import ApiEndpoints from "../../../core/apiEndpoints";
import store from "../../../redux/store";
import { KRoutes } from "../../../routers/router";
import {
  changeServiceCategoryStatus,
  changeServiceCategoryVisibility,
  deleteServiceCategory,
} from "../../../services/services";
import { IServiceCategory } from "../../../types/services";

const serviceCategoryTableHeaders: Column<IServiceCategory>[] = [
  {
    id: "name",
    Header: "Name",
    accessor: "name",
  },
  {
    id: "image",
    Header: "Image",
    accessor: "_id",
    Cell: ({ value }) => ImageCell(ApiEndpoints.serviceCategoryImage + value),
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: StatusPill,
    Filter: SelectColumnFilter, // new
    filter: "includes",
  },
  {
    Header: "Visibility",
    accessor: "visibility",
    Cell: VisibilityPill,
    Filter: SelectColumnFilter, // new
    filter: "includes",
  },
  {
    Header: "CreatedAt",
    accessor: "createdAt",
    Cell: DateFromatPill,
  },
  {
    Header: "UpdatedAt",
    accessor: "updatedAt",
    Cell: DateFromatPill,
  },
  {
    Header: "Action",
    accessor: "_id",
    Cell: ({ value }) => {
      return ButtonsPill({
        statusChangeButton: true,
        visibilityChangeButton: true,
        editButton: true,
        deleteButton: ["MasterAdmin", "Developer"].includes(store.getState().admins.profile?.role || "")
          ? true
          : false,
        editPath: KRoutes.editServiceCategory + "/" + value,
        onClickDelete: () => deleteServiceCategory(value),
        onClickStatusChange: () => changeServiceCategoryStatus(value),
        onClickVisibilityChange: () => changeServiceCategoryVisibility(value),
      });
    },
  },
];

export default serviceCategoryTableHeaders;
