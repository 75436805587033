class ApiEndpoints {
  static serverURL: string =
    process.env.REACT_SERVER_URL ||
    "https://server.kwiknkleen.gokulsreejith.com";
  //  "http://192.168.1.10:5000";

  static baseURL: string = `${this.serverURL}/api/v1`;

  // Admin
  static admin: string = "/admin";
  static deletedAdmin: string = `${this.admin}/deleted`;
  static changeAdminStatus: string = `${this.admin}/change-state/`;
  static restoreAdmin: string = `${this.admin}/restore/`;
  static deleteAdmin: string = `${this.admin}/delete/`;
  static adminLogin: string = `${this.admin}/login`;
  static adminProfile: string = `${this.admin}/profile`;
  static adminImage: string = `${this.baseURL}${this.admin}/image`;

  // Customer
  static customer: string = "/customer";
  static deletedCustomer: string = `${this.customer}/deleted`;
  static changeCustomerStatus: string = `${this.customer}/change-state/`;
  static restoreCustomer: string = `${this.customer}/restore/`;
  static deleteCustomer: string = `${this.customer}/delete/`;
  static customerImage: string = `${this.baseURL}${this.customer}/image`;

  // Agent
  static agent: string = "/agent";
  static deletedAgent: string = `${this.agent}/deleted`;
  static changeAgentStatus: string = `${this.agent}/change-state/`;
  static restoreAgent: string = `${this.agent}/restore/`;
  static deleteAgent: string = `${this.agent}/delete/`;
  static agentImage: string = `${this.baseURL}${this.agent}/image`;

  // Location
  static location: string = "/location";
  static deletedLocation: string = `${this.location}/deleted`;
  static changeLocationStatus: string = `${this.location}/change-state/`;
  static restoreLocation: string = `${this.location}/restore/`;
  static deleteLocation: string = `${this.location}/delete/`;

  // Holiday
  static holiday: string = "/holiday";
  static deletedHoliday: string = `${this.holiday}/deleted`;
  static changeHolidayStatus: string = `${this.holiday}/change-state/`;
  static restoreHoliday: string = `${this.holiday}/restore/`;
  static deleteHoliday: string = `${this.holiday}/delete/`;

  // Service Category
  static serviceCategory: string = `/category`;
  static deletedServiceCategory: string = `${this.serviceCategory}/deleted`;
  static changeServiceCategoryStatus: string = `${this.serviceCategory}/change-state/`;
  static changeServiceCategoryVisibility: string = `${this.serviceCategory}/change-visibility/`;
  static restoreServiceCategory: string = `${this.serviceCategory}/restore/`;
  static deleteServiceCategory: string = `${this.serviceCategory}/delete/`;
  static serviceCategoryImage: string = `${this.baseURL}${this.serviceCategory}/image/`;

  // Service Banner
  static serviceBanner: string = `/banner`;
  static deletedServiceBanner: string = `${this.serviceBanner}/deleted`;
  static changeServiceBannerVisibility: string = `${this.serviceBanner}/change-visibility/`;
  static restoreServiceBanner: string = `${this.serviceBanner}/restore/`;
  static deleteServiceBanner: string = `${this.serviceBanner}/delete/`;
  static serviceBannerImage: string = `${this.baseURL}${this.serviceBanner}/image/`;

  // Service Type
  static serviceType: string = `/service-type`;
  static deletedServiceType: string = `${this.serviceType}/deleted`;
  static changeServiceTypeStatus: string = `${this.serviceType}/change-state/`;
  static restoreServiceType: string = `${this.serviceType}/restore/`;
  static deleteServiceType: string = `${this.serviceType}/delete/`;

  // Service Model
  static serviceModel: string = `/service-model`;
  static deletedServiceModel: string = `${this.serviceModel}/deleted`;
  static changeServiceModelStatus: string = `${this.serviceModel}/change-state/`;
  static changeServiceModelVisibility: string = `${this.serviceModel}/change-visibility/`;
  static restoreServiceModel: string = `${this.serviceModel}/restore/`;
  static deleteServiceModel: string = `${this.serviceModel}/delete/`;
  static serviceModelImage: string = `${this.baseURL}${this.serviceModel}/image/`;

  // Services
  static service: string = `/service`;
  static deletedService: string = `${this.service}/deleted`;
  static changeServiceStatus: string = `${this.service}/change-state/`;
  static restoreService: string = `${this.service}/restore/`;
  static deleteService: string = `${this.service}/delete/`;

  // Servce Plan
  static servicePlan: string = `/service-plan`;
  static serviceAllPlans: string = `${this.servicePlan}/all`;
  static deletedServicePlan: string = `${this.servicePlan}/deleted`;
  static changeServicePlanStatus: string = `${this.servicePlan}/change-state/`;
  static changeServicePlanVisibility: string = `${this.servicePlan}/change-visibility/`;
  static restoreServicePlan: string = `${this.servicePlan}/restore/`;
  static deleteServicePlan: string = `${this.servicePlan}/delete/`;
  static servicePlanImage: string = `${this.baseURL}${this.servicePlan}/image/`;

  // Servce Plans price List
  static servicePlansPriceList: string = `/service-plans-price-list`;
  static serviceAllPlansPriceLists: string = `${this.servicePlansPriceList}/all`;
  static deletedServicePlansPriceList: string = `${this.servicePlansPriceList}/deleted`;
  static changeServicePlansPriceListStatus: string = `${this.servicePlansPriceList}/change-state/`;
  static changeServicePlansPriceListVisibility: string = `${this.servicePlansPriceList}/change-visibility/`;
  static restoreServicePlansPriceList: string = `${this.servicePlansPriceList}/restore/`;
  static deleteServicePlansPriceList: string = `${this.servicePlansPriceList}/delete/`;
  static servicePlansPriceListImage: string = `${this.baseURL}${this.servicePlansPriceList}/image/`;

  // Service Order
  static serviceOrder: string = `/service-order`;
  static deletedServiceOrder: string = `${this.serviceOrder}/deleted`;
  static changeServiceOrderStatus: string = `${this.serviceOrder}/change-state/`;
  static restoreServiceOrder: string = `${this.serviceOrder}/restore/`;
  static deleteServiceOrder: string = `${this.serviceOrder}/delete/`;

  // Sales Category
  static salesCategory: string = `/category`;
  static deletedSalesCategory: string = `${this.salesCategory}/deleted`;
  static changeSalesCategoryStatus: string = `${this.salesCategory}/change-state/`;
  static changeSalesCategoryVisibility: string = `${this.salesCategory}/change-visibility/`;
  static restoreSalesCategory: string = `${this.salesCategory}/restore/`;
  static deleteSalesCategory: string = `${this.salesCategory}/delete/`;
  static salesCategoryImage: string = `${this.baseURL}${this.salesCategory}/image/`;

  // Sales Banner
  static salesBanner: string = `/banner`;
  static deletedSalesBanner: string = `${this.salesBanner}/deleted`;
  static changeSalesBannerVisibility: string = `${this.salesBanner}/change-visibility/`;
  static restoreSalesBanner: string = `${this.salesBanner}/restore/`;
  static deleteSalesBanner: string = `${this.salesBanner}/delete/`;
  static salesBannerImage: string = `${this.baseURL}${this.salesBanner}/image/`;

  // Sales Item
  static salesItem: string = `/sales-item`;
  static deletedSalesItem: string = `${this.salesItem}/deleted`;
  static changeSalesItemStatus: string = `${this.salesItem}/change-state/`;
  static changeSalesItemVisibility: string = `${this.salesItem}/change-visibility/`;
  static restoreSalesItem: string = `${this.salesItem}/restore/`;
  static deleteSalesItem: string = `${this.salesItem}/delete/`;
  static salesItemImage: string = `${this.baseURL}${this.salesItem}/image/`;

  // Sales Order
  static salesOrder: string = `/sales-order`;
  static deletedSalesOrder: string = `${this.salesOrder}/deleted`;
  static changeSalesOrderStatus: string = `${this.salesOrder}/change-state/`;
  static restoreSalesOrder: string = `${this.salesOrder}/restore/`;
  static deleteSalesOrder: string = `${this.salesOrder}/delete/`;
}

export default ApiEndpoints;
