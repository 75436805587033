import httpClient from "../../core/axios";
import ApiEndpoints from "../../core/apiEndpoints";
import store, { dispatch } from "../../redux/store";
import { setErrorMessage, setSuccessMessage } from "../../redux/notification";
import {
  changeSalesItemLoading,
  setSalesItemState,
  addSalesItemState,
  changeSalesItemStatusState,
  changeSalesItemVisibilityState,
  deleteSalesItemState,
  editSalesItemState,
  setSalesDeletedItemState,
  restoreSalesItemState,
  pDeleteSalesItemState,
} from "../../redux/sales";

/**
 * To get a sales items from server
 */
export const getSalesItems = async (refresh?: boolean) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeSalesItemLoading(true));

    // API Fetching Deleted
    if (store.getState().admins.profile?.role === "MasterAdmin")
      getSalesDeletedItem(refresh);

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.salesItem);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setSalesItemState({ items: resp.data.data, refresh }));
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSalesItemLoading(false));
  }
};

/**
 * To get a sales deleted items from server
 */
export const getSalesDeletedItem = async (refresh?: boolean) => {
  try {
    // Set Loading
    if (!refresh) dispatch(changeSalesItemLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.deletedSalesItem);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setSalesDeletedItemState({ items: resp.data.data, refresh }));
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSalesItemLoading(false));
  }
};

/**
 * To add a new sales item
 */
export const addSalesItem = async (itemForm: FormData) => {
  try {
    // Set Loading
    dispatch(changeSalesItemLoading(true));

    // API Fetching
    const resp = await httpClient("multipart/form-data").post(
      ApiEndpoints.salesItem,
      itemForm
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(addSalesItemState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSalesItemLoading(false));
  }
};

/**
 * To add a new sales item
 */
export const editSalesItem = async (id: string, itemForm: FormData) => {
  try {
    // Set Loading
    dispatch(changeSalesItemLoading(true));

    // API Fetching
    const resp = await httpClient("multipart/form-data").patch(
      ApiEndpoints.salesItem + "/" + id,
      itemForm
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(editSalesItemState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSalesItemLoading(false));
  }
};

/**
 * To change the status
 */
export const changeSalesItemStatus = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeSalesItemLoading(true));

    // API Fetching
    const resp = await httpClient().get(
      ApiEndpoints.changeSalesItemStatus + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeSalesItemStatusState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSalesItemLoading(false));
  }
};

/**
 * To change the visibility
 */
export const changeSalesItemVisibility = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeSalesItemLoading(true));

    // API Fetching
    const resp = await httpClient().get(
      ApiEndpoints.changeSalesItemVisibility + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeSalesItemVisibilityState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSalesItemLoading(false));
  }
};

/**
 * To delete the item
 */
export const deleteSalesItem = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeSalesItemLoading(true));

    // API Fetching
    const resp = await httpClient().delete(ApiEndpoints.salesItem + "/" + id);
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(deleteSalesItemState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSalesItemLoading(false));
  }
};

/**
 * To restore the sales item
 */
export const restoreSalesItem = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeSalesItemLoading(true));

    // API Fetching
    const resp = await httpClient().delete(ApiEndpoints.restoreSalesItem + id);
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(restoreSalesItemState(resp.data.data));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSalesItemLoading(false));
  }
};

/**
 * To delete the sales item permanently
 */
export const pDeleteSalesItem = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeSalesItemLoading(true));

    // API Fetching
    const resp = await httpClient().delete(ApiEndpoints.deleteSalesItem + id);
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(pDeleteSalesItemState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSalesItemLoading(false));
  }
};
