import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILocation } from "../types/default";

interface ILocationState {
  locations: ILocation[];
  deletedLocations: ILocation[];
  isLoading: boolean;
}

const locationInitState: ILocationState = {
  locations: [],
  deletedLocations: [],
  isLoading: false,
};

const locationSlice = createSlice({
  name: "location",
  initialState: locationInitState,
  reducers: {
    changeLocationLoading: (
      state: ILocationState,
      action: PayloadAction<boolean>
    ) => {
      if (state.isLoading !== action.payload) {
        state.isLoading = action.payload;
      }
    },
    setLocationsState: (
      state: ILocationState,
      action: PayloadAction<{ locations: ILocation[]; refresh?: boolean }>
    ) => {
      if (state.locations.length <= 0 || action.payload.refresh) {
        state.locations = action.payload.locations || state.locations;
      }
      state.isLoading = false;
    },
    setDeletedLocationsState: (
      state: ILocationState,
      action: PayloadAction<{ locations: ILocation[]; refresh?: boolean }>
    ) => {
      if (state.deletedLocations.length <= 0 || action.payload.refresh) {
        state.deletedLocations =
          action.payload.locations || state.deletedLocations;
      }
      state.isLoading = false;
    },
    addLocationState: (
      state: ILocationState,
      action: PayloadAction<ILocation>
    ) => {
      state.locations.push(action.payload);
      state.isLoading = false;
    },
    editLocationState: (
      state: ILocationState,
      action: PayloadAction<ILocation>
    ) => {
      state.locations = state.locations.map((location) => {
        if (action.payload._id === location._id) {
          location = action.payload;
        }
        return location;
      });
      state.isLoading = false;
    },
    changeLocationStatusState: (
      state: ILocationState,
      action: PayloadAction<ILocation>
    ) => {
      state.locations = state.locations.map((location) => {
        if (
          action.payload._id === location._id &&
          action.payload.status !== location.status
        ) {
          location.status = action.payload.status;
        }
        return location;
      });
      state.isLoading = false;
    },
    deleteLocationState: (
      state: ILocationState,
      action: PayloadAction<ILocation["_id"]>
    ) => {
      state.locations = state.locations.filter(
        (location) => location._id !== action.payload
      );
      state.isLoading = false;
    },
    pDeleteLocationState: (
      state: ILocationState,
      action: PayloadAction<ILocation["_id"]>
    ) => {
      state.deletedLocations = state.deletedLocations.filter(
        (location) => location._id !== action.payload
      );
      state.isLoading = false;
    },
    restoreLocationState: (
      state: ILocationState,
      action: PayloadAction<ILocation>
    ) => {
      state.locations.push(action.payload);
      state.isLoading = false;
    },
    clearLocationState: (state: ILocationState) => {
      state = locationInitState;
    },
  },
});

export const {
  changeLocationLoading,
  setLocationsState,
  setDeletedLocationsState,
  addLocationState,
  editLocationState,
  changeLocationStatusState,
  deleteLocationState,
  pDeleteLocationState,
  restoreLocationState,
  clearLocationState,
} = locationSlice.actions;

export default locationSlice.reducer;
