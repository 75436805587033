import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IServiceType } from "../../types/services";

interface IServiceTypeState {
  types: IServiceType[];
  deletedTypes: IServiceType[];
  isLoading: boolean;
}

const serviceTypeInitState: IServiceTypeState = {
  types: [],
  deletedTypes: [],
  isLoading: false,
};

const serviceTypeSlice = createSlice({
  name: "service-type",
  initialState: serviceTypeInitState,
  reducers: {
    changeServiceTypeLoading: (
      state: IServiceTypeState,
      action: PayloadAction<boolean>
    ) => {
      if (state.isLoading !== action.payload) {
        state.isLoading = action.payload;
      }
    },
    setServiceTypesState: (
      state: IServiceTypeState,
      action: PayloadAction<{ types: IServiceType[]; refresh?: boolean }>
    ) => {
      if (state.types.length <= 0 || action.payload.refresh) {
        state.types = action.payload.types || state.types;
      }
      state.isLoading = false;
    },
    setServiceDeletedTypesState: (
      state: IServiceTypeState,
      action: PayloadAction<{ types: IServiceType[]; refresh?: boolean }>
    ) => {
      if (state.deletedTypes.length <= 0 || action.payload.refresh) {
        state.deletedTypes = action.payload.types || state.deletedTypes;
      }
      state.isLoading = false;
    },
    addServiceTypeState: (
      state: IServiceTypeState,
      action: PayloadAction<IServiceType>
    ) => {
      state.types.unshift(action.payload);
      state.isLoading = false;
    },
    editServiceTypeState: (
      state: IServiceTypeState,
      action: PayloadAction<IServiceType>
    ) => {
      state.types = state.types.map((type) => {
        if (action.payload._id === type._id) {
          type = action.payload;
        }
        return type;
      });
      state.isLoading = false;
    },
    changeServiceTypeStatusState: (
      state: IServiceTypeState,
      action: PayloadAction<IServiceType>
    ) => {
      state.types = state.types.map((type) => {
        if (
          action.payload._id === type._id &&
          action.payload.status !== type.status
        ) {
          type.status = action.payload.status;
        }
        return type;
      });
      state.isLoading = false;
    },
    deleteServiceTypeState: (
      state: IServiceTypeState,
      action: PayloadAction<IServiceType["_id"]>
    ) => {
      state.types = state.types.filter(
        (category) => category._id !== action.payload
      );
      state.isLoading = false;
    },
    pDeleteServiceTypeState: (
      state: IServiceTypeState,
      action: PayloadAction<IServiceType["_id"]>
    ) => {
      state.deletedTypes = state.deletedTypes.filter(
        (plan) => plan._id !== action.payload
      );
      state.isLoading = false;
    },
    restoreServiceTypeState: (
      state: IServiceTypeState,
      action: PayloadAction<IServiceType>
    ) => {
      state.types.unshift(action.payload);
      state.isLoading = false;
    },
    clearServiceTypeState: (state: IServiceTypeState) => {
      state = serviceTypeInitState;
    },
  },
});

export const {
  changeServiceTypeLoading,
  setServiceTypesState,
  setServiceDeletedTypesState,
  addServiceTypeState,
  editServiceTypeState,
  changeServiceTypeStatusState,
  deleteServiceTypeState,
  pDeleteServiceTypeState,
  restoreServiceTypeState,
  clearServiceTypeState,
} = serviceTypeSlice.actions;

export default serviceTypeSlice.reducer;
