import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISalesCategory } from "../../types/sales";

interface ISalesCategoryState {
  categories: ISalesCategory[];
  deletedCategories: ISalesCategory[];
  isLoading: boolean;
}

const salesCategoryInitState: ISalesCategoryState = {
  categories: [],
  deletedCategories: [],
  isLoading: false,
};

const salesCategorySlice = createSlice({
  name: "sales-category",
  initialState: salesCategoryInitState,
  reducers: {
    changeSalesCategoryLoading: (
      state: ISalesCategoryState,
      action: PayloadAction<boolean>
    ) => {
      if (state.isLoading !== action.payload) {
        state.isLoading = action.payload || state.isLoading;
      }
    },
    setSalesCategoriesState: (
      state: ISalesCategoryState,
      action: PayloadAction<{ categories: ISalesCategory[]; refresh?: boolean }>
    ) => {
      if (state.categories.length <= 0 || action.payload.refresh) {
        state.categories = action.payload.categories || state.categories;
      }
      state.isLoading = false;
    },
    setSalesDeletedCategoriesState: (
      state: ISalesCategoryState,
      action: PayloadAction<{ categories: ISalesCategory[]; refresh?: boolean }>
    ) => {
      if (state.deletedCategories.length <= 0 || action.payload.refresh) {
        state.deletedCategories =
          action.payload.categories || state.deletedCategories;
      }
      state.isLoading = false;
    },
    addSalesCategoryState: (
      state: ISalesCategoryState,
      action: PayloadAction<ISalesCategory>
    ) => {
      state.categories.push(action.payload);
      state.isLoading = false;
    },
    editSalesCategoryState: (
      state: ISalesCategoryState,
      action: PayloadAction<ISalesCategory>
    ) => {
      state.categories = state.categories.map((category) => {
        if (action.payload._id === category._id) {
          category = action.payload;
        }
        return category;
      });
      state.isLoading = false;
    },
    changeSalesCategoryStatusState: (
      state: ISalesCategoryState,
      action: PayloadAction<ISalesCategory>
    ) => {
      state.categories = state.categories.map((category) => {
        if (
          action.payload._id === category._id &&
          action.payload.status !== category.status
        ) {
          category.status = action.payload.status;
        }
        return category;
      });
      state.isLoading = false;
    },
    changeSalesCategoryVisibilityState: (
      state: ISalesCategoryState,
      action: PayloadAction<ISalesCategory>
    ) => {
      state.categories = state.categories.map((category) => {
        if (
          action.payload._id === category._id &&
          action.payload.visibility !== category.visibility
        ) {
          category.visibility = action.payload.visibility;
        }
        return category;
      });
      state.isLoading = false;
    },
    deleteSalesCategoryState: (
      state: ISalesCategoryState,
      action: PayloadAction<ISalesCategory["_id"]>
    ) => {
      state.categories = state.categories.filter(
        (category) => category._id !== action.payload
      );
      state.isLoading = false;
    },
    pDeleteSalesCategoryState: (
      state: ISalesCategoryState,
      action: PayloadAction<ISalesCategory["_id"]>
    ) => {
      state.deletedCategories = state.deletedCategories.filter(
        (category) => category._id !== action.payload
      );
      state.isLoading = false;
    },
    restoreSalesCategoryState: (
      state: ISalesCategoryState,
      action: PayloadAction<ISalesCategory>
    ) => {
      state.categories.push(action.payload);
      state.isLoading = false;
    },
    clearSalesCategoryState: (state: ISalesCategoryState) => {
      state = salesCategoryInitState;
    },
  },
});

export const {
  changeSalesCategoryLoading,
  setSalesCategoriesState,
  addSalesCategoryState,
  editSalesCategoryState,
  changeSalesCategoryStatusState,
  changeSalesCategoryVisibilityState,
  deleteSalesCategoryState,
  setSalesDeletedCategoriesState,
  restoreSalesCategoryState,
  pDeleteSalesCategoryState,
  clearSalesCategoryState,
} = salesCategorySlice.actions;

export default salesCategorySlice.reducer;
