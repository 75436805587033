import React, { useCallback, useEffect, useState } from "react";
import {
  TextField,
  GridView,
  SelectInput,
  FileUpload,
} from "../../../components/TextFieldItems";
import Layout from "../../../components/Layout";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { KRoutes } from "../../../routers/router";
import { useAppSelector } from "../../../redux/hooks";
import Loader from "../../../components/Loader";
import {
  addServicePlansPriceList,
  editServicePlansPriceList,
} from "../../../services/services/plansPriceList";

const ScreenAddServicePlan = () => {
  const { plans } = useAppSelector((state) => state.servicePlans);
  const { models } = useAppSelector((state) => state.serviceModels);
  const { types } = useAppSelector((state) => state.serviceTypes);
  const { categories } = useAppSelector((state) => state.serviceCategories);
  const { allPlansPriceList, isLoading } = useAppSelector(
    (state) => state.servicePlansPriceList
  );
  const [plan, setPlan] = useState("");
  const [model, setModel] = useState("");
  const [type, setType] = useState("");
  const [category, setCategory] = useState("");
  const [price, setPrice] = useState("");
  const [calculatedPrice, setCalculatedPrice] = useState("");
  const [calculatedDuration, setCalculatedDuration] = useState("");
  const [duration, setDuration] = useState("");
  const [available, setAvailable] = useState(false);

  const navigate = useNavigate();
  const params = useParams();

  const setEditFields = useCallback(
    (id: string) => {
      const planPriceList = allPlansPriceList.find((p) => p._id === id);
      if (planPriceList) {
        setPlan(planPriceList.plan._id);
        setModel(planPriceList.model._id);
        setType(planPriceList.type._id);
        setCategory(planPriceList.category._id);
        setPrice(planPriceList.price);
        setDuration(planPriceList.duration);
        setCalculatedPrice(planPriceList.calculatedPrice);
        setCalculatedDuration(planPriceList.calculatedDuration);
        setAvailable(planPriceList.available ?? false);
      }
    },
    [plans]
  );

  useEffect(() => {
    if (params.pid) {
      setEditFields(params.pid);
    }
  }, [params.pid, setEditFields]);

  const handleSubmit = async () => {
    if (!plan || !model || !type || !category || !price || !duration) {
      toast("Fill price, and duration");
      return;
    } else {
      const planPriceListData = {
        plan,
        model,
        type,
        category,
        price,
        duration,
      };
      const success =
        params.pid && available
          ? await editServicePlansPriceList(params.pid, planPriceListData)
          : await addServicePlansPriceList(planPriceListData);
      if (success) {
        navigate(KRoutes.servicePlansPriceList);
      }
    }
  };
  return (
    <Layout title="service-plans-price-list">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="p-10 overflow-y-scroll overflow-x-hidden scrollbar-hide">
          <div className="flex justify-between">
            <p className="lg:text-3xl text-2xl font-black dark:text-white ">
              {params.pid ? "Edit" : "Add"} Plan Price List
            </p>
            <div>
              <button
                className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800`"
                onClick={() => navigate(-1)}
              >
                <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                  Back
                </span>
              </button>
            </div>
          </div>
          <form className="mt-6 " onSubmit={handleSubmit}>
            <GridView className="gap-6 mb-6 lg:grid-cols-2">
              <>
                <SelectInput
                  data={plans.map((plan) => {
                    return {
                      id: plan._id,
                      title: plan.name,
                    };
                  })}
                  title="Plan"
                  value={plan}
                  disabled={params.pid ? true : false}
                  onChange={(e) => setPlan(e.target.value)}
                />
                <SelectInput
                  data={categories.map((category) => {
                    return {
                      id: category._id,
                      title: category.name,
                    };
                  })}
                  title="Category"
                  value={category}
                  disabled={params.pid ? true : false}
                  onChange={(e) => setCategory(e.target.value)}
                />
                <SelectInput
                  data={models.map((model) => {
                    return {
                      id: model._id,
                      title: model.name,
                    };
                  })}
                  title="Model"
                  value={model}
                  disabled={params.pid ? true : false}
                  onChange={(e) => setModel(e.target.value)}
                />
                <SelectInput
                  data={types.map((type) => {
                    return {
                      id: type._id,
                      title: type.name,
                    };
                  })}
                  title="Type"
                  value={type}
                  disabled={params.pid ? true : false}
                  onChange={(e) => setType(e.target.value)}
                />
                <TextField
                  label="Calculated Price"
                  className="mb-5"
                  id="calculated-price"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="text"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="calculated-price"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  title="Must contain number or decimal values Eg:1.00"
                  value={calculatedPrice}
                  required={true}
                  placeholder="Calculated Price"
                  onChange={(e) => setCalculatedPrice(e.target.value)}
                />
                <TextField
                  label="Calculated Duration"
                  className="mb-5"
                  id="calculated-duration"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="text"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="calculated-duration"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  title="Must contain number or decimal values Eg:1.00"
                  value={calculatedDuration}
                  required={true}
                  placeholder="Calculated Duration"
                  onChange={(e) => setCalculatedDuration(e.target.value)}
                />
                <TextField
                  label="Price"
                  className="mb-5"
                  id="price"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="text"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="price"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  title="Must contain number or decimal values Eg:1.00"
                  value={price}
                  required={true}
                  placeholder="Price"
                  onChange={(e) => setPrice(e.target.value)}
                />
                <TextField
                  label="Duration"
                  className="mb-5"
                  id="duration"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="text"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="duration"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  title="Must contain number or decimal values Eg:1.00"
                  value={duration}
                  required={true}
                  placeholder="Duration"
                  onChange={(e) => setDuration(e.target.value)}
                />
              </>
            </GridView>
            <div className="flex items-center justify-center -mx-3 my-6">
              <div className="w-full lg:w-1/2 px-3">
                <button
                  type="button"
                  className="w-full active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-3 bg-primary-color text-white  text-lg font-bold rounded-xl"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </Layout>
  );
};

export default ScreenAddServicePlan;
