import React, { useCallback, useEffect, useState } from "react";
import {
  TextField,
  GridView,
  SelectInput,
  FileUpload,
} from "../../../components/TextFieldItems";
import Layout from "../../../components/Layout";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { KRoutes } from "../../../routers/router";
import { useAppSelector } from "../../../redux/hooks";
import ApiEndpoints from "../../../core/apiEndpoints";
import { ISelectedImage } from "../../../types/default";
import {
  addSalesCategory,
  editSalesCategory,
} from "../../../services/sales/category";
import Loader from "../../../components/Loader";

const ScreenAddSalesCategory = () => {
  const { categories, isLoading } = useAppSelector(
    (state) => state.salesCategory
  );
  const [name, setName] = useState("");
  const [selectedFile, setSelectedFile] = useState<ISelectedImage>({
    file: null,
    URL: null,
  });
  const [status, setStatus] = useState("Active");
  const [visibility, setVisibility] = useState("Show");
  const navigate = useNavigate();
  const params = useParams();

  const setEditFields = useCallback(
    (id: string) => {
      const category = categories.find((c) => c._id === id);
      if (category) {
        setName(category.name);
        setSelectedFile((prevState) => ({
          ...prevState,
          URL: ApiEndpoints.salesCategoryImage + category._id,
        }));
        setStatus(category.status);
        setVisibility(category.visibility);
      }
    },
    [categories]
  );

  useEffect(() => {
    if (params.cid) {
      setEditFields(params.cid);
    }
  }, [params.cid, setEditFields]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    if (!name || (!params.cid && !selectedFile.file)) {
      toast("Fill name and upload image");
      return;
    } else {
      const categoryForm = new FormData();
      categoryForm.append("name", name);
      categoryForm.append("type", "SalesCategory");
      categoryForm.append("status", status);
      categoryForm.append("visibility", visibility);
      if (selectedFile.file) {
        categoryForm.append("image", selectedFile.file);
      }

      const success = params.cid
        ? await editSalesCategory(params.cid, categoryForm)
        : await addSalesCategory(categoryForm);
      if (success) {
        navigate(KRoutes.salesCategories);
      }
    }
  };
  return (
    <Layout title="sales-categorys">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="p-10 overflow-y-scroll overflow-x-hidden scrollbar-hide">
          <div className="flex justify-between">
            <p className="lg:text-3xl text-2xl font-black dark:text-white ">
              {params.cid ? "Edit" : "Add"} Category
            </p>
            <div>
              <button
                className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800`"
                onClick={() => navigate(-1)}
              >
                <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                  Back
                </span>
              </button>
            </div>
          </div>
          <form className="mt-6 " onSubmit={handleSubmit}>
            <GridView className="gap-6 mb-6 lg:grid-cols-2">
              <>
                <TextField
                  label="Name"
                  id="name"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="text"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="name"
                  value={name}
                  required={true}
                  placeholder="Category Name"
                  onChange={(e) => setName(e.target.value)}
                />
                <div className="flex gap-8  w-full">
                  <SelectInput
                    data={[
                      {
                        id: "Active",
                        title: "Active",
                      },
                      {
                        id: "Inactive",
                        title: "Inactive",
                      },
                    ]}
                    title="Status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  />{" "}
                  <SelectInput
                    data={[
                      {
                        id: "Show",
                        title: "Show",
                      },
                      {
                        id: "Hide",
                        title: "Hide",
                      },
                    ]}
                    title="Visibility"
                    value={visibility}
                    onChange={(e) => setVisibility(e.target.value)}
                  />
                </div>
              </>
            </GridView>
            <div className="flex -mx-3 my-6">
              <div className="w-full lg:w-1/2 px-3">
                <FileUpload
                  title="Image"
                  desc=" PNG, JPG  (MAX. 800x400px)."
                  accept="image/*"
                  maxSize={1}
                  file={selectedFile.file}
                  url={selectedFile.URL}
                  onFileSelect={(file) => {
                    setSelectedFile({
                      file: file,
                      URL: URL.createObjectURL(file),
                    });
                  }}
                  removeSelectedFile={() => {
                    setSelectedFile({
                      file: null,
                      URL: "",
                    });
                  }}
                />
              </div>
            </div>
            <div className="flex items-center justify-center -mx-3 my-6">
              <div className="w-full lg:w-1/2 px-3">
                <button
                  type="submit"
                  className="w-full active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-3 bg-primary-color text-white  text-lg font-bold rounded-xl"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </Layout>
  );
};

export default ScreenAddSalesCategory;
